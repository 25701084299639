import React, { useState } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import the axios instance with token handling
import { Link } from 'react-router-dom';
import './AddTimeSlots.css';

const AddTimeSlots = () => {
    const [slotName, setSlotName] = useState('asasd');
    const [slotFor, setSlotFor] = useState('HCS');
    const [normalDiff, setNormalDiff] = useState(0);
    const [normalStartTime, setNormalStartTime] = useState('00:00');
    const [normalEndTime, setNormalEndTime] = useState('0:00');
    const [bookMinPriorTime, setBookMinPriorTime] = useState(0);
    const [beforeApplicableDate, setBeforeApplicableDate] = useState(0);
    const [beforeFromTime, setBeforeFromTime] = useState('00:00');
    const [beforeToTime, setBeforeToTime] = useState('00:00');
    const [beforeDiff, setBeforeDiff] = useState(0);
    const [beforeStartTime, setBeforeStartTime] = useState('00:00');
    const [beforeEndTime, setBeforeEndTime] = useState('00:00');
    const [afterApplicableDate, setAfterApplicableDate] = useState(1);
    const [afterFromTime, setAfterFromTime] = useState('00:00');
    const [afterToTime, setAfterToTime] = useState('00:00');
    const [afterDiff, setAfterDiff] = useState(0);
    const [afterStartTime, setAfterStartTime] = useState('00:00');
    const [afterEndTime, setAfterEndTime] = useState('00:00');

    const [generatedRoutineSlots, setGeneratedRoutineSlots] = useState([]);
    const [generatedBeforeSlots, setGeneratedBeforeSlots] = useState([]);
    const [generatedAfterSlots, setGeneratedAfterSlots] = useState([]);

    const generateRoutineSlots = () => {
        if (normalStartTime && normalEndTime && normalDiff > 0) {
            const slots = generateSlots(normalStartTime, normalEndTime, normalDiff);
            setGeneratedRoutineSlots(slots);
            return slots;
        }
        return [];
    };

    const generateBeforeSlots = () => {
        if (beforeStartTime && beforeEndTime && beforeDiff > 0) {
            const slots = generateSlots(beforeStartTime, beforeEndTime, beforeDiff);
            setGeneratedBeforeSlots(slots);
            return slots;
        }
        return [];
    };

    const generateAfterSlots = () => {
        if (afterStartTime && afterEndTime && afterDiff > 0) {
            const slots = generateSlots(afterStartTime, afterEndTime, afterDiff);
            setGeneratedAfterSlots(slots);
            return slots;
        }
        return [];
    };

    const generateSlots = (startTimeStr, endTimeStr, diffInMinutes) => {
        const slots = [];
        let startTime = new Date(`1970-01-01T${startTimeStr}`);
        const endTime = new Date(`1970-01-01T${endTimeStr}`);

        let currentTime = new Date(startTime);

        while (currentTime < endTime) {
            const nextTime = new Date(currentTime.getTime() + diffInMinutes * 60000);
            if (nextTime <= endTime) {
                slots.push(`${formatTime(currentTime)} To ${formatTime(nextTime)}`);
            }
            currentTime = nextTime;
        }

        return slots;
    };

    const formatTime = (date) => {
        return date.toTimeString().split(' ')[0];
    };

    const saveTimeSlots = async (slotType, slots) => {
        const data = {
            slot_name: slotName,
            slot_for: slotFor,
            slot_type: slotType,
            slots: slots,
            normal_diff: normalDiff,
            normal_start_time: normalStartTime,
            normal_end_time: normalEndTime,
            book_min_prior_time: bookMinPriorTime,
            before_applicable_date: beforeApplicableDate,
            before_from_time: beforeFromTime,
            before_to_time: beforeToTime,
            before_diff: beforeDiff,
            before_start_time: beforeStartTime,
            before_end_time: beforeEndTime,
            after_applicable_date: afterApplicableDate,
            after_from_time: afterFromTime,
            after_to_time: afterToTime,
            after_diff: afterDiff,
            after_start_time: afterStartTime,
            after_end_time: afterEndTime
        };

        try {
            const response = await axiosInstance.post('/api/api/create-time-slots/', data);

            if (response.status === 200 || response.status === 201) {
                const result = await response.data;
                console.log('Time slots saved successfully:', result);
            } else {
                console.error('Failed to save time slots:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="add-time-slots">
            <h2>Update Existing Time-Slot</h2>
            <Link to="/master/TimeSlots">
                <button className="back-button">Back To Menu</button>
            </Link>
            <form>
                <div className="form-group">
                    <label htmlFor="slotName">Slot Name:</label>
                    <input type="text" id="slotName" value={slotName} onChange={(e) => setSlotName(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="slotFor">For:</label>
                    <select id="slotFor" value={slotFor} onChange={(e) => setSlotFor(e.target.value)}>
                        <option value="HCS">HCS</option>
                    </select>
                </div>

                <div className="routine-day-time-slots">
                    <h4>ROUTINE DAY TIME SLOTS</h4>
                    <div className="form-group">
                        <label htmlFor="normalDiff">Normal Diff. (minutes):</label>
                        <input type="number" id="normalDiff" value={normalDiff} onChange={(e) => setNormalDiff(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="normalStartTime">Normal Start Time:</label>
                        <input type="time" id="normalStartTime" value={normalStartTime} onChange={(e) => setNormalStartTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="normalEndTime">Normal End Time:</label>
                        <input type="time" id="normalEndTime" value={normalEndTime} onChange={(e) => setNormalEndTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bookMinPriorTime">Book Min Prior Time (minutes):</label>
                        <input type="number" id="bookMinPriorTime" value={bookMinPriorTime} onChange={(e) => setBookMinPriorTime(e.target.value)} />
                    </div>
                    <button type="button" className="view-button" onClick={generateRoutineSlots}>View</button>
                    <button type="button" className="add-button" onClick={() => saveTimeSlots('Routine', generatedRoutineSlots)}>Add Routine Time Slots</button>
                </div>

                <div className="before-routine-time-slots">
                    <h4>BEFORE ROUTINE TIME SLOTS</h4>
                    <div className="form-group">
                        <label htmlFor="beforeApplicableDate">Applicable Date +:</label>
                        <input type="number" id="beforeApplicableDate" value={beforeApplicableDate} onChange={(e) => setBeforeApplicableDate(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="beforeFromTime">From Time:</label>
                        <input type="time" id="beforeFromTime" value={beforeFromTime} onChange={(e) => setBeforeFromTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="beforeToTime">To Time:</label>
                        <input type="time" id="beforeToTime" value={beforeToTime} onChange={(e) => setBeforeToTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="beforeDiff">Diff. (minutes):</label>
                        <input type="number" id="beforeDiff" value={beforeDiff} onChange={(e) => setBeforeDiff(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="beforeStartTime">Start Time:</label>
                        <input type="time" id="beforeStartTime" value={beforeStartTime} onChange={(e) => setBeforeStartTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="beforeEndTime">End Time:</label>
                        <input type="time" id="beforeEndTime" value={beforeEndTime} onChange={(e) => setBeforeEndTime(e.target.value)} />
                    </div>
                    <button type="button" className="view-button" onClick={generateBeforeSlots}>View</button>
                    <button type="button" className="add-button" onClick={() => saveTimeSlots('Before Routine', generatedBeforeSlots)}>Add Before Routine Time Slots</button>
                </div>

                <div className="after-routine-time-slots">
                    <h4>AFTER ROUTINE TIME SLOTS</h4>
                    <div className="form-group">
                        <label htmlFor="afterApplicableDate">Applicable Date +:</label>
                        <input type="number" id="afterApplicableDate" value={afterApplicableDate} onChange={(e) => setAfterApplicableDate(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="afterFromTime">From:</label>
                        <input type="time" id="afterFromTime" value={afterFromTime} onChange={(e) => setAfterFromTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="afterToTime">To:</label>
                        <input type="time" id="afterToTime" value={afterToTime} onChange={(e) => setAfterToTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="afterDiff">Diff. (minutes):</label>
                        <input type="number" id="afterDiff" value={afterDiff} onChange={(e) => setAfterDiff(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="afterStartTime">Start Time:</label>
                        <input type="time" id="afterStartTime" value={afterStartTime} onChange={(e) => setAfterStartTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="afterEndTime">End Time:</label>
                        <input type="time" id="afterEndTime" value={afterEndTime} onChange={(e) => setAfterEndTime(e.target.value)} />
                    </div>
                    <button type="button" className="view-button" onClick={generateAfterSlots}>View</button>
                    <button type="button" className="add-button" onClick={() => saveTimeSlots('After Routine', generatedAfterSlots)}>Add After Routine Time Slots</button>
                </div>
            </form>

            <div className="generated-time-slots">
                <h4>Generated Routine Time Slots</h4>
                <ul>
                    {generatedRoutineSlots.map((slot, index) => (
                        <li key={index}>{slot}</li>
                    ))}
                </ul>

                <h4>Generated Before Routine Time Slots</h4>
                <ul>
                    {generatedBeforeSlots.map((slot, index) => (
                        <li key={index}>{slot}</li>
                    ))}
                </ul>

                <h4>Generated After Routine Time Slots</h4>
                <ul>
                    {generatedAfterSlots.map((slot, index) => (
                        <li key={index}>{slot}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AddTimeSlots;
