import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Use your custom axios instance
import './EstimateParams.css';

function TestInclusionComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const itemcode = params.get('itemcode');
  const itemtype = params.get('itemtype');

  const [testInclusions, setTestInclusions] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchTestInclusions = async () => {
      try {
        const response = await axiosInstance.get(`/api/api/get-item-inclusion/`, {
          params: {
            itemcode,
            itemtype,
          },
        });
        setTestInclusions(response.data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data from the API:', error);
        setError('Failed to fetch data. Please try again later.');
        setLoading(false); // Set loading to false even if there’s an error
      }
    };

    fetchTestInclusions();
  }, [itemcode, itemtype]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading message while data is being fetched
  }

  if (error) {
    return <div className="error">{error}</div>; // Display an error message if there was an issue fetching data
  }

  return (
    <div className='EstimateParamPageMainDiv'>
      <table className='EstimateParamsTable'>
        <thead>
          <tr>
            <th>S. NO.</th>
            <th>ID</th>
            <th>Observation Name</th>
          </tr>
        </thead>
        <tbody>
          {testInclusions.map((testInclusion, index) => (
            <React.Fragment key={testInclusion.id}>
              <tr>
                <td className='EstimateParamsIntusionsName' id='EstimateParamsIntusionsName3' colSpan="4">
                  {testInclusion.TestInclusionName}
                </td>
              </tr>
              {testInclusion.TestParametersObj.map((param, paramIndex) => (
                <tr key={param.ParameterID} className='EstimateParamsMAinData'>
                  <td>{paramIndex + 1}</td>
                  <td>{param.ParameterID}</td>
                  <td>{param.ParameterName}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TestInclusionComponent;
