import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Ensure axiosInstance is configured
import './DeleteSpecialInstructions.css';

const DeleteSpecialInstructions = () => {
    const [instructions, setInstructions] = useState([]);
    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch all special instructions
        axiosInstance.get('/api/api/special-instructions/')
            .then(response => setInstructions(response.data))
            .catch(error => console.error('Error fetching special instructions:', error));
    }, []);

    const handleSelectInstruction = (instruction) => {
        setSelectedInstruction(instruction);
    };

    const handleDelete = async () => {
        if (!selectedInstruction) return;

        try {
            await axiosInstance.delete(`/api/api/special-instructions/${selectedInstruction.id}/`);
            // Remove the deleted instruction from the list
            setInstructions(instructions.filter(instruction => instruction.id !== selectedInstruction.id));
            setSelectedInstruction(null);
            alert('Special instruction deleted successfully!');
        } catch (error) {
            console.error('Error deleting special instructions:', error);
        }
    };

    return (
        <div className="delete-special-instructions-container">
            <div className="delete-special-instructions-header">
                <h2>Delete Special Instructions</h2>
                <Link to="/Packages/SpecialInstructions">
                    <button className="delete-special-instructions-back-button">Back To Menu</button>
                </Link>
            </div>

            <div className="delete-special-instructions-list-container">
                <h3>Select Special Instructions to Delete</h3>
                <table className="special-instructions-table">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Test</th>
                            <th>Special Instructions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {instructions.map(instruction => (
                            <tr key={instruction.id}>
                                <td>
                                    <button
                                        className="select-button"
                                        onClick={() => handleSelectInstruction(instruction)}
                                    >
                                        Select
                                    </button>
                                </td>
                                <td>{instruction.test_name}</td>
                                <td>{instruction.special_instructions}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedInstruction && (
                <div className="delete-special-instructions-confirm-container">
                    <h3>Are you sure you want to delete the following Special Instructions?</h3>
                    <p><strong>Test Name:</strong> {selectedInstruction.test_name}</p>
                    <p><strong>Special Instructions:</strong> {selectedInstruction.special_instructions}</p>
                    <button
                        className="delete-special-instructions-delete-button"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                </div>
            )}
        </div>
    );
};

export default DeleteSpecialInstructions;
