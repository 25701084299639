import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import axiosInstance for token handling
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './Assign.css';

const ReportAssign = () => {
  const [uniqueId, setUniqueId] = useState('');
  const [data, setData] = useState(null);
  const [selectedPhlebotomist, setSelectedPhlebotomist] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [fromHours, setFromHours] = useState('');
  const [fromMinutes, setFromMinutes] = useState('');
  const [toHours, setToHours] = useState('');
  const [toMinutes, setToMinutes] = useState('');
  const [assignedDateTime, setAssignedDateTime] = useState('');
  const [specialInstructions, setSpecialInstructions] = useState([]);
  const [selectedInstructions, setSelectedInstructions] = useState([]);
  const [formData, setFormData] = useState({
    Unique_id: '',
    Assign_phelobo: '',
    date_for_collection: '',
    preferred_time: '',
    Assigned_date_and_time: '',
    phlebotomist_user_id: '', // Add this to capture the userID
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [messageResponse, setMessageResponse] = useState('');

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const uniqueIdFromURL = urlSearchParams.get('Unique_id');
    if (uniqueIdFromURL) {
      setUniqueId(uniqueIdFromURL);
      fetchDataForUniqueID(uniqueIdFromURL);
    }
  }, []);

  const fetchDataForUniqueID = async (uniqueId) => {
    const apiUrl = `/api/api/assignbookingsdata/assign/${uniqueId}/`;

    try {
      const response = await axiosInstance.get(apiUrl);
      const responseData = response.data;

      setData(responseData);
      setFormData({
        Unique_id: uniqueId,
        Assign_phelobo: responseData.booking_data.Assign_phelobo || '',
        date_for_collection:
          responseData.booking_data.date_for_collection ||
          new Date().toISOString().split('T')[0],
        preferred_time: responseData.booking_data.preferred_time || '',
        Assigned_date_and_time:
          responseData.booking_data.Assigned_date_and_time ||
          new Date().toISOString(),
        phlebotomist_user_id: '', // Initialize to empty
      });

      setScheduleDate(
        new Date(responseData.booking_data.date_for_collection || new Date())
      );
      const preferredTime = responseData.booking_data.preferred_time || '';
      if (preferredTime) {
        const [from, to] = preferredTime.split(' to ');
        const [fromH, fromM] = from.split(':');
        const [toH, toM] = to.split(':');
        setFromHours(fromH);
        setFromMinutes(fromM);
        setToHours(toH);
        setToMinutes(toM);
      }
      setAssignedDateTime(responseData.booking_data.Assigned_date_and_time);

      // Set the selected phlebotomist properly
      if (responseData.booking_data.Assign_phelobo && responseData.phlebotomist_data) {
        const assignedPhlebo = responseData.phlebotomist_data.find(
          (ph) => ph.name === responseData.booking_data.Assign_phelobo
        );
        if (assignedPhlebo) {
          setSelectedPhlebotomist(assignedPhlebo);
          setFormData((prevFormData) => ({
            ...prevFormData,
            phlebotomist_user_id: assignedPhlebo.userID, // Set the userID
          }));
        }
      }

      // Fetch special instructions and set already selected ones
      fetchSpecialInstructions(responseData.special_instructions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSpecialInstructions = async (fetchedInstructions) => {
    try {
      const response = await axiosInstance.get(`/api/api/special-instructions/`);
      setSpecialInstructions(response.data);

      // Pre-select instructions that are already in the database
      const selectedInstructionsFromDB = fetchedInstructions.map((instruction) => ({
        id: instruction.id,
        test_name: instruction.test_name,
        special_instructions: instruction.special_instructions,
      }));

      // Check 'None' by default
      if (selectedInstructionsFromDB.length === 0) {
        const noneInstruction = response.data.find(
          (instruction) => instruction.test_name === 'None'
        );
        if (noneInstruction) {
          setSelectedInstructions([noneInstruction]);
        }
      } else {
        setSelectedInstructions(selectedInstructionsFromDB);
      }
    } catch (error) {
      console.error('Error fetching special instructions:', error);
    }
  };

  const handlePhlebotomistChange = (selectedOption) => {
    const phlebotomist = data.phlebotomist_data.find(
      (ph) => ph.name === selectedOption.value
    );
    setSelectedPhlebotomist(phlebotomist); // Update the entire phlebotomist object
    setFormData((prevFormData) => ({
      ...prevFormData,
      Assign_phelobo: selectedOption.value,
      phlebotomist_user_id: phlebotomist.userID, // Update the userID
    }));
  };

  const handleDateChange = (date) => {
    setScheduleDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      date_for_collection: date.toISOString().split('T')[0],
    }));
  };

  const handleTimeChange = (selectedOption, type) => {
    const value = selectedOption.value;

    if (type === 'fromHours') {
      setFromHours(value);
      if (
        toHours &&
        (parseInt(toHours) < parseInt(value) ||
          (parseInt(toHours) === parseInt(value) &&
            parseInt(toMinutes) <= parseInt(fromMinutes)))
      ) {
        setToHours(value);
        setToMinutes((parseInt(fromMinutes) + 30) % 60);
      }
    } else if (type === 'fromMinutes') {
      setFromMinutes(value);
      if (
        toHours === fromHours &&
        parseInt(toMinutes) <= parseInt(value)
      ) {
        setToMinutes((parseInt(value) + 30) % 60);
      }
    } else if (type === 'toHours') {
      if (
        parseInt(value) < parseInt(fromHours) ||
        (parseInt(value) === parseInt(fromHours) &&
          parseInt(toMinutes) <= parseInt(fromMinutes))
      ) {
        alert('To time cannot be before from time.');
        return;
      }
      setToHours(value);
    } else if (type === 'toMinutes') {
      if (
        toHours === fromHours &&
        parseInt(value) <= parseInt(fromMinutes)
      ) {
        alert('To time cannot be before from time.');
        return;
      }
      setToMinutes(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      preferred_time: `${fromHours}:${fromMinutes} to ${toHours}:${toMinutes}`,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!selectedPhlebotomist) {
      errors.phlebotomist = 'Please select a phlebotomist';
    }
    if (!scheduleDate) {
      errors.date = 'Please select a schedule date';
    }
    if (!fromHours || !fromMinutes || !toHours || !toMinutes) {
      errors.time = 'Please select a valid time range';
    }
    return errors;
  };

  const submitForm = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      alert(Object.values(errors).join('\n'));
      return;
    }

    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const uniqueIdFromURL = urlSearchParams.get('Unique_id');

      // Fetch selected phlebotomist details
      const selectedPhleboData = data.phlebotomist_data.find(
        (ph) => ph.name === selectedPhlebotomist?.name
      );

      // Ensure that instructions are handled properly
      const instructions =
        selectedInstructions.length > 0
          ? selectedInstructions.map((instruction) => ({
              id: instruction.id,
              test_name: instruction.test_name,
              special_instructions: instruction.special_instructions,
            }))
          : data?.special_instructions || []; // Fallback to current instructions if no new ones are selected

      // Prepare the updated payload
      const updatedFormData = {
        ...formData,
        Unique_id: uniqueIdFromURL,
        date_for_collection: scheduleDate.toISOString().split('T')[0],
        preferred_time: `${fromHours}:${fromMinutes} to ${toHours}:${toMinutes}`,
        Assigned_date_and_time: new Date().toISOString(),
        instructions: instructions, // Always send instructions or fallback
        phlebo_mobile_number: selectedPhleboData?.mobile_no || '',
        phlebo_vaccine: selectedPhleboData?.vaccination_certificate || '',
        phlebo_profile: selectedPhleboData?.profile_picture || '',
        Assigned_phlebo_email: selectedPhleboData?.email || '',
        phlebotomist_user_id: selectedPhleboData?.userID || '', // Include the userID
      };

      // Send the PUT request to update the booking
      const response = await axiosInstance.put(
        `/api/api/bookings/update/${uniqueIdFromURL}/`,
        updatedFormData
      );

      setSuccessMessage(
        'Phlebotomist assigned and instructions sent successfully!'
      );
      setTimeout(() => setSuccessMessage(''), 3000);
      setAssignedDateTime(updatedFormData.Assigned_date_and_time);
      console.log('PUT Request Response:', response.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const formatDateTime = (datetime) => {
    if (!datetime) return 'N/A';
    const date = new Date(datetime);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    const d = new Date(date);
    return d.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const handleInstructionSelection = (instruction) => {
    if (instruction.test_name === 'None') {
      // If "None" is selected, unselect all other instructions
      setSelectedInstructions([
        {
          id: instruction.id,
          test_name: instruction.test_name,
          special_instructions: instruction.special_instructions,
        },
      ]);
    } else {
      // If another instruction is selected, unselect "None"
      setSelectedInstructions((prevSelected) => {
        const noneSelected = prevSelected.some(
          (item) => item.test_name === 'None'
        );
        if (noneSelected) {
          // Remove "None" from the selected instructions
          return prevSelected
            .filter((item) => item.test_name !== 'None')
            .concat(instruction);
        }

        // If "None" is not selected, just toggle the selected instruction
        if (prevSelected.some((item) => item.id === instruction.id)) {
          return prevSelected.filter((item) => item.id !== instruction.id);
        }
        return [...prevSelected, instruction];
      });
    }
  };

  const timeOptions = [...Array(24).keys()].map((hour) => ({
    value: hour.toString().padStart(2, '0'),
    label: hour.toString().padStart(2, '0'),
  }));

  const minuteOptions = [...Array(12).keys()].map((minute) => ({
    value: (minute * 5).toString().padStart(2, '0'),
    label: (minute * 5).toString().padStart(2, '0'),
  }));

  const sendSmsToPhlebo = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const uniqueIdFromURL = urlSearchParams.get('Unique_id');
      const response = await axiosInstance.get(`/api/api/bookings/update/${uniqueIdFromURL}/`);
      const bookingData = response.data;
      const Assigned_Phlebo_mobileNumber = bookingData.phlebotomist_phone;
      const Patient_MobileNumber = bookingData.mobile_number;
  
      if (!Assigned_Phlebo_mobileNumber) {
        alert('Phlebotomist phone number is missing. Please check the booking data.');
        return;
      }
  
      const smsData = {
        Assigned_Phlebo_mobile_number: Assigned_Phlebo_mobileNumber,
        Patient_mobile_number: Patient_MobileNumber,
        date_for_collection: bookingData.date_for_collection,
        preferred_time: bookingData.preferred_time,
        patient_name: bookingData.patient_name,
        uniqueId: bookingData.Unique_id,
      };
  
      // Send SMS asynchronously without awaiting
      axiosInstance.post('/api/send-sms-to-phlebo/', smsData)
        .then(smsResponse => {
          if (smsResponse.status === 200) {
            // Optionally, use a non-blocking notification here
            console.log('SMS sent to Phlebotomist successfully!');
          } else {
            console.error('Failed to send SMS.');
          }
        })
        .catch(smsError => {
          console.error('Error sending SMS:', smsError);
        });
  
      // Proceed to update the SMS sent count without waiting for the SMS to be sent
      const updatedSmsCount = bookingData.sms_sent_to_phlebo + 1;
      axiosInstance.put(`/api/api/bookings/update/${uniqueIdFromURL}/`, {
        sms_sent_to_phlebo: updatedSmsCount,
      })
        .then(putResponse => {
          if (putResponse.status === 200) {
            console.log('SMS sent count updated successfully.');
            // Optionally, notify the user non-blockingly
            alert('SMS sent to Phlebotomist successfully!');
          } else {
            console.error('Failed to update SMS sent count.');
            alert('Failed to update SMS sent count. Please try again.');
          }
        })
        .catch(putError => {
          console.error('Error updating SMS sent count:', putError);
          alert('Error updating SMS sent count. Please try again.');
        });
  
    } catch (error) {
      console.error('Error sending SMS:', error);
      alert('Failed to send SMS. Please try again.');
    }
  };
  

  const sendSmsToPatient = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const uniqueIdFromURL = urlSearchParams.get('Unique_id');
      const response = await axiosInstance.get(`/api/api/bookings/update/${uniqueIdFromURL}/`);
      const bookingData = response.data;
      const Assigned_Phlebo_mobileNumber = bookingData.phlebotomist_phone;
      const Patient_MobileNumber = bookingData.mobile_number;
  
      if (!Assigned_Phlebo_mobileNumber) {
        alert('Phlebotomist phone number is missing. Please check the booking data.');
        return;
      }
  
      const smsData = {
        Assigned_Phlebo_mobile_number: Assigned_Phlebo_mobileNumber,
        Patient_mobile_number: Patient_MobileNumber,
        date_for_collection: bookingData.date_for_collection,
        uniqueId: bookingData.Unique_id,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
      };
  
      // Send SMS asynchronously without awaiting
      axiosInstance.post('/api/send-sms-to-patient/', smsData)
        .then(smsResponse => {
          if (smsResponse.status === 200) {
            console.log('SMS sent to Patient successfully!');
          } else {
            console.error('Failed to send SMS to Patient.');
          }
        })
        .catch(smsError => {
          console.error('Error sending SMS to Patient:', smsError);
        });
  
      // Proceed to update the SMS sent count without waiting for the SMS to be sent
      const updatedSmsCount = bookingData.sms_sent_to_patient + 1;
      axiosInstance.put(`/api/api/bookings/update/${uniqueIdFromURL}/`, {
        sms_sent_to_patient: updatedSmsCount,
      })
        .then(putResponse => {
          if (putResponse.status === 200) {
            console.log('SMS sent count updated successfully.');
            // Optionally, notify the user non-blockingly
            alert('SMS sent to Patient successfully!');
          } else {
            console.error('Failed to update SMS sent count.');
            alert('Failed to update SMS sent count. Please try again.');
          }
        })
        .catch(putError => {
          console.error('Error updating SMS sent count:', putError);
          alert('Error updating SMS sent count. Please try again.');
        });
  
    } catch (error) {
      console.error('Error sending SMS to Patient:', error);
      alert('Failed to send SMS to Patient. Please try again.');
    }
  };
  

  const sendEmailToPhlebo = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const uniqueIdFromURL = urlSearchParams.get('Unique_id');
  
      const response = await axiosInstance.get(`/api/api/bookings/update/${uniqueIdFromURL}/`);
      const bookingData = response.data;
  
      const emailData = {
        Assigned_phlebo_email: bookingData.Assigned_phlebo_email,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
        patient_name: bookingData.patient_name,
        booking_id: bookingData.Unique_id,
        scheduled_date: bookingData.date_for_collection,
        scheduled_time: bookingData.preferred_time,
      };
  
      // Send Email asynchronously without awaiting
      axiosInstance.post('/api/send-email-to-phlebo/', emailData)
        .then(emailResponse => {
          if (emailResponse.status === 200) {
            console.log('Email sent to phlebotomist successfully!');
            // Optionally, notify the user non-blockingly
            alert('Email sent to phlebotomist successfully!');
          } else {
            console.error('Failed to send email to phlebotomist.');
            alert('Failed to send email to the phlebotomist. Please try again.');
          }
        })
        .catch(emailError => {
          console.error('Error sending email to phlebotomist:', emailError);
          alert('Failed to send email to the phlebotomist. Please try again.');
        });
  
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };
  

  const sendEmailToPatient = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const uniqueIdFromURL = urlSearchParams.get('Unique_id');
  
      // Fetch the booking data for the specific ID
      const response = await axiosInstance.get(`/api/api/bookings/update/${uniqueIdFromURL}/`);
      const bookingData = response.data;
  
      // Clean up 'media/media/' duplication if present in paths
      const cleanPath = (path) =>
        path && path.includes('media/media/') ? path.replace('media/media/', 'media/') : path;
  
      const cleanedProfilePicture = cleanPath(bookingData.phlebotomist_profile_picture);
      const cleanedVaccinationCertificate = cleanPath(
        bookingData.phlebotomist_vaccination_certificate
      );
  
      // Filter the instructions to exclude those with the test_name "None"
      let filteredInstructions = [];
      try {
        filteredInstructions = JSON.parse(bookingData.special_instructions).filter(
          (instruction) => instruction.test_name !== 'None'
        );
      } catch (parseError) {
        console.error('Error parsing special_instructions:', parseError);
      }
  
      // Prepare the email data, including instructions only if present
      const emailData = {
        Assigned_phlebo_email: bookingData.Assigned_phlebo_email,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
        patient_name: bookingData.patient_name,
        booking_id: bookingData.Unique_id,
        scheduled_date: bookingData.date_for_collection,
        scheduled_time: bookingData.preferred_time,
        Assigned_Phlebo_mobile_number: bookingData.phlebotomist_phone,
        patient_email: bookingData.patient_email,
        patient_address: bookingData.address,
        phlebotomist_profile_picture: cleanedProfilePicture, // Use cleaned profile picture path
        phlebotomist_vaccination_certificate: cleanedVaccinationCertificate, // Use cleaned vaccination certificate path
        instructions: filteredInstructions.length > 0 ? filteredInstructions : null, // Include instructions only if there are valid ones
      };
  
      // Send Email asynchronously without awaiting
      axiosInstance.post('/api/send-email-to-patient/', emailData)
        .then(emailResponse => {
          if (emailResponse.status === 200) {
            console.log('Email sent to patient successfully!');
            alert('Email sent to patient successfully!');
          } else {
            console.error('Failed to send email to patient.');
            alert('Failed to send email to the patient. Please try again.');
          }
        })
        .catch(emailError => {
          console.error('Error sending email to patient:', emailError);
          alert('Failed to send email to the patient. Please try again.');
        });
  
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };
  

  const sendEmailWithEmoji = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const uniqueIdFromURL = urlSearchParams.get('Unique_id');
  
      // Fetch the booking data for the specific ID
      const response = await axiosInstance.get(`/api/api/bookings/update/${uniqueIdFromURL}/`);
      const bookingData = response.data;
  
      // Clean up 'media/media/' duplication if present in paths
      const cleanPath = (path) =>
        path && path.includes('media/media/') ? path.replace('media/media/', 'media/') : path;
  
      const cleanedProfilePicture = cleanPath(bookingData.phlebotomist_profile_picture);
      const cleanedVaccinationCertificate = cleanPath(
        bookingData.phlebotomist_vaccination_certificate
      );
  
      // Filter the instructions to exclude those with the test_name "None"
      let filteredInstructions = [];
      try {
        filteredInstructions = JSON.parse(bookingData.special_instructions).filter(
          (instruction) => instruction.test_name !== 'None'
        );
      } catch (parseError) {
        console.error('Error parsing special_instructions:', parseError);
      }
  
      // Prepare the email data, including instructions only if present
      const emailData = {
        Assigned_phlebo_email: bookingData.Assigned_phlebo_email,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
        patient_name: bookingData.patient_name,
        booking_id: bookingData.Unique_id,
        scheduled_date: bookingData.date_for_collection,
        scheduled_time: bookingData.preferred_time,
        Assigned_Phlebo_mobile_number: bookingData.phlebotomist_phone,
        patient_email: bookingData.patient_email,
        patient_address: bookingData.address,
        phlebotomist_profile_picture: cleanedProfilePicture, // Use cleaned profile picture path
        phlebotomist_vaccination_certificate: cleanedVaccinationCertificate, // Use cleaned vaccination certificate path
        instructions: filteredInstructions.length > 0 ? filteredInstructions : null, // Include instructions only if there are valid ones
      };
  
      // Send Email asynchronously without awaiting
      axiosInstance.post('/api/emoji-send-email-to-patient/', emailData)
        .then(emailResponse => {
          if (emailResponse.status === 200) {
            console.log('Email with emoji sent to patient successfully!');
            alert('Email with emoji sent to patient successfully!');
          } else {
            console.error('Failed to send email with emoji to patient.');
            alert('Failed to send email with emoji to the patient. Please try again.');
          }
        })
        .catch(emailError => {
          console.error('Error sending email with emoji to patient:', emailError);
          alert('Failed to send email with emoji to the patient. Please try again.');
        });
  
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };
  

  const sendWhatsappToPatient = async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const uniqueIdFromURL = urlSearchParams.get('Unique_id');
      const response = await axiosInstance.get(`/api/api/bookings/update/${uniqueIdFromURL}/`);
      const bookingData = response.data;
      const Assigned_Phlebo_mobileNumber = bookingData.phlebotomist_phone;
      const Patient_whatsapp_Number = bookingData.whatsapp_number;
  
      if (!Patient_whatsapp_Number) {
        alert('Patient WhatsApp number is missing. Please check the booking data.');
        return;
      }
  
      const smsData = {
        Assigned_Phlebo_mobile_number: Assigned_Phlebo_mobileNumber,
        Patient_whatsapp_Number: Patient_whatsapp_Number,
        date_for_collection: bookingData.date_for_collection,
        uniqueId: bookingData.Unique_id,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
        Patient_name: bookingData.patient_name,
        patient_address: bookingData.address,
      };
  
      // Send WhatsApp message asynchronously without awaiting
      axiosInstance.post('/api/whatsapp-to-patient/', smsData)
        .then(smsResponse => {
          if (smsResponse.status === 200) {
            console.log('WhatsApp message sent to Patient successfully!');
            alert('WhatsApp message sent to Patient successfully!');
          } else {
            console.error('Failed to send WhatsApp message to Patient.');
            alert('Failed to send WhatsApp message to Patient. Please try again.');
          }
        })
        .catch(smsError => {
          console.error('Error sending WhatsApp message to Patient:', smsError);
          alert('Failed to send WhatsApp message to Patient. Please try again.');
        });
  
      // Proceed to update the SMS sent count without waiting for the WhatsApp message to be sent
      const updatedSmsCount = bookingData.sms_sent_to_patient + 1;
      axiosInstance.put(`/api/api/bookings/update/${uniqueIdFromURL}/`, {
        sms_sent_to_patient: updatedSmsCount,
      })
        .then(putResponse => {
          if (putResponse.status === 200) {
            console.log('WhatsApp message sent count updated successfully.');
          } else {
            console.error('Failed to update WhatsApp message sent count.');
            alert('Failed to update WhatsApp message sent count. Please try again.');
          }
        })
        .catch(putError => {
          console.error('Error updating WhatsApp message sent count:', putError);
          alert('Error updating WhatsApp message sent count. Please try again.');
        });
  
    } catch (error) {
      console.error('Error sending WhatsApp message to Patient:', error);
      alert('Failed to send WhatsApp message to Patient. Please try again.');
    }
  };
  

  return (
    <div className="assign-container">
      <h2>Phlebo Assign Details</h2>

      {data && data.booking_data ? (
        <div className="patient-info">
          <table className="info-table">
            <tbody>
              <tr>
                <td>
                  <strong>Patient Name:</strong>
                </td>
                <td>{data.booking_data.patient_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Current Phlebo Assigned to This Task:</strong>
                </td>
                <td>{data.booking_data.Assign_phelobo || 'N/A'}</td>
              </tr>
              <tr>
                <td>
                  <strong>Date & Time (Assigned On):</strong>
                </td>
                <td>{formatDateTime(assignedDateTime)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Date & Time (Scheduled On):</strong>
                </td>
                <td>
                  {formatDate(scheduleDate)} {data.booking_data.preferred_time}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>SMS Sent To Phlebo:</strong>
                </td>
                <td>{data.booking_data.sms_sent_to_phlebo}</td>
              </tr>
              <tr>
                <td>
                  <strong>SMS Sent To Patient:</strong>
                </td>
                <td>{data.booking_data.sms_sent_to_patient}</td>
              </tr>
              <tr>
                <td>
                  <strong>Mail Sent To Phlebo:</strong>
                </td>
                <td>{data.booking_data.mail_sent_to_phlebo}</td>
              </tr>
              <tr>
                <td>
                  <strong>Mail Sent To User:</strong>
                </td>
                <td>{data.booking_data.mail_sent_to_user}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      <div className="AssignPhleboHeading">
        <h2>Assign Phlebotomist</h2>
      </div>

      <div className="ASsignedMaindIvForflex">
        {data && data.phlebotomist_data ? (
          <div className="assign-phlebotomist">
            <div>
              <label>Select Phlebo</label>
              <Select
                options={data.phlebotomist_data.map((phlebotomist) => ({
                  value: phlebotomist.name,
                  label: phlebotomist.name,
                }))}
                value={
                  selectedPhlebotomist
                    ? {
                        value: selectedPhlebotomist.name,
                        label: selectedPhlebotomist.name,
                      }
                    : data.booking_data.Assign_phelobo
                    ? {
                        value: data.booking_data.Assign_phelobo,
                        label: data.booking_data.Assign_phelobo,
                      }
                    : null
                }
                onChange={(selectedOption) => handlePhlebotomistChange(selectedOption)}
              />
            </div>
          </div>
        ) : null}

        <div className="schedule-info">
          <div className="schedule-date">
            <div className="AssignedLabelDiv">
              <label>Schedule Date: </label>
              <DatePicker
                selected={scheduleDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                className="date-picker"
              />
            </div>
          </div>
          <div className="FromHoursAssignedFlexDiv">
            <div className="preferred-time">
              <div className="AssignedLabelDiv">
                <label>From Hours: </label>
                <Select
                  options={timeOptions}
                  value={
                    fromHours ? { value: fromHours, label: fromHours } : null
                  }
                  onChange={(selectedOption) =>
                    handleTimeChange(selectedOption, 'fromHours')
                  }
                />
              </div>
              <div className="AssignedLabelDiv">
                <label>From Minutes: </label>
                <Select
                  options={minuteOptions}
                  value={
                    fromMinutes
                      ? { value: fromMinutes, label: fromMinutes }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleTimeChange(selectedOption, 'fromMinutes')
                  }
                />
              </div>
            </div>
            <div className="FromHoursAssignedFlexDivss">
              <div className="AssignedLabelDiv">
                <label>To Hours: </label>
                <Select
                  options={timeOptions}
                  value={toHours ? { value: toHours, label: toHours } : null}
                  onChange={(selectedOption) =>
                    handleTimeChange(selectedOption, 'toHours')
                  }
                />
              </div>
              <div className="AssignedLabelDiv">
                <label>To Minutes: </label>
                <Select
                  options={minuteOptions}
                  value={
                    toMinutes ? { value: toMinutes, label: toMinutes } : null
                  }
                  onChange={(selectedOption) =>
                    handleTimeChange(selectedOption, 'toMinutes')
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {successMessage && <p className="success-message">{successMessage}</p>}
      {messageResponse && <p className="success-message">{messageResponse}</p>}

      {specialInstructions.length > 0 && (
        <div className="special-instructions">
          <h3>Special Instructions</h3>
          <ul>
            {specialInstructions.map((instruction) => (
              <li key={instruction.id}>
                <input
                  type="checkbox"
                  checked={selectedInstructions.some(
                    (item) => item.id === instruction.id
                  )}
                  onChange={() => handleInstructionSelection(instruction)}
                />
                <strong>{instruction.test_name}: </strong>
              </li>
            ))}
          </ul>
        </div>
      )}

      <button className="submit-button" onClick={submitForm}>
        Save
      </button>
      <button className="submit-button" onClick={sendSmsToPhlebo}>
        SMS To Phlebo
      </button>
      <button className="submit-button" onClick={sendSmsToPatient}>
        SMS To Patient
      </button>
      <button className="submit-button" onClick={sendEmailToPhlebo}>
        Email To Phlebo
      </button>
      <button className="submit-button" onClick={sendEmailToPatient}>
        Email To Patient
      </button>
      <button className="submit-button" onClick={sendEmailWithEmoji}>
        Email With Emoji
      </button>
      <button className="submit-button" onClick={sendWhatsappToPatient}>
        WhatsApp To Patient
      </button>
    </div>
  );
};

export default ReportAssign;
