import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import the axios instance with token handling
import { Link } from 'react-router-dom';
import './UpdateTimeSlots.css';

const UpdateTimeSlots = () => {
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [formData, setFormData] = useState({
        slot_name: '',
        slot_for: 'HCS',
        normal_diff: 0,
        normal_start_time: '00:00',
        normal_end_time: '00:00',
        book_min_prior_time: 0,
        before_applicable_date: 0,
        before_from_time: '00:00',
        before_to_time: '00:00',
        before_diff: 0,
        before_start_time: '00:00',
        before_end_time: '00:00',
        after_applicable_date: 1,
        after_from_time: '00:00',
        after_to_time: '00:00',
        after_diff: 0,
        after_start_time: '00:00',
        after_end_time: '00:00',
    });

    const [generatedRoutineSlots, setGeneratedRoutineSlots] = useState([]);
    const [generatedBeforeSlots, setGeneratedBeforeSlots] = useState([]);
    const [generatedAfterSlots, setGeneratedAfterSlots] = useState([]);

    useEffect(() => {
        // Fetch all time slots
        axiosInstance.get('/api/api/all-time-slots/')
            .then(response => setTimeSlots(response.data))
            .catch(error => console.error('Error fetching time slots:', error));
    }, []);

    const handleSelectSlot = (slot) => {
        setSelectedSlot(slot);
        setFormData({
            slot_name: slot.slot_name,
            slot_for: slot.slot_for,
            normal_diff: slot.normal_diff,
            normal_start_time: slot.normal_start_time,
            normal_end_time: slot.normal_end_time,
            book_min_prior_time: slot.book_min_prior_time,
            before_applicable_date: slot.before_applicable_date,
            before_from_time: slot.before_from_time,
            before_to_time: slot.before_to_time,
            before_diff: slot.before_diff,
            before_start_time: slot.before_start_time,
            before_end_time: slot.before_end_time,
            after_applicable_date: slot.after_applicable_date,
            after_from_time: slot.after_from_time,
            after_to_time: slot.after_to_time,
            after_diff: slot.after_diff,
            after_start_time: slot.after_start_time,
            after_end_time: slot.after_end_time,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const generateRoutineSlots = () => {
        if (formData.normal_start_time && formData.normal_end_time && formData.normal_diff > 0) {
            const slots = generateSlots(formData.normal_start_time, formData.normal_end_time, formData.normal_diff);
            setGeneratedRoutineSlots(slots);
        }
    };

    const generateBeforeSlots = () => {
        if (formData.before_start_time && formData.before_end_time && formData.before_diff > 0) {
            const slots = generateSlots(formData.before_start_time, formData.before_end_time, formData.before_diff);
            setGeneratedBeforeSlots(slots);
        }
    };

    const generateAfterSlots = () => {
        if (formData.after_start_time && formData.after_end_time && formData.after_diff > 0) {
            const slots = generateSlots(formData.after_start_time, formData.after_end_time, formData.after_diff);
            setGeneratedAfterSlots(slots);
        }
    };

    const generateSlots = (startTimeStr, endTimeStr, diffInMinutes) => {
        const slots = [];
        let startTime = new Date(`1970-01-01T${startTimeStr}`);
        const endTime = new Date(`1970-01-01T${endTimeStr}`);

        let currentTime = new Date(startTime);

        while (currentTime < endTime) {
            const nextTime = new Date(currentTime.getTime() + diffInMinutes * 60000);
            if (nextTime <= endTime) {
                slots.push(`${formatTime(currentTime)} To ${formatTime(nextTime)}`);
            }
            currentTime = nextTime;
        }

        return slots;
    };

    const formatTime = (date) => {
        return date.toTimeString().split(' ')[0];
    };

    const handleSave = () => {
        axiosInstance.put(`/api/api/time-slots/${selectedSlot.id}/`, formData)
            .then(response => {
                alert('Time slot updated successfully!');
                setTimeSlots(timeSlots.map(slot => slot.id === selectedSlot.id ? response.data : slot));
                setSelectedSlot(null);
            })
            .catch(error => console.error('Error updating time slot:', error));
    };

    return (
        <div className="update-time-slots">
            <h2>Update Time Slots</h2>
            <Link to="/master/TimeSlots">
                <button className="back-button">Back To Menu</button>
            </Link>
            
            <div className="slot-list">
                <h3>Select a Time Slot to Edit:</h3>
                <ul>
                    {timeSlots.map(slot => (
                        <li key={slot.id} onClick={() => handleSelectSlot(slot)}>
                            {slot.slot_name} ({slot.normal_start_time} - {slot.normal_end_time})
                        </li>
                    ))}
                </ul>
            </div>

            {selectedSlot && (
                <div className="slot-edit-form">
                    <h3>Edit Time Slot: {selectedSlot.slot_name}</h3>
                    <div className="form-group">
                        <label>Slot Name</label>
                        <input
                            type="text"
                            name="slot_name"
                            value={formData.slot_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>For</label>
                        <select
                            name="slot_for"
                            value={formData.slot_for}
                            onChange={handleChange}
                        >
                            <option value="HCS">HCS</option>
                        </select>
                    </div>

                    <div className="routine-day-time-slots">
                        <h4>ROUTINE DAY TIME SLOTS</h4>
                        <div className="form-group">
                            <label>Normal Diff. (minutes)</label>
                            <input
                                type="number"
                                name="normal_diff"
                                value={formData.normal_diff}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Normal Start Time</label>
                            <input
                                type="time"
                                name="normal_start_time"
                                value={formData.normal_start_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Normal End Time</label>
                            <input
                                type="time"
                                name="normal_end_time"
                                value={formData.normal_end_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Book Min Prior Time (minutes)</label>
                            <input
                                type="number"
                                name="book_min_prior_time"
                                value={formData.book_min_prior_time}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="button" className="view-button" onClick={generateRoutineSlots}>View</button>
                        <button type="button" className="save-button" onClick={handleSave}>Save Routine Time Slots</button>
                    </div>

                    <div className="before-routine-time-slots">
                        <h4>BEFORE ROUTINE TIME SLOTS</h4>
                        <div className="form-group">
                            <label>Applicable Date +</label>
                            <input
                                type="number"
                                name="before_applicable_date"
                                value={formData.before_applicable_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Before From Time</label>
                            <input
                                type="time"
                                name="before_from_time"
                                value={formData.before_from_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Before To Time</label>
                            <input
                                type="time"
                                name="before_to_time"
                                value={formData.before_to_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Before Diff. (minutes)</label>
                            <input
                                type="number"
                                name="before_diff"
                                value={formData.before_diff}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Before Start Time</label>
                            <input
                                type="time"
                                name="before_start_time"
                                value={formData.before_start_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Before End Time</label>
                            <input
                                type="time"
                                name="before_end_time"
                                value={formData.before_end_time}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="button" className="view-button" onClick={generateBeforeSlots}>View</button>
                        <button type="button" className="save-button" onClick={handleSave}>Save Before Routine Time Slots</button>
                    </div>

                    <div className="after-routine-time-slots">
                        <h4>AFTER ROUTINE TIME SLOTS</h4>
                        <div className="form-group">
                            <label>Applicable Date +</label>
                            <input
                                type="number"
                                name="after_applicable_date"
                                value={formData.after_applicable_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>After From Time</label>
                            <input
                                type="time"
                                name="after_from_time"
                                value={formData.after_from_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>After To Time</label>
                            <input
                                type="time"
                                name="after_to_time"
                                value={formData.after_to_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>After Diff. (minutes)</label>
                            <input
                                type="number"
                                name="after_diff"
                                value={formData.after_diff}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>After Start Time</label>
                            <input
                                type="time"
                                name="after_start_time"
                                value={formData.after_start_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>After End Time</label>
                            <input
                                type="time"
                                name="after_end_time"
                                value={formData.after_end_time}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="button" className="view-button" onClick={generateAfterSlots}>View</button>
                        <button type="button" className="save-button" onClick={handleSave}>Save After Routine Time Slots</button>
                    </div>

                </div>
            )}

            <div className="generated-time-slots">
                <h4>Generated Routine Time Slots</h4>
                <ul>
                    {generatedRoutineSlots.map((slot, index) => (
                        <li key={index}>{slot}</li>
                    ))}
                </ul>

                <h4>Generated Before Routine Time Slots</h4>
                <ul>
                    {generatedBeforeSlots.map((slot, index) => (
                        <li key={index}>{slot}</li>
                    ))}
                </ul>

                <h4>Generated After Routine Time Slots</h4>
                <ul>
                    {generatedAfterSlots.map((slot, index) => (
                        <li key={index}>{slot}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default UpdateTimeSlots;
