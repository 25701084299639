import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import './UpdateUser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faEye,
  faEyeSlash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function UpdateUser() {
  const [formData, setFormData] = useState({
    filter: '',
    user_type: '',
  });
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [imagePreviews, setImagePreviews] = useState({
    profile_picture: null,
    vaccination_certificate: null,
  });

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get('api/current-user/'); // Adjusted URL
        const currentUser = response.data;
        if (currentUser.is_superuser || currentUser.user_type === 'admin') {
          setIsAdminUser(true);
        }
      } catch (error) {
        console.error('Error fetching current user data:', error);
      }
    };

    fetchCurrentUser();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFilter = async () => {
    setLoading(true);
    try {
      const queryParams = {
        filter: formData.filter,
        user_type: formData.user_type,
      };

      const response = await axiosInstance.get('api/api/filter-users/', {
        params: queryParams,
      }); // Adjusted URL
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Error filtering users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (user) => {
    console.log('Selected user:', user);
    setSelectedUser(user);
    setUpdateMessage('');
    setImagePreviews({
      profile_picture: user.profile_picture
        ? `https://api.dangsccg.co.in${user.profile_picture}`
        : null,
      vaccination_certificate: user.vaccination_certificate
        ? `https://api.dangsccg.co.in${user.vaccination_certificate}`
        : null,
    });
  };

  const handleUpdateInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      setSelectedUser({ ...selectedUser, [name]: file });
  
      // Preview the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews({ ...imagePreviews, [name]: reader.result });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setSelectedUser({ ...selectedUser, [name]: value });
    }
  };
  

  const handleUpdateUser = async () => {
    if (selectedUser.password && selectedUser.password !== selectedUser.confirm_password) {
      setUpdateMessage("Passwords do not match.");
      return;
    }
  
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      for (const key in selectedUser) {
        if (
          selectedUser[key] !== null &&
          selectedUser[key] !== undefined &&
          key !== 'userID'
        ) {
          if (
            (key === 'profile_picture' || key === 'vaccination_certificate') &&
            selectedUser[key] instanceof File
          ) {
            formDataToSend.append(key, selectedUser[key]);
          } else if (
            key !== 'profile_picture' &&
            key !== 'vaccination_certificate'
          ) {
            formDataToSend.append(key, selectedUser[key]);
          }
        }
      }
  
      await axiosInstance.put(
        `api/api/update-user/${selectedUser.userID}/`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUpdateMessage('User updated successfully');
      handleFilter(); // Refresh the filtered users list
    } catch (error) {
      console.error('Error updating user:', error);
      setUpdateMessage(
        'Error updating user: ' +
        (error.response?.data?.detail || error.message)
      );
    } finally {
      setLoading(false);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="update-user-container">
      <div className="update-user-header">
        <h2>Search and Update User</h2>
        <Link to="/User">
          <button className="back-button">Back To Menu</button>
        </Link>
      </div>
      <div className="update-user-filters">
        <div className="filter-group">
          <label htmlFor="filter">Enter Name/Email/Mobile No.:</label>
          <input
            type="text"
            name="filter"
            id="filter"
            value={formData.filter}
            onChange={handleInputChange}
          />
        </div>
        <div className="filter-group">
          <label htmlFor="user_type">User Type:</label>
          <select
            name="user_type"
            id="user_type"
            value={formData.user_type}
            onChange={handleInputChange}
          >
            <option value="">-- Select --</option>
            <option value="admin">Admin</option>
            <option value="manager">Manager</option>
            <option value="employee">Employee</option>
            <option value="phlebotomist">Phlebotomist</option>
          </select>
        </div>
        <button onClick={handleFilter} className="filter-button">
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Search'
          )}
        </button>
      </div>

      <div className="user-table">
        <table>
          <thead>
            <tr>
              <th>Action</th>
              <th>User Type</th>
              <th>User Detail</th>
              <th>Id No.</th>
              <th>Address</th>
              <th>User Image</th>
              <th>Vaccination Certificate</th>
              {isAdminUser && <th>Username</th>}
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.userID}>
                <td>
                  <button
                    onClick={() => handleSelect(user)}
                    className="select-button"
                  >
                    Select
                  </button>
                </td>
                <td>{user.user_type}</td>
                <td>
                  <div>
                    <strong>{user.name}</strong>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faPhone} className="icon" />{' '}
                    {user.mobile_no}
                    <span className="whatsapp">
                      <FontAwesomeIcon icon={faWhatsapp} className="icon" />{' '}
                      {user.whatsapp_no}
                    </span>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faEnvelope} className="icon" />{' '}
                    {user.email}
                  </div>
                </td>
                <td>{user.userID}</td>
                <td>{user.address}</td>
                <td>
                  {user.profile_picture && (
                    <img
                      src={`https://api.dangsccg.co.in${user.profile_picture}`}
                      alt="User"
                      className="user-image"
                    />
                  )}
                </td>
                <td>
                  {user.vaccination_certificate && (
                    <a
                      href={`https://api.dangsccg.co.in${user.vaccination_certificate}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Certificate
                    </a>
                  )}
                </td>
                {isAdminUser && <td>{user.username}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedUser && (
        <div className="update-form">
          <h3>Update User: {selectedUser.name}</h3>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={selectedUser.name || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="user_type">User Type:</label>
            <select
              name="user_type"
              id="user_type"
              value={selectedUser.user_type || ''}
              onChange={handleUpdateInputChange}
            >
              <option value="admin">Admin</option>
              <option value="manager">Manager</option>
              <option value="employee">Employee</option>
              <option value="phlebotomist">Phlebotomist</option>
              <option value="qa">QA</option>
              <option value="hr">HR</option>

            
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={selectedUser.email || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile_no">Mobile No:</label>
            <input
              type="tel"
              id="mobile_no"
              name="mobile_no"
              value={selectedUser.mobile_no || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="whatsapp_no">WhatsApp No:</label>
            <input
              type="tel"
              id="whatsapp_no"
              name="whatsapp_no"
              value={selectedUser.whatsapp_no || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <textarea
              id="address"
              name="address"
              value={selectedUser.address || ''}
              onChange={handleUpdateInputChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={selectedUser.city || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="pincode">Pincode:</label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              value={selectedUser.pincode || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="state">State:</label>
            <input
              type="text"
              id="state"
              name="state"
              value={selectedUser.state || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              id="country"
              name="country"
              value={selectedUser.country || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          {isAdminUser && (
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={selectedUser.username || ''}
                onChange={handleUpdateInputChange}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="password">New Password:</label>
            <div className="password-input">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                onChange={handleUpdateInputChange}
                placeholder="Leave blank to keep current password"
              />
         
            </div>
          </div>
          <div className="form-group">
              <label htmlFor="confirm_password">Confirm Password:</label>
              <div className="password-input">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="confirm_password"
                  name="confirm_password"
                  onChange={handleUpdateInputChange}
                  placeholder="Confirm new password"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="toggle-password"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

          {selectedUser.user_type === 'phlebotomist' && (
            <>
              <div className="form-group">
                <label htmlFor="profile_picture">Profile Picture:</label>
                <input
                  type="file"
                  id="profile_picture"
                  name="profile_picture"
                  onChange={handleUpdateInputChange}
                  accept="image/*"
                />
                {imagePreviews.profile_picture && (
                  <img
                    src={imagePreviews.profile_picture}
                    alt="Profile Preview"
                    className="preview-image"
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="vaccination_certificate">
                  Vaccination Certificate:
                </label>
                <input
                  type="file"
                  id="vaccination_certificate"
                  name="vaccination_certificate"
                  onChange={handleUpdateInputChange}
                  accept="image/*,application/pdf"
                />
                {imagePreviews.vaccination_certificate &&
                  (imagePreviews.vaccination_certificate.includes('data:image') ? (
                    <img
                      src={imagePreviews.vaccination_certificate}
                      alt="Certificate Preview"
                      className="preview-image"
                    />
                  ) : (
                    <a
                      href={imagePreviews.vaccination_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Selected Certificate
                    </a>
                  ))}
              </div>
            </>
          )}
          <button onClick={handleUpdateUser} className="update-button">
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Update User'
            )}
          </button>
          {updateMessage && (
            <div className="update-message">{updateMessage}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default UpdateUser;
