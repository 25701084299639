import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem('access_token');
  const userRole = localStorage.getItem('user_role');

  if (!token) {
    // If there's no token, redirect to login
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(userRole)) {
    // If user role is not in allowed roles, redirect to not authorized page
    return <Navigate to="/not-authorized" />;
  }

  // If everything is fine, render the children
  return children;
};

export default ProtectedRoute;