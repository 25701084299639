import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Use axiosInstance instead of axios for token management
import './DeleteArticleCRM.css';
import { Link } from 'react-router-dom';

const DeleteArticleCRM = () => {
  const [articles, setArticles] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axiosInstance.get('/api/get_articles/');

        if (response.status === 200) {
          const sortedArticles = response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
          setArticles(sortedArticles);
        } else {
          console.error('Error fetching articles. Status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  const handleDelete = async (articleId) => {
    const confirmed = window.confirm('Are you sure you want to delete this article?');
    if (confirmed) {
      try {
        const response = await axiosInstance.delete(`/api/delete_article/${articleId}/`);
        if (response.status === 204 || response.status === 200) {
          alert('Article deleted successfully.');
          window.location.reload(); // Reload the page after successful deletion
        } else {
          alert('Failed to delete article.');
        }
      } catch (error) {
        console.error('Error deleting article:', error);
        alert('Failed to delete article.');
      }
    }
  };

  const filteredArticles = articles.filter((article) => {
    return article.article_heading.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <div className="DeleteArticleTopHeadingAndBackButton">
        <h2>Article Details</h2>
        <Link to="/News/ArticleCRM">
          <button>Back To Menu</button>
        </Link>
      </div>
      <div className="DeleteArticleFilter">
        <div className="FilterMain">
          <input
            type="text"
            placeholder="Search Headlines"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="DeleteArticleTable">
          <thead>
            <tr>
              <th>Action</th>
              <th>Article Heading</th>
              <th>Released on</th>
              <th>Content</th>
              <th>Thumbnail Image</th>
              <th>Alt Text</th>
            </tr>
          </thead>
          <tbody>
            {filteredArticles.map((article) => (
              <tr key={article.id}>
                <td>
                  <button onClick={() => handleDelete(article.id)}>Delete</button>
                </td>
                <td>{article.article_heading}</td>
                <td>{article.release_date}</td>
                <td>{article.articles_content}</td>
                <td>
                  <img src={`https://api.dangsccg.co.in${article.article_thumbnail_image}`} alt={article.thumbnail_alt_text} />
                </td>
                <td>{article.thumbnail_alt_text}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DeleteArticleCRM;
