// src/components/Reports/Estimate/EstimateGenerator.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axiosInstance from '../../../axiosInstance';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { useNavigate, useLocation } from 'react-router-dom';
import './Estimate.css';

function EstimateGenerator() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedItemsTable, setSelectedItemsTable] = useState([]);
  const [totalRate, setTotalRate] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Mr');
  const [patientName, setPatientName] = useState('');
  const [patientAge, setPatientAge] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [bookingId, setBookingId] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uniqueId = params.get('Unique_id');
    setBookingId(uniqueId);
  }, [location.search]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axiosInstance.get('/api/api/updated-estimaterate/');
        const data = response.data;

        const allOptions = data.map((item) => ({
          label: `${item.itemname}, #${item.itemcode}`,
          value: item.id,
          itemcode: item.itemcode,
          rate: parseFloat(item.Rate) || 0,
          itemType: item.ItemType,
          gender: item.Gender,
        }));

        setOptions(allOptions);
      } catch (error) {
        console.error('Error fetching data from the API:', error);
      }
    };

    fetchOptions();
  }, []);

  const getFilteredOptions = (selectedOption) => {
    return options.filter((option) => {
      if (selectedOption === 'Mr') {
        return option.gender === 'M' || option.gender === 'B';
      } else if (selectedOption === 'Mrs') {
        return option.gender === 'F' || option.gender === 'B';
      }
      return true;
    });
  };

  const calculateTotalRate = (items) => {
    const total = items.reduce((total, selectedItem) => {
      return total + selectedItem.rate;
    }, 0);

    const discountAmount = total * (discountPercentage / 100);
    const final = total - discountAmount;

    setTotalRate(total);
    setFinalAmount(final);
  };

  useEffect(() => {
    calculateTotalRate(selectedItemsTable);
  }, [selectedItemsTable, discountPercentage]);

  const handleDelete = (value) => {
    const updatedItems = selectedItemsTable.filter((item) => item.value !== value);
    setSelectedItemsTable(updatedItems);
  };

  const handleChange = (selectedOption) => {
    const newItems = selectedOption.filter(
      (item) => !selectedItemsTable.some((existingItem) => existingItem.value === item.value)
    );

    const updatedItemsTable = [...selectedItemsTable, ...newItems];
    setSelectedItemsTable(updatedItemsTable);
    setSelectedOptions([]);
    setInputValue('');
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  useEffect(() => {
    setSelectedItemsTable([]);
    setTotalRate(0);
  }, [selectedOption]);

  const generatePDF = async () => {
    try {
      const data = {
        booking_id: bookingId,
        tests: selectedItemsTable.map((item) => item.value),
        total_amount: totalRate,
        discount_percentage: discountPercentage,
        final_amount: finalAmount,
        patient_name: patientName,
        patient_age: patientAge,
        gender: selectedOption,
        contact_no: contactNo,
      };

      const response = await axiosInstance.post('/api/api/estimate/', data);

      if (response.status === 200 || response.status === 201) {
        const existingPdfBytes = await fetch('/letterheadfinal.pdf').then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = pdfDoc.getPages();
        let currentPage = pages[0];
        const { width, height } = currentPage.getSize();
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontSize = 10;
        let yPosition = height - 300;

        currentPage.drawText('ESTIMATE RATE', { x: width / 2 - 50, y: yPosition, size: 14, font: boldFont });
        yPosition -= 30;

        const detailsData = [
          ['NAME', patientName, 'ESTIMATE NO.', Math.floor(Math.random() * 100000).toString()],
          ['AGE/GENDER', `${patientAge}/${selectedOption === 'Mr' ? 'MALE' : 'FEMALE'}`, 'VISIT/REG. DATE', new Date().toLocaleString()],
          ['CONTACT NO.', contactNo, 'CENTER', 'DR DANGS LAB'],
          ['ADDRESS', '', 'CENTER PH. NO.', '9999992020'],
          ['', '', 'CENTER ADDRESS', 'C2/1 SDA']
        ];

        detailsData.forEach((row) => {
          currentPage.drawText(row[0], { x: 60, y: yPosition, size: fontSize, font: boldFont });
          currentPage.drawText(row[1], { x: 150, y: yPosition, size: fontSize, font: font });
          currentPage.drawText(row[2], { x: 300, y: yPosition, size: fontSize, font: boldFont });
          currentPage.drawText(row[3], { x: 400, y: yPosition, size: fontSize, font: font });
          yPosition -= 20;
        });

        yPosition -= 20;

        const headers = ['#', 'SERVICE CODE', 'SERVICE NAME', 'MRP', 'DISCOUNT', 'TOTAL'];
        headers.forEach((header, index) => {
          currentPage.drawText(header, { x: 60 + index * 80, y: yPosition, size: fontSize, font: boldFont });
        });

        currentPage.drawLine({
          start: { x: 60, y: yPosition + 5 },
          end: { x: 540, y: yPosition + 5 },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        yPosition -= 15;

        selectedItemsTable.forEach((item, index) => {
          currentPage.drawText((index + 1).toString(), { x: 60, y: yPosition, size: fontSize, font: font });
          currentPage.drawText(item.itemcode, { x: 100, y: yPosition, size: fontSize, font: font });
          currentPage.drawText(item.label.split(', ')[0], { x: 180, y: yPosition, size: fontSize, font: font });
          currentPage.drawText(item.rate.toFixed(2), { x: 320, y: yPosition, size: fontSize, font: font });
          currentPage.drawText(`${discountPercentage}%`, { x: 400, y: yPosition, size: fontSize, font: font });
          const itemFinalAmount = item.rate - (item.rate * discountPercentage / 100);
          currentPage.drawText(itemFinalAmount.toFixed(2), { x: 480, y: yPosition, size: fontSize, font: font });

          currentPage.drawLine({
            start: { x: 60, y: yPosition - 2 },
            end: { x: 540, y: yPosition - 2 },
            thickness: 0.5,
            color: rgb(0, 0, 0),
          });

          yPosition -= 20;

          if (yPosition < 50) {
            currentPage = pdfDoc.addPage([width, height]);
            yPosition = height - 50;
          }
        });

        yPosition -= 30;
        currentPage.drawText(`BILL AMOUNT : ${totalRate.toFixed(2)}`, { x: 350, y: yPosition, size: fontSize, font: boldFont });
        yPosition -= 15;
        currentPage.drawText(`TOTAL DISCOUNT : ${(totalRate * discountPercentage / 100).toFixed(2)}`, { x: 350, y: yPosition, size: fontSize, font: boldFont });
        yPosition -= 15;
        currentPage.drawText(`NET BILL AMOUNT : ${finalAmount.toFixed(2)}`, { x: 350, y: yPosition, size: fontSize, font: boldFont });

        yPosition -= 40;
        currentPage.drawText('AUTHORIZED SIGNATURE :', { x: 350, y: yPosition, size: fontSize, font: boldFont });
        currentPage.drawLine({
          start: { x: 350, y: yPosition - 20 },
          end: { x: 550, y: yPosition - 20 },
          thickness: 1,
          color: rgb(0, 0, 0),
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'EstimateReceipt.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(url);

        alert('Estimate saved and PDF generated successfully.');
        navigate(-1);
      } else {
        alert('Error saving estimate. Please try again.');
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF or saving estimate. Please try again.');
    }
  };

  return (
    <div className="EstimatePageMAiN">
      <div className="EstimatePageMainHeading">
        <h2>Estimate Page</h2>
      </div>
      <div className="EstimateMianDiv">
        <div className="EstimatePageMainFilters">
          <input
            type="text"
            placeholder="Patient Name"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
          />
          <input
            type="number"
            placeholder="Age"
            value={patientAge}
            onChange={(e) => setPatientAge(e.target.value)}
          />
          <Select
            value={{ label: selectedOption, value: selectedOption }}
            onChange={(option) => setSelectedOption(option.value)}
            options={[
              { label: 'Mr', value: 'Mr' },
              { label: 'Mrs', value: 'Mrs' },
            ]}
          />
          <input
            type="tel"
            placeholder="Contact No."
            value={contactNo}
            onChange={(e) => setContactNo(e.target.value)}
          />
          <label>
            Discount (%):
            <input
              type="number"
              min="0"
              max="100"
              value={discountPercentage}
              onChange={(e) => setDiscountPercentage(parseFloat(e.target.value) || 0)}
            />
          </label>
          <Select
            isMulti
            options={getFilteredOptions(selectedOption)}
            value={selectedOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            placeholder="Search by itemcode or itemname..."
            isSearchable
            hideSelectedOptions={true}
            menuIsOpen={inputValue.length >= 2}
            id="EsitmateDropdownId"
          />
        </div>
        <div className="EstimateTableDIv">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>ItemCode</th>
                <th>ItemName</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {selectedItemsTable.map((selectedItem) => (
                <tr key={selectedItem.value}>
                  <td>
                    <button onClick={() => handleDelete(selectedItem.value)}>X</button>
                  </td>
                  <td>{selectedItem.itemcode}</td>
                  <td>{selectedItem.label.split(', ')[0]}</td>
                  <td>{selectedItem.rate.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="EstimateTotal">
                <td colSpan="3">Total:</td>
                <td>{totalRate.toFixed(2)}</td>
              </tr>
              <tr className="EstimateTotal">
                <td colSpan="3">Discount ({discountPercentage}%):</td>
                <td>{(totalRate * discountPercentage / 100).toFixed(2)}</td>
              </tr>
              <tr className="EstimateTotal">
                <td colSpan="3">Final Amount:</td>
                <td>{finalAmount.toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <button onClick={generatePDF}>Generate PDF</button>
      </div>
    </div>
  );
}

export default EstimateGenerator;