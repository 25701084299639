import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Use axiosInstance instead of axios
import './DeleteBlogCRM.css';

const DeleteBlogCRM = () => {
  const [blogs, setBlogs] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axiosInstance.get('/api/get_blogs/');

        if (response.status === 200) {
          const sortedBlogs = response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
          setBlogs(sortedBlogs);
        } else {
          console.error('Error fetching blogs. Status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  const handleDelete = async (blogId) => {
    const confirmed = window.confirm('Are you sure you want to delete this blog?');
    if (confirmed) {
      try {
        const response = await axiosInstance.delete(`/api/delete_blog/${blogId}/`);
        if (response.status === 204 || response.status === 200) {
          alert('Blog deleted successfully.');
          window.location.reload(); // Reload the page after successful deletion
        } else {
          alert('Failed to delete blog.');
        }
      } catch (error) {
        console.error('Error deleting blog:', error);
        alert('Failed to delete blog.');
      }
    }
  };

  const filteredBlogs = blogs.filter((blog) => {
    return blog.blog_heading.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
       <div className="CRMBlog-container">
      <div className="DeleteBlogTopHeadingAndBackButton">
        <h2>Blog Details</h2>
        <Link to="/News/Blogs">
          <button>Back To Menu</button>
        </Link>
      </div>
      <div className="DeleteBlogFilter">
        <div className="FilterMain">
          <input
            type="text"
            placeholder="Search Headlines"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="DeleteBlogTable">
          <thead>
            <tr>
              <th>Action</th>
              <th>Blog Heading</th>
              <th>Released on</th>
              <th>Content</th>
              <th>Thumbnail Image</th>
              <th>Alt Text</th>
            </tr>
          </thead>
          <tbody>
            {filteredBlogs.map((blog) => (
              <tr key={blog.id}>
                <td>
                  <button onClick={() => handleDelete(blog.id)}>Delete</button>
                </td>
                <td>{blog.blog_heading}</td>
                <td>{blog.release_date}</td>
                <td>{blog.blogs_content}</td>
                <td>
                  <img src={`https://api.dangsccg.co.in${blog.blog_thumbnail_image}`} alt={blog.thumbnail_alt_text} />
                </td>
                <td>{blog.thumbnail_alt_text}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </>
  );
};

export default DeleteBlogCRM;
