import React from 'react';
import { Link } from 'react-router-dom';
import './BlogsCRM.css';

function BlogsCRM() {
  return (
    <div className="blogs-crm-container">
      <div className="blogs-crm-header">
        <h1>Blogs Master</h1>
      </div>
      <div className="blogs-crm-actions">
        <div className="blogs-crm-action-button">
          <Link to="/News/AddBlogCRM">
            <button className="blogs-crm-btn">Add Blog</button>
          </Link>
        </div>
        <div className="blogs-crm-action-button">
          <Link to="/News/UpdateBlogCRM">
            <button className="blogs-crm-btn">Update Blog</button>
          </Link>
        </div>
        <div className="blogs-crm-action-button">
          <Link to="/News/DeleteBlogCRM">
            <button className="blogs-crm-btn delete-btn">Delete Blog</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogsCRM;
