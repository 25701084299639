import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import the axios instance with token handling
import { Link, useNavigate } from 'react-router-dom';
import './UpdateHolidays.css';

const UpdateHolidays = () => {
    const [holidays, setHolidays] = useState([]);
    const [filteredHolidays, setFilteredHolidays] = useState([]);
    const [selectedHoliday, setSelectedHoliday] = useState(null);
    const [holidayName, setHolidayName] = useState('');
    const [holidayDate, setHolidayDate] = useState('');
    const [isShow, setIsShow] = useState(true);
    const [isBookUpto, setIsBookUpto] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHolidays = async () => {
            try {
                const response = await axiosInstance.get('/api/holidays/');
                setHolidays(response.data);
                setFilteredHolidays(response.data);  // Initially, display all holidays
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, []);

    const handleSelectHoliday = (holiday) => {
        setSelectedHoliday(holiday);
        setHolidayName(holiday.name);
        setHolidayDate(holiday.date);
        setIsShow(holiday.is_show);
        setIsBookUpto(holiday.is_book_upto);
    };

    const handleUpdateHoliday = async (event) => {
        event.preventDefault();

        const updatedHoliday = {
            name: holidayName,
            date: holidayDate,
            is_show: isShow,
            is_book_upto: isBookUpto
        };

        try {
            await axiosInstance.put(`/api/holidays/${selectedHoliday.id}/`, updatedHoliday);
            alert('Holiday Updated Successfully!');
            navigate('/master/Holidays');  // Redirect to the /master/Holidays page after update
        } catch (error) {
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message);
            } else {
                console.log(error);
                alert('Failed to update holiday');
            }
        }
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = holidays.filter(holiday => holiday.name.toLowerCase().includes(value));
        setFilteredHolidays(filtered);
    };

    return (
        <div className="update-holidays">
            {selectedHoliday ? (
                <div className="add-holiday-form">
                    <h2>Update Holiday</h2>
                    <form onSubmit={handleUpdateHoliday}>
                        <label>
                            Holiday Name:
                            <input type="text" value={holidayName} onChange={(e) => setHolidayName(e.target.value)} />
                        </label>
                        <label>
                            Holiday Date:
                            <input type="date" value={holidayDate} onChange={(e) => setHolidayDate(e.target.value)} />
                        </label>
                        <label>
                            Is Show:
                            <select value={isShow ? 'Yes' : 'No'} onChange={(e) => setIsShow(e.target.value === 'Yes')}>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </label>
                        <label>
                            Is BookUpto:
                            <select value={isBookUpto ? 'Yes' : 'No'} onChange={(e) => setIsBookUpto(e.target.value === 'Yes')}>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </label>
                        <button type="submit">Update</button>
                    </form>
                    <Link to="/master/Holidays">Back to Menu</Link>
                </div>
            ) : (
                <div className="holiday-list">
                    <h2>Holidays Master</h2>
                    <Link to="/master/Holidays">
                        <button>Back To Menu</button>
                    </Link>
                    <div className="search-holidays">
                        <h3>Search Holidays</h3>
                        <input 
                            type="text" 
                            placeholder="Enter Holiday Name" 
                            value={searchTerm} 
                            onChange={handleSearchChange} 
                        />
                        <button onClick={handleSearchChange}>Search</button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Holiday Name</th>
                                <th>Holiday Date</th>
                                <th>Is Show</th>
                                <th>Booking Upto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredHolidays.map((holiday) => (
                                <tr key={holiday.id}>
                                    <td><button onClick={() => handleSelectHoliday(holiday)}>Select</button></td>
                                    <td>{holiday.name}</td>
                                    <td>{holiday.date}</td>
                                    <td>{holiday.is_show ? 'True' : 'False'}</td>
                                    <td>{holiday.is_book_upto ? 'True' : 'False'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default UpdateHolidays;
