import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Use your custom axios instance
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Feedback.css';
import LogoWithBorder from '../../../IconsANdImages/Logowithborder.png';


const Feedback = () => {
    const today = new Date();
    const [feedbacks, setFeedbacks] = useState([]);
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [patientName, setPatientName] = useState('');
    const [patientEmail, setPatientEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [location, setLocation] = useState('');
    const [mailStatus, setMailStatus] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state

    const fetchFeedbacks = async () => {
        setLoading(true); // Start loading
        setError(null); // Reset error state

        const formattedFromDate = fromDate ? fromDate.toISOString().split('T')[0] : '';
        const formattedToDate = toDate ? toDate.toISOString().split('T')[0] : '';

        try {
            const response = await axiosInstance.get('/api/api/filtered-feedback/', {
                params: {
                    from_date: formattedFromDate,
                    to_date: formattedToDate,
                    patient_name: patientName,
                    patient_email: patientEmail,
                    mobile_number: mobileNumber,
                    location: location,
                    mail_status: mailStatus,
                },
            });
            setFeedbacks(response.data);
        } catch (error) {
            setError('Error fetching feedbacks. Please try again later.');
            console.error('Error fetching feedbacks:', error);
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleDateChange = (dateSetter) => (date) => {
        const adjustedDate = new Date(date);
        adjustedDate.setHours(0, 0, 0, 0);
        dateSetter(adjustedDate);
    };

    const handleSearch = () => {
        fetchFeedbacks();
    };


    const handlePrint = (feedback) => {
        const printWindow = window.open('', '_blank');
    

    
        printWindow.document.write(`
            <!DOCTYPE html>
            <html>
                <head>
                    <title>Feedback Details</title>
                    <style>
                        @media print {
                            body {
                                -webkit-print-color-adjust: exact;
                                print-color-adjust: exact;
                            }
                            .header {
                                border: 1px solid black !important;
                                box-sizing: border-box;
                                padding: 10px 25px 2px 20px;
                            }
                        }
    
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            background-color: #ffffff;
                        }
                        .container {                      
                            padding: 20px;
                            max-width: 1000px;
                            margin: auto;
                            background-color: #fff;
                        }
                        .header {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            margin-bottom: 0px;
                            border: 2px solid black !important;
                            box-sizing: border-box;
                            padding: 10px 25px 2px 20px;
                        }
                        .logo img {
                            width: 200px;
                            height: auto;
                            margin-top: 0.5rem;
                        }
                        .contact-info {
                            text-align: right;
                            font-size: 15px;
                            line-height: 1.8;
                        }
                        .title-bar {
                            background-color: white;
                            color: #000;
                            text-align: center;
                            padding: 12px;
                            font-weight: bold;
                            margin-bottom: 0px;
                            border: 2px solid #7b7b7b;
                            font-size: 18px;
                        }
                        .feedback-detail-table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-top: 0px;
                        }
                        .feedback-detail-table th,
                        .feedback-detail-table td {
                            border: 2px solid #000;
                            padding: 12px;
                            text-align: left;
                            vertical-align: top;
                            font-size: 19px;
                        }
                        .feedback-detail-table th {
                            background-color: white;
                            font-weight: bold;
                            width: 40%;
                        }
                        .feedback-detail-table td {
                            background-color: #ffffff;
                        }
                        .star-rating {
                            color: #ffd700; /* Gold color for stars */
                            font-size: 26px!important;
                        }
                        .comment-section {
                            white-space: pre-wrap; /* Preserves formatting for multiline comments */
                            line-height: 1.5;
                            margin-top: 10px;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class="header">
                            <div class="logo">
                                <img src="${LogoWithBorder}" alt="Dr Dangs Lab Logo">
                            </div>
                            <div class="contact-info">
                                <p>C2/1, S.D.A<br>
                                Aurobindo Marg<br>
                                New Delhi-110016<br>
                                +91-011-45004200, +91-9999992020</p>
                            </div>
                        </div>
                        <div class="title-bar">FEEDBACK DETAIL</div>
                        <table class="feedback-detail-table">
                            <tr><th>Name</th><td>${feedback.name}</td></tr>
                            <tr><th>Email</th><td>${feedback.email}</td></tr>
                            <tr><th>Mobile No.</th><td>${feedback.contact_number}</td></tr>
                            <tr><th>Branch Name</th><td>${feedback.location}</td></tr>
                            <tr><th>Feedback Date</th><td>${new Date(feedback.created_at).toLocaleDateString('en-GB')}</td></tr>
                            <tr><th>Feedback Time</th><td>${new Date(feedback.created_at).toLocaleTimeString('en-GB')}</td></tr>
                            <tr><th>Testing Date</th><td>${feedback.testing_date}</td></tr>
                            <tr><th>You are coming to our laboratory since (in years)*</th><td>${feedback.coming_since_years}</td></tr>
                            <tr><th>Timely delivery of reports*</th><td class="star-rating">${'★'.repeat(feedback.timely_delivery)}</td></tr>
                            <tr><th>Quality of report*</th><td class="star-rating">${'★'.repeat(feedback.quality_report)}</td></tr>
                            <tr><th>Satisfaction level of our services*</th><td class="star-rating">${'★'.repeat(feedback.satisfaction_level)}</td></tr>
                            <tr><th>Sample Collection Service*</th><td class="star-rating">${'★'.repeat(feedback.sample_collection)}</td></tr>
                            <tr><th>Comment</th><td class="comment-section">${feedback.comments}</td></tr>
                        </table>
                    </div>
                    <script>
                        window.onload = function() {
                            // Ensure the content is fully loaded before printing
                            window.print();
                        }
                    </script>
                </body>
            </html>
        `);
    
        printWindow.document.close();
    };
    
    
    

    return (
        <div className="feedback-enquiry">
            <h2>Feedback Report</h2>
            <div className="feedback-search-filters">
                <div className="feedback-search-field">
                    <label>From:</label>
                    <DatePicker 
                        selected={fromDate} 
                        onChange={handleDateChange(setFromDate)} 
                        dateFormat="dd-MM-yyyy" 
                    />
                </div>
                <div className="feedback-search-field">
                    <label>To:</label>
                    <DatePicker 
                        selected={toDate} 
                        onChange={handleDateChange(setToDate)} 
                        dateFormat="dd-MM-yyyy" 
                    />
                </div>
                <div className="feedback-search-field">
                    <label>Patient Name:</label>
                    <input 
                        type="text" 
                        value={patientName} 
                        onChange={(e) => setPatientName(e.target.value)} 
                    />
                </div>
                <div className="feedback-search-field">
                    <label>Patient Email:</label>
                    <input 
                        type="text" 
                        value={patientEmail} 
                        onChange={(e) => setPatientEmail(e.target.value)} 
                    />
                </div>
            </div>
            <div className="feedback-search-filters">
                <div className="feedback-search-field">
                    <label>Mobile No.:</label>
                    <input 
                        type="text" 
                        value={mobileNumber} 
                        onChange={(e) => setMobileNumber(e.target.value)} 
                    />
                </div>
                <div className="feedback-search-field">
                    <label>Locations:</label>
                    <select value={location} onChange={(e) => setLocation(e.target.value)}>
                        <option value="">-- Select --</option>
                        <option value="location1">Location 1</option>
                        <option value="location2">Location 2</option>
                    </select>
                </div>
                <div className="feedback-search-field">
                    <label>Mail Status:</label>
                    <select value={mailStatus} onChange={(e) => setMailStatus(e.target.value)}>
                        <option value="">--Select--</option>
                        <option value="sent_to_applicant">Mail Sent To Applicant</option>
                        <option value="sent_to_ddl">Mail Sent To DDL</option>
                    </select>
                </div>
           </div>
            <div className="feedback-button-container">
                <button className="feedback-button" onClick={handleSearch}>Generate</button>
                <button className="feedback-button">Export To Excel</button>
            </div>
            <div className="feedback-table-container">
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div className="error-message">{error}</div>
                ) : feedbacks.length > 0 ? (
                    <table className="feedback-table">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Action</th>
                                <th>Patient Detail</th>
                                <th>Date Time</th>
                                <th>Collection Detail</th>
                                <th>Coming to lab since (years)</th>
                                <th>Timely Delivery of Reports</th>
                                <th>Quality of Reports</th>
                                <th>Satisfaction Level</th>
                                <th>Sample Collection</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feedbacks.map((feedback, index) => (
                                <tr key={feedback.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <button className="feedback-manage-button">Manage</button>
                                        <button className="feedback-print-button" onClick={() => handlePrint(feedback)}>Print</button>
                                    </td>
                                    <td>
                                        {feedback.name}
                                        <br />
                                        <a href={`tel:${feedback.contact_number}`}>{feedback.contact_number}</a>
                                        <br />
                                        <a href={`mailto:${feedback.email}`}>{feedback.email}</a>
                                    </td>
                                    <td>
                                        {new Date(feedback.created_at).toLocaleDateString('en-GB')}
                                        <br />
                                        {new Date(feedback.created_at).toLocaleTimeString('en-GB')}
                                    </td>
                                    <td>{feedback.testing_date},<br/>{feedback.location}</td>
                                    <td>{feedback.coming_since_years}</td>
                                    <td>{feedback.timely_delivery}</td>
                                    <td>{feedback.quality_report}</td>
                                    <td>{feedback.satisfaction_level}</td>
                                    <td>{feedback.sample_collection}</td>
                                    <td>{feedback.comments}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>No feedback found</div>
                )}
            </div>
        </div>
    );
};

export default Feedback;
