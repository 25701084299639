import React from 'react';
import { Link } from 'react-router-dom';
import './News.css';

function News() {
  return (
    <div className="news-container">
      <div className="news-heading">
        <h1>News Management</h1>
      </div>

      <div className="news-actions">
        <div className="news-action-button">
          <Link to="/News/Add-News">
            <button className="news-btn">Add News</button>
          </Link>
        </div>
        <div className="news-action-button">
          <Link to="/News/Update-News">
            <button className="news-btn">Update News</button>
          </Link>
        </div>
        <div className="news-action-button">
          <Link to="/News/Deletes-News">
            <button className="news-btn delete-btn">Delete News</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default News;
