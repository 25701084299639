import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';
import './ManageHomeCollection.css';

const ManageHomeCollection = () => {
  // State declarations
  const [uniqueId, setUniqueId] = useState('');
  const [bookingData, setBookingData] = useState(null);
  const [estimateData, setEstimateData] = useState(null);
  const [showEstimate, setShowEstimate] = useState(false);
  const [lateTime, setLateTime] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [collectionAmount, setCollectionAmount] = useState(0);
  const [assignPhlebo, setAssignPhlebo] = useState('');
  const [isCollected, setIsCollected] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // Initial data loading
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const uniqueIdFromURL = urlSearchParams.get('Unique_id');
    if (uniqueIdFromURL) {
      setUniqueId(uniqueIdFromURL);
      fetchDataForUniqueID(uniqueIdFromURL);
      fetchEstimateData(uniqueIdFromURL);
    }
  }, []);

  // Fetch current user data
  useEffect(() => {
    axiosInstance.get('/api/current-user/')
      .then(response => {
        console.log('Current user data:', response.data);
        setCurrentUser(response.data);
      })
      .catch(error => {
        console.error('Error fetching current user data:', error);
      });
  }, []);

  // Fetch booking data
  const fetchDataForUniqueID = async (uniqueId) => {
    try {
      const response = await axiosInstance.get(`/api/api/bookings/update/${uniqueId}/`);
      const data = response.data;
      setBookingData(data);
      setPaymentMode(data.payment_mode || '');
      setCollectionAmount(data.collection_amount || 0);
      setAssignPhlebo(data.Assign_phelobo || '');
      setIsCollected(data.is_Collected || false);
    } catch (error) {
      console.error('Error fetching booking data:', error);
    }
  };

  // Fetch estimate data
  const fetchEstimateData = async (uniqueId) => {
    try {
      const response = await axiosInstance.get(`/api/api/estimate/${uniqueId}/`);
      setEstimateData(response.data);
    } catch (error) {
      console.error('Error fetching estimate data:', error);
    }
  };

  // Prescription handling functions
  const openPrescriptionPopup = () => {
    if (!bookingData || !bookingData.prescription) return;

    const backendUrl = axiosInstance.defaults.baseURL;
    const prescriptionUrl = `${backendUrl}${bookingData.prescription}`;
    const fileExtension = prescriptionUrl.split('.').pop().toLowerCase();
    const width = 800;
    const height = 600;
    const options = `width=${width},height=${height},top=100,left=100,scrollbars=yes`;

    if (fileExtension === 'pdf') {
      window.open(prescriptionUrl, '_blank', options);
    } else {
      const imgWindow = window.open('', '_blank', options);
      imgWindow.document.write(`
        <html>
          <head>
            <title>View Prescription</title>
          </head>
          <body style="margin: 0; padding: 0;">
            <img src="${prescriptionUrl}" style="width: 100%; height: auto;" />
          </body>
        </html>
      `);
    }
  };

  const printPrescription = () => {
    if (!bookingData || !bookingData.prescription) return;

    const backendUrl = axiosInstance.defaults.baseURL;
    const prescriptionUrl = `${backendUrl}${bookingData.prescription}`;
    const fileExtension = prescriptionUrl.split('.').pop().toLowerCase();

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (fileExtension === 'pdf') {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Prescription</title>
          </head>
          <body style="margin: 0; padding: 0;">
            <embed src="${prescriptionUrl}" type="application/pdf" style="width:100%; height:100%;" />
          </body>
        </html>
      `);
    } else {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Prescription</title>
          </head>
          <body style="margin: 0; padding: 0;">
            <img src="${prescriptionUrl}" style="width: 100%; height: auto;" onload="window.print(); window.close();" />
          </body>
        </html>
      `);
    }
  };

  // Email functions
  const sendEmailToPatientManageBooking = async () => {
    try {
      const emailData = {
        patient_name: bookingData.patient_name,
        booking_id: bookingData.Unique_id,
        scheduled_date: bookingData.date_for_collection,
        scheduled_time: bookingData.preferred_time,
        patient_email: bookingData.patient_email,
      };

      const emailResponse = await axiosInstance.post('/api/send-email-to-patient-manage-booking/', emailData);

      if (emailResponse.status === 200) {
        alert('Email sent to patient successfully!');
      } else {
        alert('Failed to send email to the patient. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };

  const sendEmailToDDLManageBooking = async () => {
    try {
      const emailData = {
        patient_name: bookingData.patient_name,
        booking_id: bookingData.Unique_id,
        scheduled_date: bookingData.date_for_collection,
        scheduled_time: bookingData.preferred_time,
        patient_email: bookingData.patient_email,
        patient_mobile_number: bookingData.mobile_number,
        patient_selected_patient: bookingData.test_package_name,
        patient_whatsapp_number: bookingData.whatsapp_number,
        patient_age: bookingData.age,
        patient_gender: bookingData.gender,
        patient_city: bookingData.city,
        patient_address: bookingData.address,
        patient_pincode: bookingData.pincode,
        patient_state: bookingData.state,
        patient_remark: bookingData.remark,
      };

      const emailResponse = await axiosInstance.post('/api/send-email-to-DDL-manage-booking/', emailData);

      if (emailResponse.status === 200) {
        alert('Email sent to DDL successfully!');
      } else {
        alert('Failed to send email to DDL. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };

  // SMS functions
  const UnableToContactSMS = async () => {
    try {
      const smsData = {
        Assigned_Phlebo_mobile_number: bookingData.phlebotomist_phone,
        Patient_mobile_number: bookingData.mobile_number,
        uniqueId: bookingData.Unique_id,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
      };

      const smsResponse = await axiosInstance.post('/api/Unable-to-Contact-send-sms-to-patient/', smsData);

      if (smsResponse.status === 200) {
        alert('SMS sent to Patient successfully!');
        const updatedSmsCount = bookingData.sms_sent_to_patient + 1;
        await axiosInstance.put(`/api/api/bookings/update/${uniqueId}/`, {
          sms_sent_to_patient: updatedSmsCount,
        });
      } else {
        alert('Failed to send SMS to Patient. Please try again.');
      }
    } catch (error) {
      console.error('Error sending SMS:', error);
      alert('Failed to send SMS to Patient. Please try again.');
    }
  };

  const IAMatYourDoorSms = async () => {
    try {
      const smsData = {
        Assigned_Phlebo_mobile_number: bookingData.phlebotomist_phone,
        Patient_mobile_number: bookingData.mobile_number,
        uniqueId: bookingData.Unique_id,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
      };

      const smsResponse = await axiosInstance.post('/api/send-sms-i-am-at-doorstep/', smsData);

      if (smsResponse.status === 200) {
        alert('SMS sent to Patient successfully!');
        const updatedSmsCount = bookingData.sms_sent_to_patient + 1;
        await axiosInstance.put(`/api/api/bookings/update/${uniqueId}/`, {
          sms_sent_to_patient: updatedSmsCount,
        });
      } else {
        alert('Failed to send SMS to Patient. Please try again.');
      }
    } catch (error) {
      console.error('Error sending SMS:', error);
      alert('Failed to send SMS to Patient. Please try again.');
    }
  };

  // Event handlers
  const handleLateTimeChange = (event) => {
    setLateTime(event.target.value);
  };

  const handleSendLateSMS = async () => {
    if (!lateTime) {
      alert('Please select a time delay.');
      return;
    }
    try {
      const smsData = {
        Assigned_Phlebo_mobile_number: bookingData.phlebotomist_phone,
        Patient_mobile_number: bookingData.mobile_number,
        uniqueId: bookingData.Unique_id,
        assigned_Phlebo_name: bookingData.Assign_phelobo,
        lateTime,
      };

      const smsResponse = await axiosInstance.post('/api/send-little-late-sms/', smsData);

      if (smsResponse.status === 200) {
        alert('Late SMS sent successfully!');
      } else {
        alert('Failed to send Late SMS. Please try again.');
      }
    } catch (error) {
      console.error('Error sending Late SMS:', error);
      alert('Failed to send Late SMS. Please try again.');
    }
  };

  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  const handleCollectionAmountChange = (event) => {
    setCollectionAmount(event.target.value);
  };

  const handleMarkCollected = async () => {
    try {
      const currentDate = new Date();
      const collectionDate = currentDate.toISOString().split('T')[0];
      const collectionTime = currentDate.toTimeString().split(' ')[0];

      const updatedData = {
        is_Collected: true,
        payment_mode: paymentMode,
        collection_amount: collectionAmount,
        Sample_collection_date: collectionDate,
        Sample_collection_time: collectionTime,
      };

      await axiosInstance.put(`/api/api/bookings/update/${uniqueId}/`, updatedData);
      alert('Marked as collected successfully!');
      fetchDataForUniqueID(uniqueId); // Refresh data
    } catch (error) {
      console.error('Error marking as collected:', error);
      alert('Failed to mark as collected. Please try again.');
    }
  };

  const handleClearCollected = async () => {
    try {
      const updatedData = {
        is_Collected: false,
        payment_mode: '',
        collection_amount: 0,
        Sample_collection_date: null,
        Sample_collection_time: null
      };

      await axiosInstance.put(`/api/api/bookings/update/${uniqueId}/`, updatedData);
      alert('Cleared collected status successfully!');
      fetchDataForUniqueID(uniqueId); // Refresh data
    } catch (error) {
      console.error('Error clearing collected status:', error);
      alert('Failed to clear collected status. Please try again.');
    }
  };

  return (
    <div className="manage-home-collection">
      <h2>Manage Home Collection Service</h2>

      {bookingData ? (
        <div className="patient-info">
          <table>
            <tbody>
              <tr>
                <td><strong>Patient Name:</strong></td>
                <td><strong>{bookingData.patient_name}, ID #[{bookingData.Unique_id}]</strong></td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{bookingData.gender}</td>
              </tr>
              <tr>
                <td>Mobile No.</td>
                <td>{bookingData.mobile_number}</td>
              </tr>
              <tr>
                <td>Whatsapp No.</td>
                <td>{bookingData.whatsapp_number}</td>
              </tr>
              <tr>
                <td>Test Name</td>
                <td>{bookingData.test_package_name}</td>
              </tr>
              <tr>
                <td>Collection Date</td>
                <td>{bookingData.date_for_collection}</td>
              </tr>
              <tr>
                <td>Collection Time</td>
                <td>{bookingData.preferred_time}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{bookingData.address}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{bookingData.city}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{bookingData.state}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>India</td>
              </tr>
              <tr>
                <td>Pincode</td>
                <td>{bookingData.pincode}</td>
              </tr>
              {/* Prescription row */}
              {bookingData.prescription && bookingData.prescription !== '/media/prescriptions/' && (
                <tr>
                  <td>Prescription</td>
                  <td className="prescription-actions">
                    <button 
                      onClick={openPrescriptionPopup}
                      className="action-button view-prescription-button"
                    >
                      View Prescription
                    </button>
                    {/* <button 
                      onClick={printPrescription}
                      className="action-button print-prescription-button"
                    >
                      Print Prescription
                    </button> */}
                  </td>
                </tr>
              )}
        {/* Continuing from previous code */}
              {/* Estimate row */}
              <tr>
                <td>Estimate</td>
                <td>
                  <button 
                    onClick={() => setShowEstimate(!showEstimate)}
                    className="action-button view-estimate-button"
                  >
                    {showEstimate ? 'Hide Estimate' : 'View Estimate'}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          {/* Estimate section */}
          {showEstimate && estimateData && (
            <div className="estimate-section">
              <h4>Estimate Details:</h4>
              <table className="estimate-table">
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {estimateData.tests?.map(test => (
                    <tr key={test.itemcode}>
                      <td>{test.itemcode}</td>
                      <td>{test.itemname}</td>
                      <td>₹{test.Rate}</td>
                    </tr>
                  ))}
                  <tr className="total-row">
                    <td colSpan="2"><strong>Total Amount</strong></td>
                    <td>₹{estimateData.total_amount}</td>
                  </tr>
                  <tr className="discount-row">
                    <td colSpan="2"><strong>Discount Percentage</strong></td>
                    <td>{estimateData.discount_percentage}%</td>
                  </tr>
                  <tr className="final-row">
                    <td colSpan="2"><strong>Final Amount</strong></td>
                    <td>₹{estimateData.final_amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* Special Instructions section */}
          {bookingData.special_instructions && (
            <div className="special-instructions">
              <h4>Special Instructions:</h4>
              <table className="instructions-table">
                <thead>
                  <tr>
                    <th>SNo.</th>
                    <th>Special Instructions</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(bookingData.special_instructions).map((instruction, index) => (
                    <tr key={instruction.id}>
                      <td>{index + 1}</td>
                      <td>{instruction.special_instructions}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Late SMS section */}
          <div className="patient-info-actions">
            <label>
              Select Time:
              <select value={lateTime} onChange={handleLateTimeChange}>
                <option value="">-- Select Time --</option>
                <option value="5">5 minutes</option>
                <option value="10">10 minutes</option>
                <option value="15">15 minutes</option>
                <option value="20">20 minutes</option>
                <option value="25">25 minutes</option>
                <option value="30">30 minutes</option>
              </select>
            </label>
            <button onClick={handleSendLateSMS} className="send-late-sms-button">Send Late SMS</button>
          </div>

          {/* Payment section */}
          <div className="payment-section">
            <label>Payment Method:</label>
            <select value={paymentMode} onChange={handlePaymentModeChange}>
              <option value="">-- Select --</option>
              <option value="Cash">Cash</option>
              <option value="Cheque">Cheque</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Debit Card">Debit Card</option>
              <option value="Google Pay">Google Pay</option>
              <option value="Online Payment">Online Payment</option>
              <option value="Paytm">Paytm</option>
              <option value="PhonePe">PhonePe</option>
              <option value="UPI">UPI</option>
            </select>

            <label>Collection Amount:</label>
            <input
              type="number"
              value={collectionAmount}
              onChange={handleCollectionAmountChange}
              className="collection-amount-input"
            />
          </div>

          {/* Collection actions */}
          <div className="collected-actions">
            <button className="mARKaSCOLLEVTED" onClick={handleMarkCollected}>
              Mark Collected
            </button>
            <button className="CLEARcOLLECTED" onClick={handleClearCollected}>
              Clear Collected
            </button>
          </div>
        </div>
      ) : null}

      {/* Action buttons */}
      {currentUser && currentUser.user_type === 'phlebotomist' ? (
        <>
          <button className="submit-button" onClick={UnableToContactSMS}>
            Unable To Contact
          </button>
          <button className="submit-button" onClick={IAMatYourDoorSms}>
            I am at Your Door
          </button>
        </>
      ) : (
        <>
          <button className="submit-button" onClick={sendEmailToPatientManageBooking}>
            Email To Patient
          </button>
          <button className="submit-button" onClick={sendEmailToDDLManageBooking}>
            Email To DDL
          </button>
          <button className="submit-button" onClick={UnableToContactSMS}>
            Unable To Contact
          </button>
          <button className="submit-button" onClick={IAMatYourDoorSms}>
            I am at Your Door
          </button>
        </>
      )}
    </div>
  );
};

export default ManageHomeCollection;