import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import './DeleteUser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function DeleteUser() {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/api/api/users/');
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users.');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectUser = (e, userID) => {
        if (e.target.checked) {
            setSelectedUsers([...selectedUsers, userID]);
        } else {
            setSelectedUsers(selectedUsers.filter(id => id !== userID));
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            for (const userID of selectedUsers) {
                await axiosInstance.delete(`/api/api/delete_user/${userID}/`);
            }
            setSuccess('Selected users deleted successfully');
            setError(null);
            fetchUsers(); // Refresh the list after deletion
            setSelectedUsers([]); // Clear the selected users
        } catch (error) {
            console.error('Error deleting users:', error);
            setError('An error occurred while deleting the users. Please try again.');
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="delete-user-container">
            <div className="delete-user-header">
                <h2>Delete Users</h2>
                <Link to="/User">
                    <button className="back-button">Back To Menu</button>
                </Link>
            </div>
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
            <div className="user-table">
                <table>
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>User ID</th>
                            <th>Username</th>
                            <th>Mobile No</th>
                            <th>Email</th>
                            <th>User Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.userID}>
                                <td>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleSelectUser(e, user.userID)}
                                        checked={selectedUsers.includes(user.userID)}
                                    />
                                </td>
                                <td>{user.userID}</td>
                                <td>{user.username}</td>
                                <td>{user.mobile_no}</td>
                                <td>{user.email}</td>
                                <td>{user.user_type}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button 
                onClick={handleDelete} 
                disabled={selectedUsers.length === 0 || loading}
                className="delete-button"
            >
                {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                    'Delete Selected Users'
                )}
            </button>
        </div>
    );
}

export default DeleteUser;