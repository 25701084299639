// src/components/Reports/Bookings/Bookings.js

import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../axiosInstance';
import './Bookings.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import SMSICONToPatient from '.././../../IconsANdImages/SMSSentToPatient.png';
import SMSICONToPlebo from '.././../../IconsANdImages/SMSSentToPhlebo.png';
import EMailTOPatient from '../../../IconsANdImages/MailSentToPatient.png';
import EmailToPhlebo from '../../../IconsANdImages/MailSentToPhlebo.png';
import UnableToConnect from '../../../IconsANdImages/UnableToContact.png';
import Late from '../../../IconsANdImages/Late.png';
import DoorBell from '../../../IconsANdImages/DoorBell.png';
import { useNavigate } from 'react-router-dom';
/* 
 * CancelBookingModal Component
 * This modal allows users to cancel a booking by selecting a reason.
 * It relies on React state for functionality and the provided CSS for styling.
 */
const CancelBookingModal = ({ isOpen, onClose, onConfirm, bookingId }) => {
  const [cancelReason, setCancelReason] = useState('');
  const [customReason, setCustomReason] = useState('');

  const handleConfirm = () => {
    if (!cancelReason) {
      alert("Please select a reason for cancellation.");
      return;
    }

    if (cancelReason === 'Others' && !customReason.trim()) {
      alert("Please provide a custom reason for cancellation.");
      return;
    }

    const finalReason = cancelReason === 'Others' ? customReason : cancelReason;
    onConfirm(bookingId, finalReason);
  };

  if (!isOpen) return null;

  return (
    <div className="bookings-cancel-modal">
      <div className="bookings-cancel-modal-content">
        <h2>Cancel Booking</h2>
        <p>Please select a reason for cancellation:</p>
        <select 
          value={cancelReason} 
          onChange={(e) => setCancelReason(e.target.value)}
        >
          <option value="">Select a reason</option>
          <option value="Time slot not available">Time slot not available</option>
          <option value="Phlebotomist issue">Phlebotomist issue</option>
          <option value="Phlebotomist reached late">Phlebotomist reached late</option>
          <option value="Patient wasn't fasting">Patient wasn't fasting</option>
          <option value="Patient is travelling">Patient is travelling</option>
          <option value="High cost of tests">High cost of tests</option>
          <option value="Patient will rebook">Patient will rebook</option>
          <option value="Patient tests from another lab">Patient tests from another lab</option>
          <option value="No response from patient for confirmation">No response from patient for confirmation</option>
          <option value="No response from patient at doorstep">No response from patient at doorstep</option>
          <option value="Others">Others</option>
        </select>
        {cancelReason === 'Others' && (
          <input
            type="text"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            placeholder="Please specify the reason"
          />
        )}
        <div className="bookings-cancel-modal-buttons">
          <button onClick={handleConfirm} className="confirm-cancel-button">Confirm Cancel</button>
          <button onClick={onClose} className="close-cancel-button">Close</button>
        </div>
      </div>
    </div>
  );
};

/* 
 * LogsModal Component
 * Displays a list of logs related to a specific booking.
 * Utilizes the LogEntry component for each log.
 */
/* 
 * LogsModal Component
 * Displays a list of logs related to a specific booking in a structured and user-friendly table.
 * Enhancements include better layout, improved styling, and accessibility features.
 */
const LogsModal = ({ isOpen, onClose, logs }) => {
  if (!isOpen) return null;

  return (
    <div className="logs-modal" role="dialog" aria-modal="true" aria-labelledby="logs-modal-title">
      <div className="logs-modal-content">
        <div className="logs-modal-header">
          <h2 id="logs-modal-title">Booking Logs</h2>
          <button onClick={onClose} className="close-log-modal-button" aria-label="Close Logs Modal">&times;</button>
        </div>
        <div className="logs-modal-body">
          {logs && logs.length > 0 ? (
            <table className="logs-table">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>User</th>
                  <th>Date & Time</th>
                  <th>Description</th>
                  <th>Changes</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => {
                  let changes = log.changes;
                  
                  // Handle 'changes' field being a stringified array
                  if (typeof changes === 'string') {
                    try {
                      changes = JSON.parse(changes);
                      // If parsing results in a string (due to double stringification), wrap it in an array
                      if (typeof changes === 'string') {
                        changes = [changes];
                      }
                    } catch (error) {
                      console.error(`Error parsing changes for log index ${index}:`, error);
                      changes = [changes];
                    }
                  }

                  return (
                    <tr key={index}>
                      <td>{log.action || 'N/A'}</td>
                      <td>{log.user || 'N/A'}</td>
                      <td>{log.date ? new Date(log.date).toLocaleString() : 'N/A'}</td>
                      <td>{log.description || 'N/A'}</td>
                      <td>
                        {Array.isArray(changes) && changes.length > 0 ? (
                          <ul>
                            {changes.map((change, idx) => (
                              <li key={idx}>{change}</li>
                            ))}
                          </ul>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p className="no-logs-message">No logs available for this booking.</p>
          )}
        </div>
        <div className="logs-modal-footer">
          <button onClick={onClose} className="close-log-button">Close</button>
        </div>
      </div>
    </div>
  );
};



/* 
 * Bookings Component
 * Main component to display and manage bookings.
 * Includes functionalities like viewing logs, cancelling bookings, assigning phlebotomists, etc.
 */
const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [searchOption, setSearchOption] = useState('created_at');
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [assignedPhleboFilter, setAssignedPhleboFilter] = useState('');
  const [phlebotomists, setPhlebotomists] = useState([]);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState(null);

  // State variables for Logs Modal
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [bookingForLogs, setBookingForLogs] = useState(null);

  /* 
   * Helper function to determine the background color class for each booking row.
   * Returns one of the predefined classes: orange-bg, red-bg, darkpurple-bg, lightpink-bg, lightpurple-bg, default-bg
   */
  const getRowBackgroundColorClass = (booking) => {
    const {
      is_active,
      is_Collected,
      preferred_time,
      date_for_collection,
      Assign_phelobo,
      Sample_collection_date,
      Sample_collection_time,
    } = booking;

    const currentDate = new Date();

    // Parse preferred times
    const [preferredTimeStartStr, preferredTimeEndStr] = preferred_time.split(' to ');
    const preferredDateTimeStart = new Date(`${date_for_collection}T${preferredTimeStartStr}`);
    const preferredDateTimeEnd = new Date(`${date_for_collection}T${preferredTimeEndStr}`);

    // Add 15 minutes to preferredDateTimeEnd
    const preferredDateTimeEndPlus15 = new Date(preferredDateTimeEnd.getTime() + 15 * 60 * 1000);

    let sampleCollectionDateTime = null;
    if (Sample_collection_date && Sample_collection_time) {
      sampleCollectionDateTime = new Date(`${Sample_collection_date}T${Sample_collection_time}`);
    }

    // 1. Check if booking is inactive (orange background)
    if (!is_active) {
      return 'orange-bg';
    }

    // 2. Check if the sample is collected
    if (is_Collected) {
      // 2a. If sampleCollectionDateTime exists
      if (sampleCollectionDateTime) {
        if (sampleCollectionDateTime <= preferredDateTimeEnd) {
          return 'lightpurple-bg'; // Collected on time or early
        } else {
          return 'darkpurple-bg'; // Collected late
        }
      } else {
        // Sample collection date/time not provided, but is_collected is true
        return 'lightpurple-bg'; // Default to lightpurple
      }
    }

    // 3. Check if time is over by 15 minutes and not collected (red background)
    if (currentDate > preferredDateTimeEndPlus15) {
      return 'red-bg';
    }

    // 4. Check if the booking is assigned
    if (Assign_phelobo) {
      // 4a. If assigned but none of the above conditions are met, return 'lightpink-bg'
      return 'lightpink-bg';
    } else {
      // 5. If not assigned and none of the above conditions are met, return 'default-bg'
      return 'default-bg';
    }
  };

  /* 
   * Converts a JavaScript Date object to IST (Indian Standard Time) formatted as 'YYYY-MM-DD'.
   */
  const convertToIST = (date) => {
    const offsetIST = 5.5 * 60 * 60 * 1000; // Offset in milliseconds for IST (UTC+5:30)
    const utcDate = new Date(date);
    const istDate = new Date(utcDate.getTime() + offsetIST);
    return istDate.toISOString().split('T')[0]; // Return date in 'YYYY-MM-DD' format
  };

  /* 
   * Initializes the fromDate and toDate to today's date in IST if not already set.
   */
  useEffect(() => {
    if (!fromDate || !toDate) {
      const today = convertToIST(new Date());
      setFromDate(today);
      setToDate(today);
    }
  }, []);

  /* 
   * Fetches the current user's data upon component mount.
   */
  useEffect(() => {
    axiosInstance.get('/api/current-user/')
      .then(response => {
        console.log('Current user data:', response.data);  // Debug log for current user details
        setCurrentUser(response.data);
      })
      .catch(error => {
        console.error('Error fetching current user data:', error);
      });
  }, []);

  /* 
   * Handles the initiation of booking cancellation by opening the CancelBookingModal.
   */
  const handleCancelBooking = (uniqueId) => {
    setBookingToCancel(uniqueId);
    setShowCancelModal(true);
  };

  /* 
   * Confirms the cancellation of a booking by sending a POST request with the cancellation reason.
   */
  const handleConfirmCancel = async (bookingId, reason) => {
    try {
      // Step 1: Cancel the booking by sending a POST request to '/api/bookings/cancel/'
      const response = await axiosInstance.post('/api/bookings/cancel/', {
        unique_id: bookingId,
        is_active: false,
        cancel_reason: reason
      });
  
      if (response.status === 200) {
        // Step 2: Find the booking details from the current bookings state
        const booking = bookings.find(b => b.Unique_id === bookingId);
  
        if (booking) {
          const { patient_name, patient_email } = booking;
  
          // Step 3: Prepare the payload for sending the cancellation email
          const emailPayload = {
            patient_name: patient_name,
            patient_email: patient_email,
            booking_id: bookingId,
            cancel_reason: reason
          };
  
          // Step 4: Send the cancellation email asynchronously without awaiting
          axiosInstance.post('/api/send-cancellation-email/', emailPayload)
            .then(emailResponse => {
              if (emailResponse.status === 200) {
                // Optionally log success or notify via a non-blocking UI element
                console.log("Cancellation email sent successfully.");
              } else {
                // Optionally log failure or notify via a non-blocking UI element
                console.error("Failed to send cancellation email.");
              }
            })
            .catch(emailError => {
              // Log the error for debugging purposes
              console.error("Error sending cancellation email:", emailError);
              // Optionally, you can send this error to your logging service
            });
        } else {
          // If booking details are not found, still inform the user about the cancellation
          console.warn(`Booking with ID ${bookingId} not found in state.`);
        }
  
        // Step 5: Update the state to reflect the cancellation immediately
        alert("Booking has been canceled.");
        setShowCancelModal(false);
        setBookingToCancel(null);
        fetchBookings(); // Refresh the bookings list
  
        // Optionally, you can trigger a non-blocking notification for email sending
        // Example using a toast notification library
        // toast.info("Cancellation email is being sent to the patient.");
      }
    } catch (error) {
      // Handle errors that occur while canceling the booking
      console.error("Error cancelling the booking:", error);
      alert("There was an error cancelling the booking. Please try again.");
    }
  };
  

  /* 
   * Fetches the list of bookings based on the current filters and user type.
   */
  const fetchBookings = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/api/showBookings/', {
        params: {
          from_date: fromDate,
          to_date: toDate,
          search_term: searchTerm || '',
          date_filter_type: searchOption,
          status: statusFilter,
          assigned_phlebo: assignedPhleboFilter,
          // Add a new parameter for phlebotomist filtering
          phlebotomist: currentUser && currentUser.user_type === 'phlebotomist' ? currentUser.username : '',
        },
      });

      if (response.status === 200) {
        // Filter bookings if the current user is a phlebotomist
        const filteredBookings = currentUser && currentUser.user_type === 'phlebotomist'
          ? response.data.bookings.filter(booking => booking.Assign_phelobo === currentUser.username)
          : response.data.bookings;
        
        setBookings(filteredBookings || []);
        setPhlebotomists(response.data.phlebotomists || []);
      } else {
        console.error('Error fetching bookings. Status:', response.status);
        setPhlebotomists([]);
      }
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setPhlebotomists([]);
    }
  }, [fromDate, toDate, searchTerm, searchOption, statusFilter, assignedPhleboFilter, currentUser]);

  /* 
   * Fetches bookings whenever the current user or relevant filters change.
   */
  useEffect(() => {
    if (currentUser) {
      fetchBookings();
    }
  }, [currentUser, fetchBookings]);

  /* 
   * Handles the "View Logs" button click by opening the LogsModal.
   */
  const handleLogsButtonClick = (uniqueId) => {
    setBookingForLogs(uniqueId);
    setShowLogsModal(true);
  };

  /* 
   * Fetches the logs for a specific booking whenever bookingForLogs changes.
   */
  useEffect(() => {
    if (bookingForLogs) {
      // Fetch logs for the booking
      axiosInstance.get(`/api/bookings/${bookingForLogs}/logs/`)
        .then(response => {
          setLogsData(response.data.logs);
        })
        .catch(error => {
          console.error('Error fetching logs:', error);
          setLogsData([]);
        });
    }
  }, [bookingForLogs]);

  const navigate = useNavigate();

  const handleEstimateClick = () => {
    navigate('/Reports/Estimate');
  };

  /* 
   * Applies the current filters by fetching bookings.
   */
  const applyFilters = () => {
    if (currentUser) {
      fetchBookings(); // Call fetchBookings only if currentUser is available
    }
  };

  /* 
   * Opens a popup window for editing a booking.
   * Refreshes the bookings list once the popup is closed.
   */
  const openEditPopup = (uniqueId) => {
    const width = window.innerWidth * 0.6;
    const height = window.innerHeight * 0.9;
    const url = `https://crm.testing.drdangslab.com/report/addbooking?Unique_id=${uniqueId}`;
    const options = `width=${width},height=${height},top=30,left=20`;

    const popup = window.open(url, 'EditPopup', options);

    // Listen for when the popup window is closed
    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer);
        // Refresh the bookings list
        fetchBookings();
      }
    }, 500);
  };

  /* 
   * Opens a popup window for managing a booking.
   * Refreshes the bookings list once the popup is closed.
   */
  const openManage = (uniqueId) => {
    const width = window.innerWidth * 0.6;
    const height = window.innerHeight * 0.9;
    const url = `https://crm.testing.drdangslab.com/report/ManageHomeCollection?Unique_id=${uniqueId}`;
    const options = `width=${width},height=${height},top=30,left=20`;

    const popup = window.open(url, 'ManagePopup', options);

    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer);
        fetchBookings();
      }
    }, 500);
  };

  /* 
   * Opens a popup window for assigning a phlebotomist to a booking.
   * Refreshes the bookings list once the popup is closed.
   */
  const openAssign = (uniqueId) => {
    const width = window.innerWidth * 0.6;
    const height = window.innerHeight * 0.9;
    const url = `https://crm.testing.drdangslab.com/report/Assign?Unique_id=${uniqueId}`;
    const options = `width=${width},height=${height},top=30,left=20`;

    const popup = window.open(url, 'AssignPopup', options);

    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer);
        fetchBookings();
      }
    }, 500);
  };

  /* 
   * Formats the date and time for the date_for_collection field.
   */
  const formatDateTime = (timestamp, preferredTime) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate()} ${date.toLocaleString('en', { month: 'short' })} ${date.getFullYear()}`;
    return (
      <div>
        <strong>SCD :</strong> {formattedDate}
      </div>
    );
  };

  /* 
   * Formats the created_at timestamp, optionally including the time.
   */
  const formatDateTimeCreatedAt = (timestamp, includeTime = false) => {
    const date = new Date(timestamp);
    const formattedDate = ` ${date.getDate()} ${date.toLocaleString('en', { month: 'short' })} ${date.getFullYear()}`;
    const formattedTime = ` ${date.toLocaleTimeString()}`;
    return (
      <div>
        <strong>BD :</strong> {formattedDate}
        {includeTime && (
          <span>
            <strong> BT :</strong> {formattedTime}
          </span>
        )}
      </div>
    );
  };

  /* 
   * Handles changes in the search input field.
   */
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  /* 
   * Handles changes in the fromDate input field.
   */
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  /* 
   * Handles changes in the toDate input field.
   */
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  /* 
   * Handles changes in the searchOption select field.
   */
  const handleSearchOptionChange = (e) => {
    setSearchOption(e.target.value);
  };

  /* 
   * Renders activity icons based on the booking's activity counts.
   */
  const renderActivityIcons = (booking) => {
    return (
      <div className="activity-icons">
        <div>
          {/* SMS Sent To Patient */}
          {booking.sms_sent_to_patient !== 0 && (
            <img
              src={SMSICONToPatient}
              alt="SMS Sent To Patient"
              title="SMS Sent To Patient"
              className="activity-icon"
            />
          )}

          {/* SMS Sent To Phlebo */}
          {booking.sms_sent_to_phlebo !== 0 && (
            <img
              src={SMSICONToPlebo}
              alt="SMS Sent To Phlebo"
              title="SMS Sent To Phlebo"
              className="activity-icon"
            />
          )}
        </div>
        {/* Mail Sent To Patient */}
        <div>
          {booking.mail_sent_to_user !== 0 && (
            <img
              src={EMailTOPatient}
              alt="Mail Sent To Patient"
              title="Mail Sent To Patient"
              className="activity-icon"
            />
          )}

          {/* Mail Sent To Phlebo */}
          {booking.mail_sent_to_phlebo !== 0 && (
            <img
              src={EmailToPhlebo}
              alt="Mail Sent To Phlebo"
              title="Mail Sent To Phlebo"
              className="activity-icon"
            />
          )}
        </div>

        <div>
          {booking.unable_sms_sent_to_user !== 0 && (
            <img
              src={UnableToConnect}
              alt="Unable To Connect"
              title="Unable To Connect"
              className="activity-icon"
            />
          )}

          {/* Late SMS Count */}
          {booking.late_sms_count !== 0 && (
            <img
              src={Late}
              alt="Late"
              title="Late"
              className="activity-icon"
            />
          )}
          {booking.door_bell_sms_sent_to_user !== 0 && (
            <img
              src={DoorBell}
              alt="Door Bell"
              title="Door Bell"
              className="activity-icon"
            />
          )}
        </div>
      </div>
    );
  };

  /* 
   * Handles the uncancellation of a booking by sending a POST request.
   */
  const handleUncancelBooking = async (uniqueId) => {
    const confirmUncancel = window.confirm("Are you sure you want to uncancel this booking?");

    if (confirmUncancel) {
      try {
        const response = await axiosInstance.post('/api/bookings/cancel/', {
          unique_id: uniqueId,
          is_active: true  // Set is_active to true for uncancel
        });

        if (response.status === 200) {
          alert("Booking has been reactivated.");
          // Refresh the bookings list
          fetchBookings();
        }
      } catch (error) {
        console.error("Error reactivating the booking:", error);
        alert("There was an error reactivating the booking. Please try again.");
      }
    }
  };

  /* 
   * Opens a popup window to view the prescription associated with a booking.
   */
  const openPrescriptionPopup = (booking) => {
    const backendUrl = axiosInstance.defaults.baseURL;
    const prescriptionUrl = `${backendUrl}${booking.prescription}`;
    const fileExtension = prescriptionUrl.split('.').pop().toLowerCase();
    const width = 800;
    const height = 600;
    const options = `width=${width},height=${height},top=100,left=100,scrollbars=yes`;

    if (fileExtension === 'pdf') {
      window.open(prescriptionUrl, '_blank', options);
    } else {
      const imgWindow = window.open('', '_blank', options);
      imgWindow.document.write(`
        <html>
          <head>
            <title>View Prescription</title>
          </head>
          <body style="margin: 0; padding: 0;">
            <img src="${prescriptionUrl}" style="width: 100%; height: auto;" />
          </body>
        </html>
      `);
    }
  };

  /* 
   * Opens a popup window to print the prescription associated with a booking.
   */
  const printPrescription = (booking) => {
    const backendUrl = axiosInstance.defaults.baseURL;
    const prescriptionUrl = `${backendUrl}${booking.prescription}`;
    const fileExtension = prescriptionUrl.split('.').pop().toLowerCase();

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (fileExtension === 'pdf') {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Prescription</title>
          </head>
          <body style="margin: 0; padding: 0;">
            <embed src="${prescriptionUrl}" type="application/pdf" style="width:100%; height:100%;" />
          </body>
        </html>
      `);
    } else {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Prescription</title>
          </head>
          <body style="margin: 0; padding: 0;">
            <img src="${prescriptionUrl}" style="width: 100%; height: auto;" onload="window.print(); window.close();" />
          </body>
        </html>
      `);
    }
  };

  return (
    <>
      <div className='BookingMainTable'>
        <h2>Booking List</h2>

        {/* Search and Filter Section */}
        <div className="SearchFilter">
          <div className="filter-row">
            <label>
              Search On:
              <select value={searchOption} onChange={handleSearchOptionChange}>
                <option value="created_at">Booking Date</option>
                <option value="date_for_collection">Date for Collection</option>
              </select>
            </label>
            <label>
              From:
              <input type="date" value={fromDate} onChange={handleFromDateChange} />
            </label>
            <label>
              To:
              <input type="date" value={toDate} onChange={handleToDateChange} />
            </label>
          </div>
          <div className="filter-row">
            <label>
              Status:
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                <option value="">All</option>
                <option value="assigned">Assigned</option>
                <option value="unassigned">Unassigned</option>
                <option value="collected">Collected</option>
                <option value="not_collected">Not Collected</option>
                <option value="collected_late">Collected Late</option>
              </select>
            </label>
            <label>
              Assigned Phlebotomist:
              <select 
                value={assignedPhleboFilter} 
                onChange={(e) => setAssignedPhleboFilter(e.target.value)}
              >
                <option value="">All</option>
                {phlebotomists.map((phlebo) => (
                  <option key={phlebo.name} value={phlebo.name}>{phlebo.name}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="filter-row">
            <label>
              Name/Email/Mobile:
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
            </label>
            <button onClick={applyFilters}>Generate</button>
          </div>
        </div>

        {/* Bookings Table */}
        <div className='forscrollableTable'>
          <table className='BookingTable my-bookings-table'>
            <thead>
              <tr>
                <th className='BookingIDButton'>ID</th>
                <th className='BookingActionButton'>Action</th>
                <th className='BookingAssigntoButton'>Assign to</th>
                <th className='BookingDTButton'>DT Confirm Date Time</th>
                <th className='BookingCDButton'>Customer Details</th>
                <th className='BookingRemarkButton'>Remark</th>
                <th className='BookingByButton'>By</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr key={index} className={getRowBackgroundColorClass(booking)}>
                  <td>{booking.Unique_id}</td>
                  <td>
                    <div className='BookingsButton'>
                      {currentUser && currentUser.user_type === 'phlebotomist' ? (
                        <>
                          <div className='BookingsButtonButton'>
                          <button
        className="action-button estimate-button"
        onClick={handleEstimateClick}
      >
        Estimate
      </button>
                          </div>
                          <div className='BookingsButtonButton'>
                            <button onClick={() => openManage(booking.Unique_id)} className="action-button manage-button">Manage</button>
                          </div>
                        </>
                      ) : (
                        <>
                          {booking.is_active ? (
                            <>
                              <div className='BookingsButtonButton'>
                                <button onClick={() => openEditPopup(booking.Unique_id)} className="action-button edit-button">Edit</button>
                              </div>
                              <div className='BookingsButtonButton'>
                                <button onClick={() => openManage(booking.Unique_id)} className="action-button manage-button">Manage</button>
                              </div>
                              <div className='BookingsButtonButton'>
                                <button onClick={() => openAssign(booking.Unique_id)} className="action-button assign-button">Assign</button>
                              </div>
                              <div className='BookingsButtonButton'>
                                <button onClick={() => handleCancelBooking(booking.Unique_id)} className="action-button cancel-button">Cancel</button>
                              </div>
                              <div className='BookingsButtonButton'>
                              <button
        className="action-button estimate-button"
        onClick={handleEstimateClick}
      >
        Estimate
      </button>
                              </div>
                              {booking.prescription && booking.prescription !== '/media/prescriptions/' && (
                                <>
                                  <div className='BookingsButtonButton'>
                                    <button onClick={() => openPrescriptionPopup(booking)} className="action-button view-prescription-button">View Prescription</button>
                                  </div>
                                  {/* <div className='BookingsButtonButton'>
                                    <button onClick={() => printPrescription(booking)} className="action-button print-prescription-button">Print Prescription</button>
                                  </div> */}
                                </>
                              )}
                            </>
                          ) : (
                            <div className='BookingsButtonButton'>
                              <button onClick={() => handleUncancelBooking(booking.Unique_id)} className="action-button uncancel-button">Uncancel</button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </td>

                  <td>
                    {booking.Assign_phelobo}
                    {renderActivityIcons(booking)}
                    
                    {/* Only show these elements when is_Collected is true */}
                    {booking.is_Collected && (
                      <>
                        <div>CD: {booking.Sample_collection_date}</div>
                        <div>CT: {booking.Sample_collection_time}</div>
                        <div>INR: {booking.collection_amount} Mode: {booking.payment_mode}</div>
                      </>
                    )}
                  </td>

                  <td></td>
                  <td>
                    <div className='BookingName'><b>{booking.patient_name}</b></div>
                    <div className='BookingCreatedAt'>{formatDateTimeCreatedAt(booking.created_at, true)}</div>
                    <div className='BookingFor'>
                      <div>{formatDateTime(booking.date_for_collection, booking.preferred_time)}</div>
                      <div className='BookingsSCT'>
                        <strong>SCT:</strong> {` ${booking.preferred_time}`}
                      </div>
                    </div>
                    <div className='BookingsTest'>
                      <FontAwesomeIcon icon={faStethoscope} className="stethoscope-icon" />
                      <strong>: {booking.test_package_name}</strong>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faLocationDot} className="map-marker-icon" /> {` ${booking.address}`}
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faEnvelope} className="email-icon" /> {` ${booking.patient_email}`}
                    </div>
                    <div>
                      <span>
                        <FontAwesomeIcon icon={faPhone} className="phone-icon" /> {`${booking.mobile_number}`}
                      </span>
                      <span className='BookingsWhatsapp'>
                        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />{' '}
                        <a href={`https://api.whatsapp.com/send?phone=${booking.whatsapp_number}`} target="_blank" rel="noopener noreferrer">
                          {booking.whatsapp_number}
                        </a>
                      </span>
                    </div>
                  </td>
                  <td>
                    {booking.remark}
                    {/* Add the View Logs button */}
                    <div className='BookingsButtonButton'>
                      <button onClick={() => handleLogsButtonClick(booking.Unique_id)} className="action-button view-logs-button">View Logs</button>
                    </div>
                  </td>
                  <td>{booking.username}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Cancel Booking Modal */}
      {showCancelModal && (
        <CancelBookingModal
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={handleConfirmCancel}
          bookingId={bookingToCancel}
        />
      )}

      {/* Logs Modal */}
      {showLogsModal && (
        <LogsModal
          isOpen={showLogsModal}
          onClose={() => { setShowLogsModal(false); setBookingForLogs(null); }}
          logs={logsData}
        />
      )}
    </>
  );
};

export default Bookings;
