import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Use axiosInstance instead of axios
import './AddBlogCRM.css';

function AddBlogCRM() {
    const [formData, setFormData] = useState({
        blog_heading: '',
        thumbnail_alt_text: '',
        thumbnail_title_text: '',
        release_date: '',
        url_name: '',
        blog_thumbnail_image: null,
        blog_image1: null,
        blog_image2: null,
        blog_image3: null,
        blog_image4: null,
        blogs_content: '',
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const blog_id = params.get('blog_id');

        if (blog_id) {
            fetchBlogData(blog_id);
        }
    }, [location]);

    const fetchBlogData = async (blog_id) => {
        try {
            const response = await axiosInstance.get(`/api/api/news/blogs/Update/${blog_id}/`);
            if (response.status === 200) {
                setFormData(response.data);
            } else {
                console.error('Failed to fetch blog data.');
            }
        } catch (error) {
            console.error('Error fetching blog data:', error);
        }
    };

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'blog_heading':
                if (!value) error = 'Blog heading is required';
                break;
            case 'thumbnail_alt_text':
                if (!value) error = 'Thumbnail alt text is required';
                break;
            case 'thumbnail_title_text':
                if (!value) error = 'Thumbnail title text is required';
                break;
            case 'release_date':
                if (!value) error = 'Release date is required';
                break;
            case 'url_name':
                if (!value) error = 'URL name is required';
                break;
            case 'blogs_content':
                if (!value) error = 'Blog content is required';
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));

        return error;
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });

            validateField(name, value);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) newErrors[key] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            alert('Please fix the validation errors before submitting.');
            return;
        }

        const formDataToSend = new FormData();

        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                formDataToSend.append(key, formData[key]);
            }
        });

        const params = new URLSearchParams(location.search);
        const blog_id = params.get('blog_id');
        const url = blog_id
            ? `/api/update_blog/${blog_id}/`
            : '/api/add_blog/';

        try {
            setIsSubmitting(true);
            const response = await axiosInstance({
                method: blog_id ? 'put' : 'post',
                url,
                data: formDataToSend,
            });

            if (response.status === 200 || response.status === 201) {
                alert('Blog saved successfully!');
                window.location.href = '/News/Blogs'; // Redirect to the blog list page
            } else {
                alert('Failed to save blog');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to save blog');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="CRMBlog-container">
        <div className="CRMBlog-header">
            <h1>Blogs Master</h1>
            <Link to="/News/Blogs">
                <button className="CRMBlog-back-button">Back To Menu</button>
            </Link>
        </div>
        <h2 className="CRMBlog-form-title">{location.search ? 'Update Blog' : 'Add New Blog'}</h2>
        <form className="CRMBlog-form" onSubmit={handleSubmit}>
                <div className="CRMBlog-form-row">
                    <div className="CRMBlog-form-group">
                        <label>Blog Heading :</label>
                        <input
                            type="text"
                            name="blog_heading"
                            value={formData.blog_heading || ''}
                            onChange={handleChange}
                            className={errors.blog_heading ? 'input-error' : ''}
                        />
                        {errors.blog_heading && <span className="error-message">{errors.blog_heading}</span>}
                    </div>
                    <div className="CRMBlog-form-group">
                        <label>Thumbnail Alt Text :</label>
                        <input
                            type="text"
                            name="thumbnail_alt_text"
                            value={formData.thumbnail_alt_text || ''}
                            onChange={handleChange}
                            className={errors.thumbnail_alt_text ? 'input-error' : ''}
                        />
                        {errors.thumbnail_alt_text && <span className="error-message">{errors.thumbnail_alt_text}</span>}
                    </div>
                    <div className="CRMBlog-form-group">
                        <label>Thumbnail Title Text :</label>
                        <input
                            type="text"
                            name="thumbnail_title_text"
                            value={formData.thumbnail_title_text || ''}
                            onChange={handleChange}
                            className={errors.thumbnail_title_text ? 'input-error' : ''}
                        />
                        {errors.thumbnail_title_text && <span className="error-message">{errors.thumbnail_title_text}</span>}
                    </div>
                </div>
                <div className="CRMBlog-form-row">
                    <div className="CRMBlog-form-group">
                        <label>Release Date:</label>
                        <input
                            type="date"
                            name="release_date"
                            value={formData.release_date || ''}
                            onChange={handleChange}
                            className={errors.release_date ? 'input-error' : ''}
                        />
                        {errors.release_date && <span className="error-message">{errors.release_date}</span>}
                    </div>
                    <div className="CRMBlog-form-group">
                        <label>Blog Thumbnail Image :</label>
                        <input
                            type="file"
                            name="blog_thumbnail_image"
                            onChange={handleChange}
                        />
                        {formData.blog_thumbnail_image && (
                            <img
                                src={
                                    typeof formData.blog_thumbnail_image === 'string'
                                        ? `https://api.dangsccg.co.in${formData.blog_thumbnail_image}`
                                        : URL.createObjectURL(formData.blog_thumbnail_image)
                                }
                                alt="Thumbnail Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMBlog-form-group">
                        <label>URL Name :</label>
                        <input
                            type="text"
                            name="url_name"
                            value={formData.url_name || ''}
                            onChange={handleChange}
                            className={errors.url_name ? 'input-error' : ''}
                        />
                        {errors.url_name && <span className="error-message">{errors.url_name}</span>}
                    </div>
                </div>
                <div className="CRMBlog-form-row">
                    <div className="CRMBlog-form-group">
                        <label>Blog Image 1 :</label>
                        <input
                            type="file"
                            name="blog_image1"
                            onChange={handleChange}
                        />
                        {formData.blog_image1 && (
                            <img
                                src={
                                    typeof formData.blog_image1 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.blog_image1}`
                                        : URL.createObjectURL(formData.blog_image1)
                                }
                                alt="Image 1 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMBlog-form-group">
                        <label>Blog Image 2 :</label>
                        <input
                            type="file"
                            name="blog_image2"
                            onChange={handleChange}
                        />
                        {formData.blog_image2 && (
                            <img
                                src={
                                    typeof formData.blog_image2 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.blog_image2}`
                                        : URL.createObjectURL(formData.blog_image2)
                                }
                                alt="Image 2 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMBlog-form-group">
                        <label>Blog Image 3 :</label>
                        <input
                            type="file"
                            name="blog_image3"
                            onChange={handleChange}
                        />
                        {formData.blog_image3 && (
                            <img
                                src={
                                    typeof formData.blog_image3 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.blog_image3}`
                                        : URL.createObjectURL(formData.blog_image3)
                                }
                                alt="Image 3 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMBlog-form-group">
                        <label>Blog Image 4 :</label>
                        <input
                            type="file"
                            name="blog_image4"
                            onChange={handleChange}
                        />
                        {formData.blog_image4 && (
                            <img
                                src={
                                    typeof formData.blog_image4 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.blog_image4}`
                                        : URL.createObjectURL(formData.blog_image4)
                                }
                                alt="Image 4 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                </div>
                <div className="CRMBlog-form-group CRMBlog-content-group">
                    <label>Blog Content :</label>
                    <textarea
                        name="blogs_content"
                        value={formData.blogs_content || ''}
                        onChange={handleChange}
                        className={errors.blogs_content ? 'input-error' : ''}
                    ></textarea>
                    {errors.blogs_content && <span className="error-message">{errors.blogs_content}</span>}
                </div>
                <button type="submit" className="CRMBlog-submit-button" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
            </form>
        </div>
    );
}

export default AddBlogCRM;
