import React from 'react';
import { Link } from 'react-router-dom';
import './User.css';

function User() {
  return (
    <div className="user-unique__container">
      <div className="user-unique__heading">
        <h1>User Details</h1>
      </div>
      <div className="user-unique__button-group">
        <span>
          <Link to="/User/Add-User">
            <button className="user-unique__button">Add</button>
          </Link>
        </span>
        <span>
          <Link to="/User/Update-User">
            <button className="user-unique__button">Update</button>
          </Link>
        </span>
        <span>
          <Link to="/User/Delete-User">
            <button className="user-unique__button">Delete</button>
          </Link>
        </span>
      </div>
    </div>
  );
}

export default User;
