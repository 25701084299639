import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance'; // Use axiosInstance instead of axios
import './AddNews.css';
import { Link, useLocation } from 'react-router-dom';

const NewsForm = () => {
  const initialFormData = {
    news_heading: '',
    news_type: 'Article',
    released_date: '',  
    news_link: '',
    alt_text: '',
    news_image: null,
    news_content: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const newsId = query.get('news_id');
  const isDeleteMode = query.get('delete') === 'true';

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    document.getElementById('released_date').setAttribute('max', currentDate);
  
    if (newsId) {
      const fetchNewsData = async () => {
        try {
          const response = await axiosInstance.get(`/api/news/Update/${newsId}/`);
          if (response.status === 200) {
            setFormData(response.data);
          }
        } catch (error) {
          console.error('Error fetching news data:', error);
        }
      };
  
      fetchNewsData();
    }
  }, [newsId]);
  

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const formDataObj = new FormData();

      for (const key in formData) {
        const value = formData[key];
        if (value instanceof Blob) {
          formDataObj.append(key, value, value.name);
        } else if (typeof value === 'string' || value === null) {
          formDataObj.append(key, value);
        }
      }

      if (isDeleteMode) {
        const deleteUrl = `/api/news/Update/${newsId}/`;

        const response = await axiosInstance.delete(deleteUrl);
        if (response.status === 204) {
          setSuccessMessage('News item deleted successfully.');
          setFormData(initialFormData);
        } else {
          setErrorMessage('Failed to delete the news item.');
        }
      } else if (newsId) {
        const updateUrl = `/api/news/Update/${newsId}/`;

        const response = await axiosInstance.put(updateUrl, formDataObj);
        if (response.status === 200) {
          setSuccessMessage('News item updated successfully.');
        } else {
          setErrorMessage('Failed to update the news item.');
        }
      } else {
        const createUrl = '/api/add-news/';

        const response = await axiosInstance.post(createUrl, formDataObj);
        if (response.status === 201) {
          setSuccessMessage('News item created successfully.');
        } else {
          setErrorMessage('Failed to create the news item.');
        }
        setFormData(initialFormData);
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please check your input and try again.');
      console.error(error);
    }
  };

  return (
    <div className='add-news-unique__container'>
      <div className='add-news-unique__header'>
        <h2>{isDeleteMode ? 'Delete News' : (newsId ? 'Update Existing News' : 'Add New News')}</h2>
        <Link to="/News/News">
          <button>Back To Menu</button>
        </Link>
      </div>

      <form onSubmit={handleSubmit} encType="multipart/form-data" className="add-news-unique__form">
        <div className='add-news-unique__flex-row'>
          <div className='add-news-unique__flex-column'>
            <label htmlFor="news_heading">News Heading:</label>
            <input
              type="text"
              id="news_heading"
              name="news_heading"
              value={formData.news_heading}
              onChange={handleChange}
              disabled={isDeleteMode}
            />
          </div>
          <div className='add-news-unique__flex-column'>
            <label htmlFor="news_type">News Type:</label>
            <select
              id="news_type"
              name="news_type"
              value={formData.news_type}
              onChange={handleChange}
              disabled={isDeleteMode}
            >
              <option value="Article">Article</option>
              <option value="Audio">Audio</option>
              <option value="Video">Video</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Technology">Technology</option>
            </select>
          </div>
          <div className='add-news-unique__flex-column'>
            <label htmlFor="released_date">Released Date:</label>
            <input
              type="date"
              id="released_date"
              name="released_date"
              value={formData.released_date}
              onChange={handleChange}
              disabled={isDeleteMode}
            />
          </div>
        </div>

        <label htmlFor="news_link">News Link:</label>
        <input
          type="url"
          id="news_link"
          name="news_link"
          value={formData.news_link}
          onChange={handleChange}
          disabled={isDeleteMode}
        />

        <div className='add-news-unique__flex-row'>
          <div className='add-news-unique__flex-column'>
            <label htmlFor="alt_text">Alt Text:</label>
            <input
              type="text"
              id="alt_text"
              name="alt_text"
              value={formData.alt_text}
              onChange={handleChange}
              disabled={isDeleteMode}
            />
          </div>
          <div className='add-news-unique__flex-column'>
            <label htmlFor="news_image">News Image:</label>
            <input
              type="file"
              name="news_image"
              id="news_image"
              accept="image/*"
              onChange={handleChange}
              disabled={isDeleteMode}
            />
          </div>
        </div>

        <label htmlFor="news_content">News Content:</label>
        <textarea
          id="news_content"
          name="news_content"
          value={formData.news_content}
          onChange={handleChange}
          disabled={isDeleteMode}
        />

        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className='add-news-unique__button-container'>
          <button type="submit">
            {isDeleteMode ? 'Delete' : (newsId ? 'Update' : 'Submit')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsForm;
