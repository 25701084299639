import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Ensure axiosInstance is configured
import './AddSpecialInstructions.css';

const AddSpecialInstructions = () => {
    const [testName, setTestName] = useState('');
    const [specialInstructions, setSpecialInstructions] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        let formErrors = {};
        if (!testName) formErrors.testName = 'Test Name is required';
        if (!specialInstructions) formErrors.specialInstructions = 'Special Instructions are required';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const data = { test_name: testName, special_instructions: specialInstructions };

        try {
            const response = await axiosInstance.post('/api/api/special-instructions/', data);
            console.log('Response:', response.data);
            navigate('/Packages/SpecialInstructions'); // Redirect after successful submission
        } catch (error) {
            console.error('Error submitting special instructions:', error);
        }
    };

    return (
        <div className="add-special-instructions-container">
            <div className="add-special-instructions-header">
                <h2>Special Instructions Details</h2>
                <Link to="/Packages/SpecialInstructions">
                    <button className="add-special-instructions-back-button">Back To Menu</button>
                </Link>
            </div>
            <div className="add-special-instructions-form-container">
                <h3>Add New Special Instructions</h3>
                <form onSubmit={handleSubmit}>
                    <div className="add-special-instructions-form-group">
                        <label htmlFor="testName">Test Name:</label>
                        <input
                            type="text"
                            id="testName"
                            value={testName}
                            onChange={(e) => setTestName(e.target.value)}
                        />
                        {errors.testName && <p className="error-message">{errors.testName}</p>}
                    </div>
                    <div className="add-special-instructions-form-group">
                        <label htmlFor="specialInstructions">Special Instructions:</label>
                        <textarea
                            id="specialInstructions"
                            value={specialInstructions}
                            onChange={(e) => setSpecialInstructions(e.target.value)}
                        />
                        {errors.specialInstructions && <p className="error-message">{errors.specialInstructions}</p>}
                    </div>
                    <button type="submit" className="add-special-instructions-submit-button">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AddSpecialInstructions;
