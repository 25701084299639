import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance'; // Import axios instance
import './UpdateNews.css';
import { Link } from 'react-router-dom';

const NewsTable = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [search, setSearch] = useState('');

  const openEditPopup = (News_id) => {
    if (News_id) {
      const width = window.innerWidth * 0.6;
      const height = window.innerHeight * 0.9;
      const url = `https://crm.testing.drdangslab.com/News/Add-News?news_id=${News_id}`;
      const options = `width=${width},height=${height},top=30,left=20`;
      window.open(url, 'EditPopup', options);
    }
  };

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const response = await axiosInstance.get('/api/newsData/'); // Use axiosInstance

        if (response.status === 200) {
          const sortedNewsItems = response.data.sort((a, b) => {
            return parseInt(b.News_id) - parseInt(a.News_id);
          });

          setNewsItems(sortedNewsItems);
        } else {
          console.error('Error fetching news items. Status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching news items:', error);
      }
    };

    fetchNewsItems();
  }, []);

  const filteredNewsItems = newsItems.filter((item) => {
    return item.news_heading.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
    <div className='UpdateNewsMainDiv'>
      <div className='add-news-unique__header'>
        <h2>News Details</h2>
        <Link to="/News/News">
          <button>Back To Menu</button>
        </Link>
      </div>

      <div className='update-news-unique__filter-container'>
        <input
          type="text"
          placeholder="Search Headlines"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <table className='update-news-unique__table'>
        <thead>
          <tr>
            <th>Action</th>
            <th>News Type</th>
            <th>Headlines</th>
            <th>Released on</th>
            <th>Content</th>
            <th>Image Path</th>
            <th>Alt Text</th>
          </tr>
        </thead>
        <tbody>
          {filteredNewsItems.map((item) => (
            <tr key={item.id}>
              <td>
                <button onClick={() => openEditPopup(item.News_id)}>Select</button>
              </td>
              <td>{item.news_type}</td>
              <td>{item.news_heading}</td>
              <td>{item.released_date}</td>
              <td>{item.news_content}</td>
              <td>{item.news_image}</td>
              <td>{item.alt_text}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </>
  );
};

export default NewsTable;
