import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isAdmin } from './utils'; // Import the utility function

import AddBooking from './components/Reports/AddBooking/BookingForm';
import ColumnNavbar from './components/Navbar/ColumnNavbar';
import RowNavbar from './components/Navbar/RowNavbar'; 
import Bookings from './components/Reports/Bookings/Bookings';
import ManageHomeCollection from './components/Reports/Bookings/ManageHomeCollection';
import BookingAssign from './components/Reports/Bookings/Assign';
import User from './components/User/User';
import AddUser from './components/User/AddUser';
import UpdateUser from './components/User/UpdateUser';
import News from './components/News/News';
import AddNews from './components/News/AddNews';
import UpdateNews from './components/News/UpdateNews';
import DeleteNews from './components/News/DeleteNews';
import Estimate from './components/Reports/Estimate/Estimate';
import EstimateParams from './components/Reports/Estimate/EstimateParams';
import EstimateForAddBooking from './components/Reports/Estimate/EsitmateForAddBooking';
import Holidays from './components/Master/Holidays/Holidays';
import AddHolidays from './components/Master/Holidays/AddHolidays';
import UpdateHolidays from './components/Master/Holidays/UpdateHolidays';
import DeleteHolidays from './components/Master/Holidays/DeleteHolidays';
import TimeSlots from './components/Master/TimeSlots/TimeSlots';
import UpdateTimeSlots from './components/Master/TimeSlots/UpdateTimeSlots';
import DeleteTimeSlots from './components/Master/TimeSlots/DeleteTimeSlots';
import AddTimeSlots from './components/Master/TimeSlots/AddTimeSlots';
import ContactEnquiry from './components/Reports/ContactEnquiry/ContactEnquiry';
import CareerEnquiry from './components/Reports/Career/Career';
import FeedbackEnquiry from './components/Reports/Feedback/Feedback';
import AddPackages from './components/Packages/Packages/AddPackages';
import Tests from './components/Packages/Tests/Tests';
import AddTests from './components/Packages/Tests/AddTest';
import BlogCRM from './components/News/BlogsCRM/BlogsCRM';
import AddBlogCRM from './components/News/BlogsCRM/AddBlogCRM';
import UpdateBlogCRM from './components/News/BlogsCRM/UpdateBlogCRM';
import DeleteBlogCRM from './components/News/BlogsCRM/DeleteBlogCRM';
import ArticleCRM from './components/News/ArticleCRM/ArticleCRM';
import AddArticleCRM from './components/News/ArticleCRM/AddArticleCRM';
import UpdateArticleCRM from './components/News/ArticleCRM/UpdateArticleCRM';
import DeleteArticleCRM from './components/News/ArticleCRM/DeleteArticleCRM';
import SpecialInstructions from './components/Packages/SpecialInstructions/SpecialInstructions';
import AddSpecialInstructions from './components/Packages/SpecialInstructions/AddSpecialInstructions';
import UpdateSpecialInstructions from './components/Packages/SpecialInstructions/UpdateSpecialInstructions';
import DeleteSpecialInstructions from './components/Packages/SpecialInstructions/DeleteSpecialInstructions';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import DeleteUser from './components/User/DeleteUser';
import NotAuthorized from './components/NotAuthorized';

function App() {
  return (
    <Router>
      <TokenExpirationHandler>
        <MainContent />
      </TokenExpirationHandler>
    </Router>
  );
}

const TokenExpirationHandler = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Token has expired
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('user_name');
          localStorage.removeItem('user_username');
          localStorage.removeItem('user_role');
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Remove the interceptor when the component unmounts
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  return children;
};

const MainContent = () => {
  const location = useLocation();

  return (
    <div className="wholepage">
      {location.pathname !== '/login' && (
        <>
          <div className="Navbar">
            <RowNavbar />
            <ColumnNavbar />
          </div>
        </>
      )}
      <div className="pages">
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/not-authorized" element={<NotAuthorized />} />

          {/* Protected Routes */}
          <Route path='/Packages/SpecialInstructions' element={<ProtectedRoute allowedRoles={['admin']}><SpecialInstructions /></ProtectedRoute>} />
          <Route path='/Packages/AddSpecialInstructions' element={<ProtectedRoute allowedRoles={['admin']}><AddSpecialInstructions /></ProtectedRoute>} />
          <Route path='/Packages/UpdateSpecialInstructions' element={<ProtectedRoute allowedRoles={['admin']}><UpdateSpecialInstructions /></ProtectedRoute>} />
          <Route path='/Packages/DeleteSpecialInstructions' element={<ProtectedRoute allowedRoles={['admin']}><DeleteSpecialInstructions /></ProtectedRoute>} />
          <Route path='/User/Delete-User' element={<ProtectedRoute allowedRoles={['admin']}><DeleteUser /></ProtectedRoute>} />
          <Route path="/News/Blogs" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><BlogCRM /></ProtectedRoute>} />
          <Route path="/News/AddBlogCRM" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddBlogCRM /></ProtectedRoute>} />
          <Route path="/News/UpdateBlogCRM" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><UpdateBlogCRM /></ProtectedRoute>} />
          <Route path="/News/DeleteBlogCRM" element={<ProtectedRoute allowedRoles={['admin']}><DeleteBlogCRM /></ProtectedRoute>} />
          <Route path="/News/ArticleCRM" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><ArticleCRM /></ProtectedRoute>} />
          <Route path="/News/AddArticleCRM" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddArticleCRM /></ProtectedRoute>} />
          <Route path="/News/UpdateArticleCRM" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><UpdateArticleCRM /></ProtectedRoute>} />
          <Route path="/News/DeleteArticleCRM" element={<ProtectedRoute allowedRoles={['admin']}><DeleteArticleCRM /></ProtectedRoute>} />
          <Route path="/report/addbooking" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'employee','phlebotomist']}><AddBooking /></ProtectedRoute>} />
          <Route path="/report/Bookings" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'employee','phlebotomist']}><Bookings /></ProtectedRoute>} />
          <Route path="/report/ManageHomeCollection" element={<ProtectedRoute allowedRoles={['admin', 'manager', 'employee','phlebotomist']}><ManageHomeCollection /></ProtectedRoute>} />
          <Route path="/report/Assign" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><BookingAssign /></ProtectedRoute>} />
          <Route path="/User" element={<ProtectedRoute allowedRoles={['admin']}><User /></ProtectedRoute>} />
          <Route path="/User/Add-User" element={<ProtectedRoute allowedRoles={['admin']}><AddUser /></ProtectedRoute>} />
          <Route path="/User/Update-User" element={<ProtectedRoute allowedRoles={['admin']}><UpdateUser /></ProtectedRoute>} /> 
          <Route path="/News/News" element={<ProtectedRoute allowedRoles={['manager','admin']}><News /></ProtectedRoute>} /> 
          <Route path="/News/Add-News" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddNews /></ProtectedRoute>} />
          <Route path="/News/Update-News" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><UpdateNews /></ProtectedRoute>} />
          <Route path="/News/Deletes-News" element={<ProtectedRoute allowedRoles={['admin']}><DeleteNews /></ProtectedRoute>} />
          <Route path="/Reports/Estimate" element={<ProtectedRoute allowedRoles={['admin', 'manager','phlebotomist']}><Estimate /></ProtectedRoute>} />
          <Route path="/Reports/EstimateParams" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><EstimateParams /></ProtectedRoute>} />
          <Route path="/Reports/EstimateForAddBooking" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><EstimateForAddBooking /></ProtectedRoute>} />
          <Route path="/Master/Holidays" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><Holidays /></ProtectedRoute>} /> 
          <Route path="/Master/Add-Holidays" element={<ProtectedRoute allowedRoles={['admin']}><AddHolidays /></ProtectedRoute>} />
          <Route path="/Master/Update-Holidays" element={<ProtectedRoute allowedRoles={['admin']}><UpdateHolidays /></ProtectedRoute>} />
          <Route path="/Master/Deletes-Holidays" element={<ProtectedRoute allowedRoles={['admin']}><DeleteHolidays /></ProtectedRoute>} />
          <Route path="/Master/TimeSlots" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><TimeSlots /></ProtectedRoute>} />
          <Route path="/Master/UpdateTimeSlots" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><UpdateTimeSlots /></ProtectedRoute>} />
          <Route path="/Master/DeleteTimeSlots" element={<ProtectedRoute allowedRoles={['admin']}><DeleteTimeSlots /></ProtectedRoute>} />
          <Route path="/Master/AddTimeSlots" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddTimeSlots /></ProtectedRoute>} />
          <Route path="/report/ContactEnquiry" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><ContactEnquiry /></ProtectedRoute>} />
          <Route path="/report/CareerEnquiry" element={<ProtectedRoute allowedRoles={['admin', 'manager','hr']}><CareerEnquiry /></ProtectedRoute>} />
          <Route path="/report/FeedbackEnquiry" element={<ProtectedRoute allowedRoles={['admin', 'manager','qa']}><FeedbackEnquiry /></ProtectedRoute>} />
          <Route path="/Packages/AddPackages" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddPackages /></ProtectedRoute>} />
          <Route path="/Packages/Tests" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><Tests /></ProtectedRoute>} />
          <Route path="/Packages/AddTests" element={<ProtectedRoute allowedRoles={['admin', 'manager']}><AddTests /></ProtectedRoute>} />
        </Routes>
      </div>
    </div>
  );
};

export default App;