import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Import the axios instance with token handling
import './AddArticleCRM.css';

function AddArticle() {
    const [formData, setFormData] = useState({
        article_heading: '',
        thumbnail_alt_text: '',
        thumbnail_title_text: '',
        release_date: '',
        url_name: '',
        article_thumbnail_image: null,
        article_image1: null,
        article_image2: null,
        article_image3: null,
        article_image4: null,
        articles_content: '',
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const article_id = params.get('article_id');

        if (article_id) {
            fetchArticleData(article_id);
        }
    }, [location]);

    const fetchArticleData = async (article_id) => {
        try {
            const response = await axiosInstance.get(`/api/news/articles/update/${article_id}/`);
            if (response.status === 200) {
                setFormData(response.data);
            } else {
                console.error('Failed to fetch article data.');
            }
        } catch (error) {
            console.error('Error fetching article data:', error);
        }
    };

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'article_heading':
                if (!value) error = 'Article heading is required';
                break;
            case 'thumbnail_alt_text':
                if (!value) error = 'Thumbnail alt text is required';
                break;
            case 'thumbnail_title_text':
                if (!value) error = 'Thumbnail title text is required';
                break;
            case 'release_date':
                if (!value) error = 'Release date is required';
                break;
            case 'url_name':
                if (!value) error = 'URL name is required';
                break;
            case 'articles_content':
                if (!value) error = 'Article content is required';
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));

        return error;
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });

            validateField(name, value);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) newErrors[key] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            alert('Please fix the validation errors before submitting.');
            return;
        }

        const formDataToSend = new FormData();

        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                formDataToSend.append(key, formData[key]);
            }
        });

        const params = new URLSearchParams(location.search);
        const article_id = params.get('article_id');
        const url = article_id
            ? `/api/news/articles/update/${article_id}/`
            : '/api/add_article/';

        try {
            setIsSubmitting(true);
            const response = await axiosInstance({
                method: article_id ? 'put' : 'post',
                url,
                data: formDataToSend,
            });

            if (response.status === 200 || response.status === 201) {
                alert('Article saved successfully!');
                window.location.href = '/news/articlecrm'; // Redirect to the article list page
            } else {
                alert('Failed to save article');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to save article');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="CRMArticle-container">
            <div className="CRMArticle-header">
                <h1>Articles Master</h1>
                <Link to="/news/articlecrm">
                    <button className="CRMArticle-back-button">Back To Menu</button>
                </Link>
            </div>
            <h2 className="CRMArticle-form-title">{location.search ? 'Update Article' : 'Add New Article'}</h2>
            <form className="CRMArticle-form" onSubmit={handleSubmit}>
                <div className="CRMArticle-form-row">
                    <div className="CRMArticle-form-group">
                        <label>Article Heading :</label>
                        <input
                            type="text"
                            name="article_heading"
                            value={formData.article_heading || ''}
                            onChange={handleChange}
                            className={errors.article_heading ? 'input-error' : ''}
                        />
                        {errors.article_heading && <span className="error-message">{errors.article_heading}</span>}
                    </div>
                    <div className="CRMArticle-form-group">
                        <label>Thumbnail Alt Text :</label>
                        <input
                            type="text"
                            name="thumbnail_alt_text"
                            value={formData.thumbnail_alt_text || ''}
                            onChange={handleChange}
                            className={errors.thumbnail_alt_text ? 'input-error' : ''}
                        />
                        {errors.thumbnail_alt_text && <span className="error-message">{errors.thumbnail_alt_text}</span>}
                    </div>
                    <div className="CRMArticle-form-group">
                        <label>Thumbnail Title Text :</label>
                        <input
                            type="text"
                            name="thumbnail_title_text"
                            value={formData.thumbnail_title_text || ''}
                            onChange={handleChange}
                            className={errors.thumbnail_title_text ? 'input-error' : ''}
                        />
                        {errors.thumbnail_title_text && <span className="error-message">{errors.thumbnail_title_text}</span>}
                    </div>
                </div>
                <div className="CRMArticle-form-row">
                    <div className="CRMArticle-form-group">
                        <label>Release Date:</label>
                        <input
                            type="date"
                            name="release_date"
                            value={formData.release_date || ''}
                            onChange={handleChange}
                            className={errors.release_date ? 'input-error' : ''}
                        />
                        {errors.release_date && <span className="error-message">{errors.release_date}</span>}
                    </div>
                    <div className="CRMArticle-form-group">
                        <label>Article Thumbnail Image :</label>
                        <input
                            type="file"
                            name="article_thumbnail_image"
                            onChange={handleChange}
                        />
                        {formData.article_thumbnail_image && (
                            <img
                                src={
                                    typeof formData.article_thumbnail_image === 'string'
                                        ? `https://api.dangsccg.co.in${formData.article_thumbnail_image}`
                                        : URL.createObjectURL(formData.article_thumbnail_image)
                                }
                                alt="Thumbnail Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMArticle-form-group">
                        <label>URL Name :</label>
                        <input
                            type="text"
                            name="url_name"
                            value={formData.url_name || ''}
                            onChange={handleChange}
                            className={errors.url_name ? 'input-error' : ''}
                        />
                        {errors.url_name && <span className="error-message">{errors.url_name}</span>}
                    </div>
                </div>
                <div className="CRMArticle-form-row">
                    <div className="CRMArticle-form-group">
                        <label>Article Image 1 :</label>
                        <input
                            type="file"
                            name="article_image1"
                            onChange={handleChange}
                        />
                        {formData.article_image1 && (
                            <img
                                src={
                                    typeof formData.article_image1 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.article_image1}`
                                        : URL.createObjectURL(formData.article_image1)
                                }
                                alt="Image 1 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMArticle-form-group">
                        <label>Article Image 2 :</label>
                        <input
                            type="file"
                            name="article_image2"
                            onChange={handleChange}
                        />
                        {formData.article_image2 && (
                            <img
                                src={
                                    typeof formData.article_image2 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.article_image2}`
                                        : URL.createObjectURL(formData.article_image2)
                                }
                                alt="Image 2 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMArticle-form-group">
                        <label>Article Image 3 :</label>
                        <input
                            type="file"
                            name="article_image3"
                            onChange={handleChange}
                        />
                        {formData.article_image3 && (
                            <img
                                src={
                                    typeof formData.article_image3 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.article_image3}`
                                        : URL.createObjectURL(formData.article_image3)
                                }
                                alt="Image 3 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <div className="CRMArticle-form-group">
                        <label>Article Image 4 :</label>
                        <input
                            type="file"
                            name="article_image4"
                            onChange={handleChange}
                        />
                        {formData.article_image4 && (
                            <img
                                src={
                                    typeof formData.article_image4 === 'string'
                                        ? `https://api.dangsccg.co.in${formData.article_image4}`
                                        : URL.createObjectURL(formData.article_image4)
                                }
                                alt="Image 4 Preview"
                                style={{ width: '100px', marginTop: '10px' }}
                            />
                        )}
                    </div>
                </div>
                <div className="CRMArticle-form-group CRMArticle-content-group">
                    <label>Article Content :</label>
                    <textarea
                        name="articles_content"
                        value={formData.articles_content || ''}
                        onChange={handleChange}
                        className={errors.articles_content ? 'input-error' : ''}
                    ></textarea>
                    {errors.articles_content && <span className="error-message">{errors.articles_content}</span>}
                </div>
                <button type="submit" className="CRMArticle-submit-button" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
            </form>
        </div>
    );
}

export default AddArticle;
