import React from 'react';
import { Link } from 'react-router-dom';
import './TimeSlots.css';

const TimeSlots = () => {
    return (
        <div className="timeslots-container">
            <div className="timeslots-header">
                <h2>Time-Slot Master</h2>
            
            </div>

            <div className="timeslots-options">
                <div className="timeslots-option">
                    <Link to="/Master/AddTimeSlots">
                        <button>Add</button>
                    </Link>
                </div>
                <div className="timeslots-option">
                    <Link to="/Master/UpdateTimeSlots">
                        <button>Update</button>
                    </Link>
                </div>
                <div className="timeslots-option">
                    <Link to="/Master/DeleteTimeSlots">
                        <button>Delete</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default TimeSlots;
