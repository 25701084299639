import React from 'react';
import './NotAuthorized.css'; // Import the CSS file

const NotAuthorized = () => {
    return (
        <div className="not-authorized-container">
            <h1>403 - Not Authorized</h1>
            <p>You do not have permission to view this page.</p>
            <a href="/">Go Back to Home</a>
        </div>
    );
};

export default NotAuthorized;
