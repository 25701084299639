import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import your custom axiosInstance
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './ContactEnquiry.css';

const ContactEnquiry = () => {
  const today = new Date();
  const [enquiries, setEnquiries] = useState([]);
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  const [nameEmail, setNameEmail] = useState('');
  const [isSpam, setIsSpam] = useState({ value: 'All', label: 'All' });
  const [records, setRecords] = useState('');

  useEffect(() => {
    fetchEnquiries();
  }, [fromDate, toDate, nameEmail, isSpam, records]);

  const fetchEnquiries = async () => {
    try {
      const response = await axiosInstance.get('/api/filtered-enquiries/', {
        params: {
          from_date: fromDate ? fromDate.toLocaleDateString('en-GB').split('/').reverse().join('-') : '',
          to_date: toDate ? toDate.toLocaleDateString('en-GB').split('/').reverse().join('-') : '',
          name_email: nameEmail,
          is_spam: isSpam.value,
          records,
        },
      });
      setEnquiries(response.data);
    } catch (error) {
      console.error('Error fetching enquiries:', error);
    }
  };

  const handleIsSpamChange = (selectedOption) => {
    setIsSpam(selectedOption);
  };

  return (
    <div className="contact-enquiry">
      <h2>Search Contact Enquiry</h2>
      <div className="search-filters">
        <label>From:</label>
        <div className="search-datepicker">
          <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="dd-MM-yyyy" />
        </div>
        <label>To:</label>
        <div className="search-datepicker">
          <DatePicker selected={toDate} onChange={(date) => setToDate(date)} dateFormat="dd-MM-yyyy" />
        </div>
        <label>Enter Name / Email:</label>
        <div className="search-input">
          <input type="text" value={nameEmail} onChange={(e) => setNameEmail(e.target.value)} />
        </div>
        <label>Is Spam:</label>
        <div className="search-select">
          <Select
            options={[
              { value: 'All', label: 'All' },
              { value: 'True', label: 'True' },
              { value: 'False', label: 'False' },
            ]}
            value={isSpam}
            onChange={handleIsSpamChange}
          />
        </div>
        <label>Records:</label>
        <div className="search-records">
          <input
            type="number"
            value={records}
            onChange={(e) => setRecords(e.target.value)}
          />
        </div>
        <button onClick={fetchEnquiries}>Search</button>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>SNo.</th>
              <th>Action</th>
              <th>Contact Date</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Subject</th>
              <th>Comment</th>
              <th>IP</th>
              <th>Spam</th>
            </tr>
          </thead>
          <tbody>
            {enquiries.length > 0 ? (
              enquiries.map((enquiry, index) => (
                <tr key={enquiry.id}>
                  <td>{enquiry.id}</td>
                  <td>{index + 1}</td>
                  <td>
                    <button className="manage">Manage</button>
                    <button className="send-info">Send Info</button>
                  </td>
                  <td>{new Date(enquiry.contact_date).toLocaleDateString('en-GB')}</td>
                  <td>{enquiry.name}</td>
                  <td>{enquiry.phone}</td>
                  <td>{enquiry.email}</td>
                  <td>{enquiry.subject}</td>
                  <td>{enquiry.comment}</td>
                  <td>{enquiry.ip}</td>
                  <td>{enquiry.is_spam.toString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11">No enquiries found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactEnquiry;
