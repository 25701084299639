import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Ensure axiosInstance is configured
import './UpdateSpecialInstructions.css';

const UpdateSpecialInstructions = () => {
    const [instructions, setInstructions] = useState([]);
    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const [testName, setTestName] = useState('');
    const [specialInstructions, setSpecialInstructions] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch all special instructions
        axiosInstance.get('/api/api/special-instructions/')
            .then(response => setInstructions(response.data))
            .catch(error => console.error('Error fetching special instructions:', error));
    }, []);

    const handleSelectInstruction = (instruction) => {
        setSelectedInstruction(instruction);
        setTestName(instruction.test_name);
        setSpecialInstructions(instruction.special_instructions);
    };

    const validateForm = () => {
        let formErrors = {};
        if (!testName) formErrors.testName = 'Test Name is required';
        if (!specialInstructions) formErrors.specialInstructions = 'Special Instructions are required';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const data = { test_name: testName, special_instructions: specialInstructions };

        try {
            const response = await axiosInstance.put(`/api/api/special-instructions/${selectedInstruction.id}/`, data);
            console.log('Response:', response.data);
            navigate('/Packages/SpecialInstructions'); // Redirect after successful update
        } catch (error) {
            console.error('Error updating special instructions:', error);
        }
    };

    return (
        <div className="update-special-instructions-container">
            <div className="update-special-instructions-header">
                <h2>Update Special Instructions</h2>
                <Link to="/Packages/SpecialInstructions">
                    <button className="update-special-instructions-back-button">Back To Menu</button>
                </Link>
            </div>

            <div className="update-special-instructions-list-container">
                <h3>Select Special Instructions to Edit</h3>
                <table className="special-instructions-table">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Test</th>
                            <th>Special Instructions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {instructions.map(instruction => (
                            <tr key={instruction.id}>
                                <td>
                                    <button
                                        className="select-button"
                                        onClick={() => handleSelectInstruction(instruction)}
                                    >
                                        Select
                                    </button>
                                </td>
                                <td>{instruction.test_name}</td>
                                <td>{instruction.special_instructions}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedInstruction && (
                <div className="update-special-instructions-form-container">
                    <h3>Edit Special Instructions for {selectedInstruction.test_name}</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="update-special-instructions-form-group">
                            <label htmlFor="testName">Test Name:</label>
                            <input
                                type="text"
                                id="testName"
                                value={testName}
                                onChange={(e) => setTestName(e.target.value)}
                            />
                            {errors.testName && <p className="error-message">{errors.testName}</p>}
                        </div>
                        <div className="update-special-instructions-form-group">
                            <label htmlFor="specialInstructions">Special Instructions:</label>
                            <textarea
                                id="specialInstructions"
                                value={specialInstructions}
                                onChange={(e) => setSpecialInstructions(e.target.value)}
                            />
                            {errors.specialInstructions && <p className="error-message">{errors.specialInstructions}</p>}
                        </div>
                        <button type="submit" className="update-special-instructions-submit-button">Update</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default UpdateSpecialInstructions;
