import React, { useState } from 'react';
import axiosInstance from '../../../axiosInstance';
import './AddHolidays.css';
import { Link, useNavigate } from 'react-router-dom';

const AddHolidays = () => {
    const [holidayName, setHolidayName] = useState('');
    const [holidayDate, setHolidayDate] = useState('');
    const [isShow, setIsShow] = useState(true);
    const [isBookUpto, setIsBookUpto] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', holidayName);
        formData.append('date', holidayDate);
        formData.append('is_show', isShow ? 'Yes' : 'No');
        formData.append('is_book_upto', isBookUpto ? 'Yes' : 'No');

        try {
            const response = await axiosInstance.post('/api/api/holidays/', formData);
            alert('Holiday Added Successfully!');
            navigate('/master/Holidays');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message);
            } else {
                console.log(error);
                alert('Failed to add holiday');
            }
        }
    };

    return (
        <div className="add-holiday-form">
            <h2>Add New Holiday</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Holiday Name:</label>
                    <input type="text" value={holidayName} onChange={(e) => setHolidayName(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label>Holiday Date:</label>
                    <input type="date" value={holidayDate} onChange={(e) => setHolidayDate(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label>Is Show:</label>
                    <select value={isShow ? 'Yes' : 'No'} onChange={(e) => setIsShow(e.target.value === 'Yes')}>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Is Book Upto:</label>
                    <select value={isBookUpto ? 'Yes' : 'No'} onChange={(e) => setIsBookUpto(e.target.value === 'Yes')}>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <button type="submit">Submit</button>
            </form>
            <Link to="/master/Holidays">
                <button className="back-button">Back to Menu</button>
            </Link>
        </div>
    );
};

export default AddHolidays;
