import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import axiosInstance
import './AddPackages.css';

const AddPackages = () => {
    const [packageName, setPackageName] = useState('');
    const [packageDetail, setPackageDetail] = useState('');
    const [packageRate, setPackageRate] = useState(0);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [tests, setTests] = useState([]);
    const [selectedTests, setSelectedTests] = useState([]);
    const [notes, setNotes] = useState([{ content: '' }]);
    const [searchQuery, setSearchQuery] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchTests = async () => {
            try {
                const response = await axiosInstance.get('/api/api/tests/'); // Use axiosInstance
                setTests(response.data);
            } catch (error) {
                console.error('Error fetching tests:', error);
            }
        };

        fetchTests();
    }, []);

    const resetFormFields = () => {
        setPackageName('');
        setPackageDetail('');
        setPackageRate(0);
        setMetaTitle('');
        setMetaKeywords('');
        setMetaDescription('');
        setSelectedTests([]);
        setNotes([{ content: '' }]);
        setSearchQuery('');
        setErrors({});
    };

    const handleTestSelection = (testId) => {
        setSelectedTests((prevSelectedTests) =>
            prevSelectedTests.includes(testId)
                ? prevSelectedTests.filter((id) => id !== testId)
                : [...prevSelectedTests, testId]
        );
    };

    const handleNoteChange = (index, value) => {
        const newNotes = [...notes];
        newNotes[index].content = value;
        setNotes(newNotes);
    };

    const handleAddNote = () => {
        setNotes([...notes, { content: '' }]);
    };

    const handleRemoveNote = (index) => {
        const newNotes = [...notes];
        newNotes.splice(index, 1);
        setNotes(newNotes);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!packageName) newErrors.packageName = 'Package Name is required';
        if (!packageDetail) newErrors.packageDetail = 'Package Detail is required';
        if (!packageRate || packageRate <= 0) newErrors.packageRate = 'Package Rate must be greater than zero';
        if (selectedTests.length === 0) newErrors.selectedTests = 'At least one test must be selected';
        notes.forEach((note, index) => {
            if (!note.content.trim()) {
                newErrors[`note-${index}`] = 'Note content cannot be empty';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
    
        const selectedTestNames = selectedTests.map(testId => {
            const test = tests.find(t => t.id === testId);
            return test ? test.name : null;
        }).filter(name => name);
    
        const newPackage = {
            package_name: packageName,
            package_detail: packageDetail,
            package_rate: packageRate,
            meta_title: metaTitle,
            meta_keywords: metaKeywords,
            meta_description: metaDescription,
            tests: selectedTestNames,
            notes: notes.filter(note => note.content.trim() !== '').map(note => note.content), // Send content of notes
        };
    
        try {
            const response = await axiosInstance.post('/api/api/packages/', newPackage); // Use axiosInstance
            console.log('Package created successfully:', response.data);
            resetFormFields();
        } catch (error) {
            console.error('Error creating package:', error.response ? error.response.data : error);
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            }
        }
    };

    const filteredTests = tests.filter(test =>
        test.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="add-packages">
            <a href="/back-to-menu" className="back-button">Back To Menu</a>
            <h1>Health Package Master</h1>
            <h2>Add New Package</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Package Name:</label>
                    <input
                        type="text"
                        value={packageName}
                        onChange={(e) => setPackageName(e.target.value)}
                        required
                    />
                    {errors.packageName && <p className="error">{errors.packageName}</p>}
                </div>
                <div className="form-group">
                    <label>Package Detail:</label>
                    <textarea
                        value={packageDetail}
                        onChange={(e) => setPackageDetail(e.target.value)}
                        required
                    ></textarea>
                    {errors.packageDetail && <p className="error">{errors.packageDetail}</p>}
                </div>
                <div className="form-group">
                    <label>Package Rate:</label>
                    <input
                        type="number"
                        value={packageRate}
                        onChange={(e) => setPackageRate(e.target.value)}
                        required
                    />
                    {errors.packageRate && <p className="error">{errors.packageRate}</p>}
                </div>
                <div className="form-group">
                    <label>Meta Title:</label>
                    <input
                        type="text"
                        value={metaTitle}
                        onChange={(e) => setMetaTitle(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Meta Keywords:</label>
                    <input
                        type="text"
                        value={metaKeywords}
                        onChange={(e) => setMetaKeywords(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Meta Description:</label>
                    <textarea
                        value={metaDescription}
                        onChange={(e) => setMetaDescription(e.target.value)}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Search Tests:</label>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search for tests"
                    />
                </div>
                <div className="form-group">
                    <label>Select Tests:</label>
                    <div className="tests-list">
                        {filteredTests.map((test) => (
                            <label key={test.id} className="test-item">
                                <input
                                    type="checkbox"
                                    id={`test-${test.id}`}
                                    checked={selectedTests.includes(test.id)}
                                    onChange={() => handleTestSelection(test.id)}
                                />
                                <span>{test.name}</span>
                            </label>
                        ))}
                    </div>
                    {errors.selectedTests && <p className="error">{errors.selectedTests}</p>}
                </div>
                <div className="form-group">
                    <label>Notes:</label>
                    {notes.map((note, index) => (
                        <div key={index} className="note-item">
                            <input
                                type="text"
                                value={note.content}
                                onChange={(e) => handleNoteChange(index, e.target.value)}
                                placeholder={`Note ${index + 1}`}
                                required
                            />
                            <button type="button" className="remove-note-button" onClick={() => handleRemoveNote(index)}>Remove</button>
                            {errors[`note-${index}`] && <p className="error">{errors[`note-${index}`]}</p>}
                        </div>
                    ))}
                    <button type="button" className="add-note-button" onClick={handleAddNote}>Add Note</button>
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
};

export default AddPackages;
