
import React from 'react';
import { Link } from 'react-router-dom';




const SpecialInstructions = () => {
    return(
        <>
            <div>

<div className='UserButtons'>
  <div className='UserButtonsHeading'>
<h1>Special Instructions Details</h1>
</div>
<div className='UserButtonsAddbuttonUpdateButton'>
<div className='UserButtonAddBotton'>
<span className='UserSpanButton1'>
<Link to="/Packages/AddSpecialInstructions">
  <button>Add</button>
</Link>
</span >
<span className='UserSpanButton2'>
<Link to="/Packages/UpdateSpecialInstructions">
  <button>Update</button>
</Link>
</span>
<span className='UserSpanButton2' id='UserSpanButton22'>
<Link to="/Packages/DeleteSpecialInstructions">
  <button>Delete</button>
</Link>
</span>
</div>
</div>
</div>
</div>
        </>
    )
};
export default SpecialInstructions;