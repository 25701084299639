import React, { useState, useEffect, useRef } from 'react';
import './ColumnNavbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  faHome,
  faFileAlt,
  faAngleDown,
  faAngleRight,
  faUsers,
  faNewspaper,
  faBars, // Hamburger icon
  faTimes, // Close icon for mobile
} from '@fortawesome/free-solid-svg-icons';

function ColumnNavbar() {
  const userRole = localStorage.getItem('user_role');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navbarRef = useRef(null); // Reference for detecting outside clicks

  const [menuItems, setMenuItems] = useState([
    {
      label: 'Home',
      icon: faHome,
      link: '/home',
      hasDropdown: false,
      roles: ['admin', 'manager', 'employee', 'phlebotomist'], // Allowed roles
    },
    {
      label: 'Master',
      icon: faFileAlt,
      hasDropdown: true,
      roles: ['admin', 'manager'], // Allowed roles
      options: [
        {
          label: 'Holidays',
          link: '/master/Holidays',
          roles: ['admin', 'manager'],
        },
        {
          label: 'Time-Slot',
          link: '/master/TimeSlots',
          roles: ['admin', 'manager'],
        },
      ],
      isOpen: false,
    },
    {
      label: 'Reports',
      icon: faFileAlt,
      hasDropdown: true,
      roles: ['admin', 'manager', 'employee', 'phlebotomist','hr','qa'], // Allowed roles
      options: [
        {
          label: 'Manage Home Collection',
          link: '/Reports/EstimateForAddBooking',
          roles: ['admin', 'manager', 'employee'],
        },
        {
          label: 'Add Booking',
          link: '/report/addbooking',
          roles: ['admin', 'manager', 'employee', 'phlebotomist'],
        },
        {
          label: 'Bookings',
          link: '/report/Bookings',
          roles: ['admin', 'manager', 'employee', 'phlebotomist'],
        },
        {
          label: 'Contact Enquiry',
          link: '/report/ContactEnquiry',
          roles: ['admin', 'manager'],
        },
        {
          label: 'Career Enquiry',
          link: '/report/CareerEnquiry',
          roles: ['admin', 'manager','hr'],
        },

        {
          label: 'Feedback',
          link: '/report/FeedbackEnquiry',
          roles: ['admin', 'manager','qa'],
        },
        {
          label: 'Estimate',
          link: '/Reports/Estimate',
          roles: ['admin', 'manager', 'phlebotomist'],
        },
      ],
      isOpen: false,
    },
    {
      label: 'Tests/Packages',
      icon: faFileAlt,
      hasDropdown: true,
      roles: ['admin', 'manager', 'employee', 'phlebotomist'], // Allowed roles
      options: [
        {
          label: 'Health Packages',
          link: '/Packages/AddPackages',
          roles: ['admin', 'manager', 'employee', 'phlebotomist'],
        },
        {
          label: 'Add Tests',
          link: '/Packages/AddTests',
          roles: ['admin', 'manager', 'employee', 'phlebotomist'],
        },
   
        {
          label: 'Special Instructions',
          link: '/Packages/SpecialInstructions',
          roles: ['admin', 'manager'],
        },
      ],
      isOpen: false,
    },
    {
      label: 'User',
      icon: faUsers,
      hasDropdown: true,
      roles: ['admin'], // Allowed roles
      options: [
        {
          label: 'User',
          link: '/User',
          roles: ['admin'],
        },
      ],
      isOpen: false,
    },
    {
      label: 'News',
      icon: faNewspaper,
      hasDropdown: true,
      roles: ['admin'], // Allowed roles
      options: [
        {
          label: 'News',
          link: '/News/News',
          roles: ['admin', 'manager'],
        },
        {
          label: 'Blogs',
          link: '/News/Blogs',
          roles: ['admin', 'manager', 'employee', 'phlebotomist'],
        },
        {
          label: 'Articles',
          link: '/News/ArticleCRM',
          roles: ['admin', 'manager'],
        },
      ],
      isOpen: false,
    },
  ]);

  const toggleDropdown = (index) => {
    setMenuItems((prevItems) =>
      prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, isOpen: !item.isOpen };
        }
        return { ...item, isOpen: false };
      })
    );
  };

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close the mobile menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <div className="column-navbar-container">
      {/* Hamburger menu for mobile */}
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
      </div>

      {/* Navbar items */}
      <div className={`column-navbar ${isMobileMenuOpen ? 'show' : ''}`} ref={navbarRef}>
        {menuItems.map((menuItem, index) => {
          // Check if the user role is allowed to see this menu item
          if (!menuItem.roles.includes(userRole)) {
            return null;
          }

          return (
            <div key={index} className="nav-item">
              {menuItem.hasDropdown ? (
                <>
                  <div
                    className="menu-item-header"
                    onClick={() => toggleDropdown(index)}
                  >
                    <span className='IconeInCOlumnNavbarHel'>
                    <FontAwesomeIcon icon={menuItem.icon} />
                    </span>
                    <span className="menu-item-label">{menuItem.label}</span>
                    <FontAwesomeIcon
                      icon={menuItem.isOpen ? faAngleDown : faAngleRight}
                      className="dropdown-icon"
                    />
                  </div>
                  {menuItem.isOpen && (
                    <div className="dropdown">
                      {menuItem.options.map((option, optionIndex) => {
                        // Check if the user role is allowed to see this dropdown option
                        if (!option.roles.includes(userRole)) {
                          return null;
                        }

                        return (
                          <Link
                            key={optionIndex}
                            to={option.link}
                            className="dropdown-item-link"
                            onClick={toggleMobileMenu} // Close mobile menu after clicking a link
                          >
                            <div className="dropdown-item">{option.label}</div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </>
              ) : (
                <Link
                  to={menuItem.link}
                  className="menu-item-link"
                  onClick={toggleMobileMenu} // Close mobile menu after clicking a link
                >
                  <div className="menu-item-header">
                    <FontAwesomeIcon icon={menuItem.icon} />
                    <span className="menu-item-label">{menuItem.label}</span>
                  </div>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ColumnNavbar;
