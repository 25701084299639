
import React from 'react';
import { Link } from 'react-router-dom';




const DeleteTimeSlots = () => {
    return(
        <>
            <div>


                
            </div>
            </>
    )
}
export default DeleteTimeSlots;