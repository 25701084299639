// src/components/Reports/AddBooking/BookingForm.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import axiosInstance from '../../../axiosInstance'; // Ensure this is correctly configured
import axios from 'axios'; // Import default axios for LoginAPI
import './BookingForm.css'; // Ensure all necessary CSS is in place
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

// Define genderOptions and stateOptions outside the component to prevent re-declaration
const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

const stateOptions = [
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'Delhi [National Capital Territory (NCT)]', label: 'Delhi [National Capital Territory (NCT)]' },
  // Add more states as needed
];

const BookingForm = () => {
  // Initialize form data state
  const [formData, setFormData] = useState({
    mobile_number: '',
    address: '',
    date_for_collection: null,
    preferred_time: '',
    test_package_name: [], // This will store selected test packages with details
    patient_name: '',
    patient_email: '',
    whatsapp_number: '',
    age: '',
    gender: null,
    city: '',
    pincode: '',
    state: '',
    prescription: null,
    remark: '',
  });

  // Additional states
  const [testPackageOptions, setTestPackageOptions] = useState([]);
  const [selectedTestPackages, setSelectedTestPackages] = useState([]);
  const [selectedItemsTable, setSelectedItemsTable] = useState([]);
  const [totalRate, setTotalRate] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [errors, setErrors] = useState({});
  const [username, setUsername] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uniqueID, setUniqueID] = useState(null); // Use uniqueID instead of bookingId
  const [isLoadingTestPackages, setIsLoadingTestPackages] = useState(false); // Loading state for test packages
  const [isLoadingBookingData, setIsLoadingBookingData] = useState(false); // Loading state for booking data

  // New state variables for patient fetching
  const [patientsList, setPatientsList] = useState([]);
  const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);

  // State variables for UAT API token management
  const [uatToken, setUatToken] = useState(null);
  const [tokenExpiry, setTokenExpiry] = useState(null);
  const isFetchingToken = useRef(false);

  // New state variable for estimate data
  const [estimateData, setEstimateData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  // ======= Helper Functions =======

  /**
   * Validate Mobile Number
   * @param {string} value - The mobile number to validate
   * @returns {string} - Error message or empty string
   */
  const validateMobileNumber = (value) => {
    if (!value) {
      return 'Mobile number is required.';
    }
    if (!/^\d{10}$/.test(value)) {
      return 'Mobile number should be a 10-digit number.';
    }
    return '';
  };

  /**
   * Validate WhatsApp Number
   * @param {string} value - The WhatsApp number to validate
   * @returns {string} - Error message or empty string
   */
  const validateWhatsAppNumber = (value) => {
    if (value && !/^\d{10}$/.test(value)) {
      return 'WhatsApp number should be a 10-digit number.';
    }
    return '';
  };

  /**
   * Validate Preferred Time
   * @param {string} value - The preferred time to validate
   * @returns {string} - Error message or empty string
   */
  const validatePreferredTime = (value) => {
    if (!value) {
      return 'Preferred time is required.';
    }
    return '';
  };

  /**
   * Validate Gender
   * @param {object} value - The gender option to validate
   * @returns {string} - Error message or empty string
   */
  const validateGender = (value) => {
    if (!value) {
      return 'Gender is required.';
    }
    return '';
  };

  /**
   * Validate Age
   * @param {string} value - The age to validate
   * @returns {string} - Error message or empty string
   */
  const validateAge = useCallback((value) => {
    if (!value) {
      return 'Age is required.';
    }
    if (isNaN(value) || parseInt(value) < 0 || parseInt(value) > 110) {
      return 'Age should be a number between 0 and 110.';
    }
    return '';
  }, []);

  /**
   * Validate Prescription File
   * @param {File} file - The file to validate
   * @returns {string} - Error message or empty string
   */
  const validatePrescription = useCallback((file) => {
    const validTypes = ['image/jpeg', 'image/png', 'application/pdf', 'image/gif'];
    if (file && !validTypes.includes(file.type)) {
      return 'Unsupported file type. Only JPG, PNG, GIF, and PDF are allowed.';
    }
    return '';
  }, []);

  // ======= Token Management Functions =======

  /**
   * Fetch Token from UAT Login API
   */
  const fetchUatToken = useCallback(async () => {
    // Prevent multiple fetches
    if (isFetchingToken.current) {
      console.log('Token fetch already in progress.');
      return;
    }
    isFetchingToken.current = true;

    const loginUrl = 'https://lims.drdangslab.com/homecollectionapi/API/LoginAPI';
    const formData = new FormData();
    formData.append('UserName', 'dangswebprod'); // Correct key with capital "N"
    formData.append('Password', 'FGDTR-UVSDR-PLQWS'); // Correct key
    formData.append('Client', 'WebSite'); // Correct key

    // Log FormData to verify correct keys and values
    console.log('Sending LoginAPI request with the following FormData:');
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      // **Use default axios without Authorization header**
      const response = await axios.post(loginUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('LoginAPI Response:', response.data); // Log the entire response

      const data = response.data;

      if (data.status && data.data && data.data.length > 0) {
        let { Token, TokenExpiry } = data.data[0];

        // **Sanitize the Token**
        Token = Token.replace(/\s/g, ''); // Remove all whitespace characters
        console.log('Sanitized Token:', Token);

        setUatToken(Token);

        // Log the received token and its expiry
        console.log('Token Expiry:', TokenExpiry);

        // Convert TokenExpiry to timestamp for easy comparison
        const expiryDate = new Date(TokenExpiry).getTime();
        setTokenExpiry(expiryDate);

        // Optionally, store the token in localStorage for persistence
        localStorage.setItem('uatToken', Token);
        localStorage.setItem('tokenExpiry', expiryDate);
      } else {
        throw new Error('Failed to fetch token. Response structure unexpected.');
      }
    } catch (error) {
      console.error('Error fetching UAT token:', error.response ? error.response.data : error.message);
      alert('Unable to authenticate with the UAT API. Please try again later.');
    } finally {
      isFetchingToken.current = false;
    }
  }, []);

  /**
   * Check if the current token is valid
   */
  const isTokenValid = useCallback(() => {
    if (!uatToken || !tokenExpiry) return false;
    const currentTime = new Date().getTime();
    // Consider a buffer (e.g., 1 minute) to account for clock differences
    return currentTime + 60000 < tokenExpiry;
  }, [uatToken, tokenExpiry]);

  // ======= Data Fetching Functions =======

  /**
   * Fetch Patients by Mobile Number using Third-Party API with UAT Token
   */
  const fetchPatientsByMobileNumber = useCallback(async () => {
    const { mobile_number } = formData;

    // Validate mobile number before making the API call
    const mobileError = validateMobileNumber(mobile_number);
    if (mobileError) {
      setErrors((prevErrors) => ({ ...prevErrors, mobile_number: mobileError }));
      return;
    }

    try {
      // Check if token is valid; if not, fetch a new one
      if (!isTokenValid()) {
        console.log('Token is invalid or expired. Fetching a new token.');
        await fetchUatToken();
        if (!isTokenValid()) {
          throw new Error('Unable to obtain a valid UAT token.');
        }
      }

      // Retrieve the latest token
      const token = uatToken || localStorage.getItem('uatToken');
      console.log('Using Token for GetPatientDetailByMobileNo:', token);

      // Prepare FormData payload
      const formDataRequest = new FormData();
      formDataRequest.append('MobileNo', mobile_number); // Ensure correct casing

      console.log('Sending GetPatientDetailByMobileNo request with FormData:');
      for (let pair of formDataRequest.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const response = await axiosInstance.post(
        'https://lims.drdangslab.com/homecollectionapi/API/HomeAPI/GetPatientDetailByMobileNo',
        formDataRequest, // Use FormData
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Match Postman's setting
            'Authorization': `Bearer ${token}`, // Use the sanitized token
          },
        }
      );

      console.log('GetPatientDetailByMobileNo Response:', response.data); // Log the entire response

      if (response.data.status && response.data.data.length > 0) {
        setPatientsList(response.data.data);
        setIsPatientModalOpen(true);
      } else {
        alert('No patients found for the provided mobile number.');
      }
    } catch (error) {
      console.error('Error fetching patients:', error.response ? error.response.data : error.message);
      alert('An error occurred while fetching patient details.');
    }
  }, [formData, validateMobileNumber, isTokenValid, fetchUatToken, uatToken, axiosInstance]);

  /**
   * Handle Enter Key Press to Fetch Patients
   */
  const handleEnterPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchPatientsByMobileNumber();
    }
  }, [fetchPatientsByMobileNumber]);

  /**
   * Handle Selecting a Patient from the Modal
   * @param {object} patient - The selected patient object
   */
  const selectPatient = useCallback((patient) => {
    // Helper function to parse the Age string and extract years
    const parseAge = (ageStr) => {
      const match = ageStr.match(/(\d+)\s*Y/);
      return match ? parseInt(match[1], 10) : '';
    };

    // Extract the number of years from the Age string
    const age = parseAge(patient.Age);

    // Combine Address and Locality into a single address string
    const address = [patient.Address, patient.Locality].filter(Boolean).join(', ');

    // Function to map API state to stateOptions
    const mapState = (apiState) => {
      const stateMap = {
        'DELHI': 'Delhi [National Capital Territory (NCT)]',
        // Add other mappings if necessary
        // 'HARYANA': 'Haryana',
        // 'UTTAR PRADESH': 'Uttar Pradesh',
        // etc.
      };

      // Get the mapped state value; default to the original if not found
      const mappedStateValue = stateMap[apiState.toUpperCase()] || apiState;

      // Find the corresponding state option
      const stateOption = stateOptions.find(
        (option) => option.value.toLowerCase() === mappedStateValue.toLowerCase()
      );

      // If a matching option is found, return it; otherwise, create a new option
      return stateOption || { value: mappedStateValue, label: mappedStateValue };
    };

    // Map the API state to the corresponding state option
    const state = mapState(patient.State);

    // Map the API gender to the corresponding gender option
    const gender = genderOptions.find(
      (option) => option.value.toLowerCase() === patient.Gender.toLowerCase()
    ) || null;

    // Update the form data with the selected patient's details
    setFormData((prevFormData) => ({
      ...prevFormData,
      patient_name: patient.PatientName || '',
      patient_email: patient.Email || '',
      whatsapp_number: patient.Mobile || '',
      address: address || '',
      city: patient.City || '',
      pincode: patient.PinCode !== 0 ? patient.PinCode : '', // Handle PinCode = 0
      gender: gender,
      age: age,
      state: state,
      // Add more fields as necessary
    }));

    // Update the selected state option for the state dropdown
    setSelectedStateOption(state);

    // Close the patient selection modal
    setIsPatientModalOpen(false);
  }, [genderOptions, stateOptions]);

  /**
   * Fetch Estimate Data by Unique ID
   * @param {string} unique_id - The Unique_id to fetch estimate for
   */
  const fetchEstimateData = useCallback(async (unique_id) => {
    try {
      const response = await axiosInstance.get(`/api/api/estimate/${unique_id}/`);
      if (response.data) {
        setEstimateData(response.data);
        // Assuming response.data has discount_percentage, discount_amount, final_amount
        setDiscountPercentage(parseFloat(response.data.discount_percentage) || 0);
        setDiscountAmount(parseFloat(response.data.discount_amount) || 0);
        setFinalAmount(parseFloat(response.data.final_amount) || 0);
      } else {
        console.warn('No estimate data found for this booking.');
      }
    } catch (error) {
      console.error('Error fetching estimate:', error.response ? error.response.data : error.message);
      // Optionally, handle cases where no estimate exists
    }
  }, [axiosInstance]);

  /**
   * Fetch Data for Unique ID (for Update Mode)
   * @param {string} fetchedUniqueId - The Unique_id to fetch data for
   */
  const fetchDataForUniqueID = useCallback(async (fetchedUniqueId) => {
    setIsLoadingBookingData(true); // Start loading
    const apiUrl = `/api/api/bookings/update/${fetchedUniqueId}/`;

    try {
      const response = await axiosInstance.get(apiUrl);
      const data = response.data;

      setFormData({
        mobile_number: data.mobile_number,
        address: data.address,
        date_for_collection: data.date_for_collection ? new Date(data.date_for_collection) : null,
        preferred_time: data.preferred_time,
        test_package_name: [], // Will be populated below
        patient_name: data.patient_name,
        patient_email: data.patient_email,
        whatsapp_number: data.whatsapp_number,
        age: data.age,
        gender: genderOptions.find(option => option.value === data.gender) || null,
        city: data.city,
        pincode: data.pincode !== 0 ? data.pincode : '', // Handle PinCode = 0
        state: { value: data.state, label: data.state },
        prescription: data.prescription || null,
        remark: data.remark,
      });

      // Enhanced Parsing: Split by commas only before new itemcode--
      const testPackageStrings = data.test_package_name.split(/,(?=[A-Z0-9]+--)/).map(str => str.trim());

      const selectedPackages = testPackageStrings.map(str => {
        const [itemcode, ...testnameArr] = str.split('--');
        const testname = testnameArr.join('--').trim(); // In case testname contains '--'

        const correspondingOption = testPackageOptions.find(opt => 
          opt.itemcode === itemcode.trim() && opt.testname.toLowerCase() === testname.toLowerCase()
        );

        return correspondingOption
          ? correspondingOption
          : {
              // If the test package is not found in testPackageOptions, create a new option
              value: itemcode.trim(),
              label: `${itemcode.trim()} - ${testname}`,
              itemcode: itemcode.trim(),
              testname: testname,
              rate: 0, // Default rate; adjust as necessary
              ItemType: '', // Default or fetch if available
              gender: 'B', // Default to both genders; adjust as necessary
            };
      }).filter(pkg => pkg !== null);

      setSelectedTestPackages(selectedPackages);
      setSelectedItemsTable(selectedPackages);
      setSelectedStateOption({ value: data.state, label: data.state });

      // Fetch the existing estimate data
      await fetchEstimateData(fetchedUniqueId);
    } catch (error) {
      console.error('Error fetching data:', error.response ? error.response.data : error.message);
      alert('An error occurred while fetching booking details.');
    } finally {
      setIsLoadingBookingData(false); // End loading
    }
  }, [testPackageOptions, genderOptions, axiosInstance, fetchEstimateData]);

  // ======= useEffect Hooks =======

  /**
   * Fetch Unique ID from URL and Data if Present (for Update Mode)
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const fetchedUniqueId = params.get('Unique_id');
    if (fetchedUniqueId) {
      setUniqueID(fetchedUniqueId);
      setIsUpdateMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   * Fetch Test Package Options on Component Mount
   */
  useEffect(() => {
    const fetchTestPackages = async () => {
      setIsLoadingTestPackages(true);
      try {
        const response = await axiosInstance.get('/api/api/updated-estimaterate/');
        const options = response.data.map((item) => ({
          label: `${item.itemcode} - ${item.itemname}`,
          value: item.id, // assuming 'id' is unique identifier
          itemcode: item.itemcode,
          testname: item.itemname, // Added testname for clarity
          rate: parseFloat(item.Rate) || 0,
          ItemType: item.ItemType,
          gender: item.Gender, // assuming 'Gender' defines to which gender the package is applicable
        }));
        setTestPackageOptions(options);
      } catch (error) {
        console.error('Error fetching test package options:', error.response ? error.response.data : error.message);
        alert('Failed to load test packages. Please try again later.');
      } finally {
        setIsLoadingTestPackages(false);
      }
    };

    fetchTestPackages();
  }, [axiosInstance]);

  /**
   * Fetch Data for Booking when Both Unique ID and Test Packages are Loaded
   */
  useEffect(() => {
    if (uniqueID && testPackageOptions.length > 0) {
      fetchDataForUniqueID(uniqueID);
    }
  }, [uniqueID, testPackageOptions, fetchDataForUniqueID]);

  /**
   * Fetch Holidays and Current User on Component Mount
   */
  useEffect(() => {
    // Fetch Holidays
    const fetchHolidays = async () => {
      try {
        const response = await axiosInstance.get('/api/holidays/');
        const holidayDates = response.data.map((holiday) => new Date(holiday.date));
        setHolidays(holidayDates);
      } catch (error) {
        console.error('Error fetching holidays:', error.response ? error.response.data : error.message);
      }
    };

    // Fetch Current User
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get('/api/current-user/');
        setUsername(response.data.username);
      } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
      }
    };

    fetchHolidays();
    fetchCurrentUser();
  }, [axiosInstance]);

  /**
   * Calculate Total Rate, Discount, and Final Amount whenever selected test packages or discount changes
   */
  useEffect(() => {
    const newTotalRate = selectedItemsTable.reduce((acc, item) => acc + item.rate, 0);
    setTotalRate(newTotalRate);

    // Recalculate discount and final amount
    const newDiscountAmount = (newTotalRate * discountPercentage) / 100;
    setDiscountAmount(newDiscountAmount.toFixed(2));
    const newFinalAmount = newTotalRate - newDiscountAmount;
    setFinalAmount(newFinalAmount.toFixed(2));
  }, [selectedItemsTable, discountPercentage]);

  /**
   * useEffect to check token validity periodically (e.g., every 5 minutes)
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isTokenValid()) {
        console.log('Token is invalid or expired. Fetching a new token.');
        fetchUatToken();
      }
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(interval);
  }, [isTokenValid, fetchUatToken]);

  /**
   * Fetch token on component mount if needed
   */
  useEffect(() => {
    if (!isTokenValid()) {
      console.log('Token is invalid or not present. Fetching a new token.');
      fetchUatToken();
    }
  }, [isTokenValid, fetchUatToken]);

  // ======= Event Handlers =======

  /**
   * Handle Input Changes with Validation
   */
  const handleChange = useCallback((e) => {
    const { name, value, type } = e.target;
    let updatedErrors = { ...errors };

    // Validation for specific fields
    if (name === 'whatsapp_number' || name === 'mobile_number') {
      const isNumeric = /^\d+$/.test(value);

      if (name === 'whatsapp_number') {
        if (value && value.length !== 10) {
          updatedErrors[name] = 'WhatsApp number must be exactly 10 digits long.';
        } else if (value && !isNumeric) {
          updatedErrors[name] = 'WhatsApp number must contain only numbers.';
        } else {
          updatedErrors[name] = '';
        }
      }

      if (name === 'mobile_number') {
        if (value.length !== 10) {
          updatedErrors[name] = 'Mobile number must be exactly 10 digits long.';
        } else if (!isNumeric) {
          updatedErrors[name] = 'Mobile number must contain only numbers.';
        } else {
          updatedErrors[name] = '';
        }
      }
    } else if (name === 'age') {
      if (isNaN(value) || parseInt(value) < 0 || parseInt(value) > 110) {
        updatedErrors[name] = 'Age should be a number between 0 and 110.';
      } else {
        updatedErrors[name] = '';
      }
    } else if (name === 'discountPercentage') {
      if (value < 0 || value > 100) {
        updatedErrors[name] = 'Discount must be between 0 and 100.';
      } else {
        updatedErrors[name] = '';
      }

      // Recalculate discount and final amount
      const newDiscountPercentage = parseFloat(value) || 0;
      const newDiscountAmount = (totalRate * newDiscountPercentage) / 100;
      setDiscountAmount(newDiscountAmount.toFixed(2));
      const newFinalAmount = totalRate - newDiscountAmount;
      setFinalAmount(newFinalAmount.toFixed(2));
    }

    // Handle file input
    if (type === 'file') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors(updatedErrors);
  }, [errors, totalRate]);

  /**
   * Handle File Change with Validation and Scanning
   */
  const handleFileChange = useCallback(async (e) => {
    const file = e.target.files[0];
    const fileError = validatePrescription(file);

    if (fileError) {
      setErrors((prevErrors) => ({ ...prevErrors, prescription: fileError }));
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('file', file);

      const response = await axiosInstance.post('/api/api/file-scan/', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === 'safe') {
        setFormData((prevFormData) => ({ ...prevFormData, prescription: file }));
        setErrors((prevErrors) => ({ ...prevErrors, prescription: '' }));
        console.log('File scanned and is safe to upload.');
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, prescription: 'The file is potentially harmful and cannot be uploaded.' }));
        console.warn('Uploaded file failed the safety scan.');
      }
    } catch (error) {
      console.error('Error scanning the file:', error.response ? error.response.data : error.message);
      setErrors((prevErrors) => ({ ...prevErrors, prescription: 'Error scanning the file. Please try again later.' }));
    }
  }, [validatePrescription]);

  /**
   * Handle Gender Change
   */
  const handleGenderChange = useCallback((selectedGender) => {
    setFormData((prevFormData) => ({ ...prevFormData, gender: selectedGender }));
    setSelectedTestPackages([]);
    setSelectedItemsTable([]);
  }, []);

  /**
   * Fetch Time Slots based on selected date
   */
  const fetchTimeSlots = useCallback(async (selectedDate) => {
    try {
      const currentISTTime = new Date().toLocaleTimeString('en-GB', { hour12: false, timeZone: 'Asia/Kolkata' });

      const response = await axiosInstance.get('/api/api/time-slots/', {
        params: { 
          date: selectedDate.toISOString().split('T')[0],
          current_time: currentISTTime
        },
      });

      if (response.data.time_slots) {
        setAvailableTimeSlots(response.data.time_slots);
        console.log('Available Time Slots:', response.data.time_slots);
      } else {
        setAvailableTimeSlots([]);
        alert(response.data.message || 'No time slots available.');
      }
    } catch (error) {
      console.error('Error fetching time slots:', error.response ? error.response.data : error.message);
      setAvailableTimeSlots([]);
    }
  }, [axiosInstance]);

  /**
   * Check if selected date is Sunday or Holiday
   */
  const isSundayOrHoliday = useCallback((date) => {
    const day = date.getDay();
    return day === 0 || holidays.some((holiday) => date.toDateString() === holiday.toDateString());
  }, [holidays]);

  /**
   * Handle Date Change
   */
  const handleDateChange = useCallback((date) => {
    if (!date) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        date_for_collection: null,
        preferred_time: '',
      }));
      setAvailableTimeSlots([]);
      return;
    }

    const selectedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    
    if (isSundayOrHoliday(selectedDate)) {
      alert('This date is a holiday or Sunday and cannot be selected.');
      setFormData((prevFormData) => ({
        ...prevFormData,
        date_for_collection: null,
        preferred_time: '',
      }));
      setAvailableTimeSlots([]);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        date_for_collection: selectedDate,
        preferred_time: '',
      }));

      fetchTimeSlots(selectedDate);
    }
  }, [isSundayOrHoliday, fetchTimeSlots]);

  /**
   * Handle Test Package Selection
   */
  const handleTestPackageSelection = useCallback((selectedOptions) => {
    setSelectedTestPackages(selectedOptions || []);

    const selectedItems = (selectedOptions || []).map((selectedOption) => {
      const correspondingOption = testPackageOptions.find(
        (option) => option.value === selectedOption.value
      );

      return correspondingOption
        ? {
            value: correspondingOption.value,
            label: correspondingOption.label,
            rate: correspondingOption.rate,
            itemcode: correspondingOption.itemcode,
            testname: correspondingOption.testname,
            ItemType: correspondingOption.ItemType,
          }
        : {
            value: selectedOption.value,
            label: selectedOption.label,
            rate: selectedOption.rate || 0,
            itemcode: selectedOption.itemcode || '',
            testname: selectedOption.testname || '',
            ItemType: selectedOption.ItemType || '',
          };
    });

    setSelectedItemsTable(selectedItems);
  }, [testPackageOptions]);

  // ======= Form Submission Handler =======

  /**
   * Generate Unique ID
   */
  const generateUniqueID = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/api/generateUniqueID/');

      if (response.status === 200) {
        console.log('Generated Unique ID:', response.data.Unique_id);
        return response.data.Unique_id;
      }

      console.error('Error generating unique ID:', response);
    } catch (error) {
      console.error('Error generating unique ID:', error.response ? error.response.data : error.message);
    }
  }, [axiosInstance]);

  /**
   * Handle Form Submission
   */
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      console.log('Submission already in progress');
      return; // Prevent multiple submissions
    }

    const { mobile_number, patient_name, address, date_for_collection, preferred_time, gender, age } = formData;

    // Form Validation
    const newErrors = {
      mobile_number: validateMobileNumber(mobile_number),
      patient_name: patient_name ? '' : 'Patient name is required.',
      address: address ? '' : 'Address is required.',
      date_for_collection: date_for_collection ? '' : 'Date for collection is required.',
      preferred_time: validatePreferredTime(preferred_time),
      gender: validateGender(gender),
      age: validateAge(age),
      discountPercentage: (discountPercentage >= 0 && discountPercentage <= 100) ? '' : 'Discount must be between 0 and 100.',
      whatsapp_number: formData.whatsapp_number
        ? (validateWhatsAppNumber(formData.whatsapp_number))
        : '',
      // Add more validations as needed
    };

    setErrors(newErrors);

    // Check if any errors exist
    if (Object.values(newErrors).some((error) => error)) {
      console.warn('Form contains errors. Please fix them before submitting.');
      return;
    }

    setIsSubmitting(true);
    console.log('Form submission started.');

    try {
      let currentUniqueID = uniqueID;
      if (!isUpdateMode) {
        currentUniqueID = await generateUniqueID();
        if (!currentUniqueID) {
          throw new Error('Failed to generate unique ID.');
        }
        setUniqueID(currentUniqueID); // Update the state with the new Unique_id
      }

      const formDataToSend = new FormData();

      // Append form fields
      for (const key in formData) {
        if (key === 'date_for_collection') {
          formDataToSend.append(key, formData[key].toISOString().split('T')[0]);
        } else if (key === 'gender') {
          formDataToSend.append(key, formData[key]?.value || '');
        } else if (key === 'state') {
          formDataToSend.append(key, formData[key].value || '');
        } else if (key === 'prescription' && formData[key]) {
          formDataToSend.append(key, formData[key]);
        } else if (key === 'test_package_name') {
          // We'll handle test_package_name separately below
          continue;
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }

      // Prepare selected test package data formatted as "itemcode--testname"
      const bookingTestPackages = selectedTestPackages.map((pkg) => `${pkg.itemcode}--${pkg.testname}`);
      console.log('Selected Test Packages:', bookingTestPackages);

      // Append test packages as a comma-separated string
      formDataToSend.append('test_package_name', bookingTestPackages.join(','));

      let bookingResponse;
      if (isUpdateMode) {
        console.log('Updating booking with Unique ID:', currentUniqueID);
        bookingResponse = await axiosInstance.put(`/api/api/bookings/update/${currentUniqueID}/`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        console.log('Creating new booking.');
        bookingResponse = await axiosInstance.post('/api/api/bookings/', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      console.log(isUpdateMode ? 'Booking updated:' : 'Booking created:', bookingResponse.data);

      // Extract Unique_id from response
      const bookingUniqueId = isUpdateMode ? uniqueID : bookingResponse.data.Unique_id;
      console.log('Unique_id for Estimate API:', bookingUniqueId); // Debugging

      // Prepare Estimate Data with detailed test information
      const estimateToSend = {
        booking_id: bookingUniqueId,
        tests: selectedTestPackages.map(pkg => ({
          itemcode: pkg.itemcode,
          itemname: pkg.testname, // Renamed from testname to itemname
          Rate: pkg.rate,         // Capitalized 'Rate' to match backend
        })),
        total_amount: totalRate.toFixed(2),
        discount_percentage: parseFloat(discountPercentage).toFixed(2),
        discount_amount: parseFloat(discountAmount).toFixed(2),
        final_amount: parseFloat(finalAmount).toFixed(2),
        patient_name: formData.patient_name,
        patient_age: formData.age,
        gender: formData.gender.value,
        contact_no: formData.whatsapp_number || formData.mobile_number,
      };

      console.log('Estimate Data:', estimateToSend); // For debugging

      let estimateResponse;
      if (isUpdateMode && estimateData) {
        console.log('Updating existing estimate with Unique ID:', bookingUniqueId);
        estimateResponse = await axiosInstance.put(`/api/api/estimate/${bookingUniqueId}/`, estimateToSend, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } else {
        console.log('Creating new estimate.');
        estimateResponse = await axiosInstance.post('/api/api/estimate/', estimateToSend, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      console.log(isUpdateMode ? 'Estimate updated:' : 'Estimate created:', estimateResponse.data);

      // Show the success modal
      setShowModal(true);
      console.log('Success modal displayed.');

      // Reset form data and related states
      setFormData({
        mobile_number: '',
        address: '',
        date_for_collection: null,
        preferred_time: '',
        test_package_name: [],
        patient_name: '',
        patient_email: '',
        whatsapp_number: '',
        age: '',
        gender: null,
        city: '',
        pincode: '',
        state: '',
        prescription: null,
        remark: '',
      });
      setSelectedTestPackages([]);
      setSelectedItemsTable([]);
      setTotalRate(0);
      setDiscountPercentage(0);
      setDiscountAmount(0);
      setFinalAmount(0);
      setErrors({});
      setUniqueID(null);
      setIsUpdateMode(false);
      setEstimateData(null);
      console.log('Form data and related states have been reset.');

      // Redirect after a short delay
      setTimeout(() => {
        setShowModal(false);
        navigate('/report/Bookings');
        console.log('Redirected to /report/Bookings.');
      }, 3000); // 3 seconds delay

    } catch (error) {
      console.error(isUpdateMode ? 'Error updating booking or estimate:' : 'Error creating booking or estimate:', error.response ? error.response.data : error.message);

      // Handle errors from booking or estimate API
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        setErrors({ general: 'An error occurred while processing the request.' });
      }
    } finally {
      setIsSubmitting(false);
      console.log('Form submission process completed.');
    }
  }, [
    formData,
    selectedTestPackages,
    totalRate,
    discountPercentage,
    discountAmount,
    finalAmount,
    isUpdateMode,
    generateUniqueID,
    validateMobileNumber,
    validatePreferredTime,
    validateGender,
    validateAge,
    username,
    navigate,
    uniqueID,
    estimateData,
  ]);

  // ======= Render =======

  return (
    <div className="booking-form-container">
      <h2>{isUpdateMode ? 'Update' : 'Add'} Home Collection Booking</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Patient Details Section */}
        <div className="row1">
          <div className="input-Feild">
            <label>Mobile Number*</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                name="mobile_number"
                value={formData.mobile_number}
                onChange={handleChange}
                onKeyPress={handleEnterPress}
                maxLength="10"
                required
              />
              <button type="button" onClick={fetchPatientsByMobileNumber} style={{ marginLeft: '10px' }}>
                Fetch Patients
              </button>
            </div>
            {errors.mobile_number && <p className="error-message">{errors.mobile_number}</p>}
          </div>

          <div className="input-Feild">
            <label>Patient Name*</label>
            <input
              type="text"
              name="patient_name"
              value={formData.patient_name}
              onChange={handleChange}
              required
            />
            {errors.patient_name && <p className="error-message">{errors.patient_name}</p>}
          </div>

          <div className="input-Feild">
            <label>Patient Email</label>
            <input
              type="email"
              name="patient_email"
              value={formData.patient_email}
              onChange={handleChange}
            />
          </div>

          <div className="input-Feild">
            <label>Gender*</label>
            <Select
              name="gender"
              options={genderOptions}
              value={formData.gender}
              onChange={handleGenderChange}
              isSearchable
              isClearable
              placeholder="Select Gender"
            />
            {errors.gender && <p className="error-message">{errors.gender}</p>}
          </div>
        </div>

        {/* Additional Details Section */}
        <div className="row1">
          <div className="input-Feild" id="input-Feild12">
            <label>Date for Collection*</label>
            <DatePicker
              selected={formData.date_for_collection}
              onChange={handleDateChange}
              minDate={new Date()}
              maxDate={new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)}
              filterDate={(date) => !isSundayOrHoliday(date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="Select a date"
              disabledKeyboardNavigation
              required
            />
            {errors.date_for_collection && <p className="error-message">{errors.date_for_collection}</p>}
          </div>

          <div className="input-Feild">
            <label>WhatsApp Number</label>
            <input
              type="text"
              name="whatsapp_number"
              value={formData.whatsapp_number}
              onChange={handleChange}
              maxLength="10"
            />
            {errors.whatsapp_number && <p className="error-message">{errors.whatsapp_number}</p>}
          </div>

          <div className="input-Feild">
            <label>Age*</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              min="0"
              max="110"
              required
            />
            {errors.age && <p className="error-message">{errors.age}</p>}
          </div>

          <div className="input-Feild">
            <label>Test Packages</label>
            {isLoadingTestPackages ? (
              <p>Loading test packages...</p>
            ) : (
              <Select
                isMulti
                name="test_package_name"
                options={testPackageOptions.filter((option) => {
                  if (formData.gender && formData.gender.value === 'Male') {
                    return option.gender === 'M' || option.gender === 'B';
                  } else if (formData.gender && formData.gender.value === 'Female') {
                    return option.gender === 'F' || option.gender === 'B';
                  }
                  return true;
                })}
                value={selectedTestPackages}
                onChange={handleTestPackageSelection}
                isSearchable
                placeholder="Select test packages..."
              />
            )}
            {errors.test_package_name && <p className="error-message">{errors.test_package_name}</p>}
          </div>
        </div>

        {/* Preferred Time and Location Section */}
        <div className="row1">
          <div className="input-Feild">
            <label>Preferred Time*</label>
            {formData.date_for_collection ? (
              availableTimeSlots.length > 0 ? (
                <select
                  id="time_slots"
                  name="preferred_time"
                  value={formData.preferred_time}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled hidden>
                    Select a time
                  </option>
                  {availableTimeSlots.map((timeSlot, index) => (
                    <option key={index} value={timeSlot}>
                      {timeSlot}
                    </option>
                  ))}
                </select>
              ) : (
                <p>No time slots available for this date.</p>
              )
            ) : (
              <p>Select a date to see available time slots.</p>
            )}
            {errors.preferred_time && <p className="error-message">{errors.preferred_time}</p>}
          </div>

          <div className="input-Feild">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Address and State Section */}
        <div className="row1">
          <div className="input-Feild" id="input-Feild22">
            <label>Address*</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
            {errors.address && <p className="error-message">{errors.address}</p>}
          </div>
          <div className="input-Feild">
            <label>Pincode</label>
            <input
              type="text"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              maxLength="6"
            />
          </div>
          <div className="input-Feild">
            <label>State*</label>
            <Select
              name="state"
              options={stateOptions}
              value={selectedStateOption}
              onChange={(option) => {
                setSelectedStateOption(option);
                setFormData((prevFormData) => ({ ...prevFormData, state: option ? option.value : '' }));
              }}
              isSearchable
              isClearable
              placeholder="Select State"
              required
            />
            {errors.state && <p className="error-message">{errors.state}</p>}
          </div>
        </div>

        {/* Prescription Upload */}
        <div className="input-Feild">
          <label>Upload Prescription: (supported file types are .jpg, .jpeg, .png, .pdf, .gif)</label>
          <input
            type="file"
            name="prescription"
            accept=".jpg, .jpeg, .png, .pdf, .gif"
            onChange={handleFileChange}
          />
          {errors.prescription && <p className="error-message">{errors.prescription}</p>}
        </div>

        {/* Remark Section */}
        <div className="input-Feild" id="inputtextarea">
          <label>Remark</label>
          <textarea
            name="remark"
            value={formData.remark}
            onChange={handleChange}
          ></textarea>
        </div>

        {/* Discount Section */}
        <div className="row1">
          <div className="input-Feild">
            <label>Discount (%)</label>
            <input
              type="number"
              name="discountPercentage"
              value={discountPercentage}
              onChange={(e) => {
                const value = e.target.value;
                setDiscountPercentage(value);
                handleChange(e); // To handle validation and calculations
              }}
              min="0"
              max="100"
              step="0.01"
            />
            {errors.discountPercentage && <p className="error-message">{errors.discountPercentage}</p>}
          </div>
        </div>

        {/* Estimate Summary Section */}
        <div className="estimate-section">
          <h3>Estimate Summary</h3>
          <table>
            <thead>
              <tr>
                <th>ItemCode</th>
                <th>ItemName</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {selectedItemsTable.map((selectedItem) => (
                <tr key={selectedItem.value}>
                  <td>{selectedItem.itemcode}</td>
                  <td>{selectedItem.testname}</td>
                  <td>{selectedItem.rate.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
                <td>{totalRate.toFixed(2)}</td>
              </tr>
              <tr>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>Discount ({discountPercentage}%):</td>
                <td>{discountAmount}</td>
              </tr>
              <tr>
                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>Final Amount:</td>
                <td>{finalAmount}</td>
              </tr>
            </tfoot>
          </table>
        </div>

        {/* Submit Button */}
        <div className="AddButtonInAddBooking">
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Processing...' : (isUpdateMode ? 'Update' : 'Add')}
          </button>
        </div>
      </form>

      {/* Optional: Remove EstimateONBooking component if it's not needed */}
      {/* <EstimateONBooking /> */}

      {isDataFetched && <p>Data fetched for mobile number {formData.mobile_number}.</p>}

      {/* Modal for Success Message */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Thank You Modal"
        className="thank-you-modal"
        overlayClassName="thank-you-modal-overlay"
      >
        <h2>Thank You!</h2>
        <p>Your booking has been {isUpdateMode ? 'updated' : 'created'} successfully.</p>
        <p>You will be redirected to the Bookings page shortly.</p>
      </Modal>

      {/* Modal for Selecting Patient */}
      <Modal
        isOpen={isPatientModalOpen}
        onRequestClose={() => setIsPatientModalOpen(false)}
        contentLabel="Select Patient"
        className="patient-modal"
        overlayClassName="patient-modal-overlay"
      >
        <h2>Select a Patient</h2>
        <button onClick={() => setIsPatientModalOpen(false)} className="close-modal-button">
          &times;
        </button>
        <div className="patient-list">
          {patientsList.map((patient) => (
            <div key={patient.patient_id} className="patient-item" onClick={() => selectPatient(patient)}>
              <p><strong>Name:</strong> {patient.PatientName}</p>
              <p><strong>Email:</strong> {patient.Email || 'N/A'}</p>
              <p><strong>Mobile:</strong> {patient.Mobile}</p>
              {/* Add more fields as needed */}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );

  // ... all the functions above

};

export default BookingForm;
