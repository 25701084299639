import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import the axios instance with token handling
import { Link } from 'react-router-dom';
import './AddHolidays.css';

const DeleteHolidays = () => {
    const [holidays, setHolidays] = useState([]);
    const [filteredHolidays, setFilteredHolidays] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchHolidays = async () => {
            try {
                const response = await axiosInstance.get('/api/holidays/');
                setHolidays(response.data);
                setFilteredHolidays(response.data); // Initially, display all holidays
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, []);

    const handleDeleteHoliday = async (holidayId) => {
        try {
            await axiosInstance.delete(`/api/holidays/${holidayId}/`);
            const updatedHolidays = holidays.filter(holiday => holiday.id !== holidayId);
            setHolidays(updatedHolidays);
            setFilteredHolidays(updatedHolidays);
            alert('Holiday Deleted Successfully!');
        } catch (error) {
            console.error('Error deleting holiday:', error);
            alert('Failed to delete holiday');
        }
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = holidays.filter(holiday => holiday.name.toLowerCase().includes(value));
        setFilteredHolidays(filtered);
    };

    return (
        <div className="delete-holidays">
            <div className="holiday-list">
                <h2>Holidays Master</h2>
                <Link to="/master/Holidays">
                    <button>Back To Menu</button>
                </Link>
                <div className="search-holidays">
                    <h3>Search Holidays</h3>
                    <input 
                        type="text" 
                        placeholder="Enter Holiday Name" 
                        value={searchTerm} 
                        onChange={handleSearchChange} 
                    />
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Holiday Name</th>
                            <th>Holiday Date</th>
                            <th>Is Show</th>
                            <th>Booking Upto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHolidays.map((holiday) => (
                            <tr key={holiday.id}>
                                <td><button onClick={() => handleDeleteHoliday(holiday.id)}>Delete</button></td>
                                <td>{holiday.name}</td>
                                <td>{holiday.date}</td>
                                <td>{holiday.is_show ? 'True' : 'False'}</td>
                                <td>{holiday.is_book_upto ? 'True' : 'False'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DeleteHolidays;
