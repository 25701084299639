import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Updated to use axiosInstance
import './UpdateArticleCRM.css';

function UpdateArticleCRM() {
    const [articles, setArticles] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [records, setRecords] = useState(10);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [formData, setFormData] = useState({
        article_heading: '',
        thumbnail_alt_text: '',
        thumbnail_title_text: '',
        release_date: '',
        url_name: '',
        article_thumbnail_image: null,
        article_image1: null,
        article_image2: null,
        article_image3: null,
        article_image4: null,
        articles_content: '',
        sequence: 0,
        status: 'active',
    });

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axiosInstance.get('/api/get_articles/');
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    const handleSelect = (article) => {
        setSelectedArticle(article);
        setFormData({
            article_heading: article.article_heading,
            thumbnail_alt_text: article.thumbnail_alt_text,
            thumbnail_title_text: article.thumbnail_title_text,
            release_date: article.release_date,
            url_name: article.url_name,
            article_thumbnail_image: article.article_thumbnail_image,
            article_image1: article.article_image1,
            article_image2: article.article_image2,
            article_image3: article.article_image3,
            article_image4: article.article_image4,
            articles_content: article.articles_content,
            sequence: article.sequence,
            status: article.status,
        });

        document.body.classList.add('modal-open');
    };

    const closeModal = () => {
        setSelectedArticle(null);
        document.body.classList.remove('modal-open');
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formDataToSend = new FormData();
        formDataToSend.append('article_heading', formData.article_heading);
        formDataToSend.append('thumbnail_alt_text', formData.thumbnail_alt_text);
        formDataToSend.append('thumbnail_title_text', formData.thumbnail_title_text);
        formDataToSend.append('release_date', formData.release_date);
        formDataToSend.append('url_name', formData.url_name);
        
        if (formData.article_thumbnail_image && typeof formData.article_thumbnail_image !== 'string') {
            formDataToSend.append('article_thumbnail_image', formData.article_thumbnail_image);
        }
        if (formData.article_image1 && typeof formData.article_image1 !== 'string') {
            formDataToSend.append('article_image1', formData.article_image1);
        }
        if (formData.article_image2 && typeof formData.article_image2 !== 'string') {
            formDataToSend.append('article_image2', formData.article_image2);
        }
        if (formData.article_image3 && typeof formData.article_image3 !== 'string') {
            formDataToSend.append('article_image3', formData.article_image3);
        }
        if (formData.article_image4 && typeof formData.article_image4 !== 'string') {
            formDataToSend.append('article_image4', formData.article_image4);
        }
        
        formDataToSend.append('articles_content', formData.articles_content);
        formDataToSend.append('sequence', formData.sequence);
        formDataToSend.append('status', formData.status === 'active'); // Convert to boolean
        
        try {
            const response = await axiosInstance.put(
                `/api/update_article_sequence/${selectedArticle.id}/`,
                formDataToSend,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
    
            if (response.status === 200) {
                alert('Article updated successfully!');
                closeModal();
            } else {
                alert('Failed to update article');
            }
        } catch (error) {
            console.error('Error:', error);
            alert(`Failed to update article: ${error.response?.data?.error || error.message}`);
        }
    };

    const handleSearch = () => {
        // Implement search functionality here
    };

    const handlePreview = (url) => {
        window.open(`https://api.dangsccg.co.in/articles/${url}`, '_blank');
    };

    const handleDeactivate = async (id) => {
        try {
            const response = await axiosInstance.post(`/api/deactivate_article/${id}/`);
            if (response.ok) {
                setArticles((prevArticles) => prevArticles.filter((article) => article.id !== id));
            } else {
                alert('Failed to deactivate article');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to deactivate article');
        }
    };

    const handleUpdateSequence = async (id, newSequence) => {
        try {
            const response = await axiosInstance.put(`/api/update_article_sequence/${id}/`, {
                sequence: newSequence,
            });
    
            if (response.status === 200) {
                const updatedArticles = await axiosInstance.get('/api/get_articles/');
                setArticles(updatedArticles.data);
            } else {
                alert('Failed to update sequence');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to update sequence');
        }
    };

    const renderImagePreview = (image) => {
        if (!image) return null;
    
        if (typeof image === 'string') {
            return (
                <img
                    src={image.startsWith('/media') ? `https://api.dangsccg.co.in${image}` : image}
                    alt="Image Preview"
                    style={{ width: '100px', marginTop: '10px' }}
                />
            );
        } else if (image instanceof File) {
            return (
                <img
                    src={URL.createObjectURL(image)}
                    alt="Image Preview"
                    style={{ width: '100px', marginTop: '10px' }}
                />
            );
        }
    
        return null;
    };

    return (
        <div className="CRMArticle-container">
            <div className="CRMArticle-header">
                <h1>Articles Master</h1>
                <Link to="/News/ArticleCRM">
                    <button className="CRMArticle-back-button">Back To Menu</button>
                </Link>
            </div>

            <div className="CRMArticle-search">
                <div className="CRMArticle-search-row">
                    <input
                        type="text"
                        placeholder="Search Headlines/URL Name"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="">--Select--</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    <input
                        type="number"
                        value={records}
                        onChange={(e) => setRecords(e.target.value)}
                        placeholder="Records"
                    />
                    <button onClick={handleSearch}>Search</button>
                </div>
            </div>

            <div className="CRMArticle-table-container">
                <table className="CRMArticle-table">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Article Detail</th>
                            <th>Sequence</th>
                            <th>Thumbnail Image</th>
                            <th>Article Images</th>
                        </tr>
                    </thead>
                    <tbody>
                        {articles.map((article) => (
                            <tr key={article.id}>
                                <td>
                                    <button onClick={() => handleSelect(article)} className="CRMArticle-button select">
                                        Select
                                    </button>
                                    <button onClick={() => handlePreview(article.url_name)} className="CRMArticle-button preview">
                                        Preview
                                    </button>
                                    <button onClick={() => handleDeactivate(article.id)} className="CRMArticle-button deactivate">
                                        Deactivate
                                    </button>
                                </td>
                                <td>
                                    <div><strong>{article.article_heading}</strong></div>
                                    <div>📅 {article.release_date}</div>
                                    <div>URL : {article.url_name}</div>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={article.sequence}
                                        onChange={(e) => handleUpdateSequence(article.id, e.target.value)}
                                    />
                                    <button className="CRMArticle-button update-sequence">Update</button>
                                </td>
                                <td>
                                    <div>Image : {article.article_thumbnail_image.split('/').pop()}</div>
                                    <div>Alt Text : {article.thumbnail_alt_text}</div>
                                    <div>Title Text : {article.thumbnail_title_text}</div>
                                </td>
                                <td>
                                    <div>Image(1) : {article.article_image1 ? article.article_image1.split('/').pop() : ''}</div>
                                    <div>Image(2) : {article.article_image2 ? article.article_image2.split('/').pop() : ''}</div>
                                    <div>Image(3) : {article.article_image3 ? article.article_image3.split('/').pop() : ''}</div>
                                    <div>Image(4) : {article.article_image4 ? article.article_image4.split('/').pop() : ''}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedArticle && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Edit Article</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Article Heading:</label>
                                <input
                                    type="text"
                                    name="article_heading"
                                    value={formData.article_heading || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Thumbnail Alt Text:</label>
                                <input
                                    type="text"
                                    name="thumbnail_alt_text"
                                    value={formData.thumbnail_alt_text || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Thumbnail Title Text:</label>
                                <input
                                    type="text"
                                    name="thumbnail_title_text"
                                    value={formData.thumbnail_title_text || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Release Date:</label>
                                <input
                                    type="date"
                                    name="release_date"
                                    value={formData.release_date || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>URL Name:</label>
                                <input
                                    type="text"
                                    name="url_name"
                                    value={formData.url_name || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Article Thumbnail Image:</label>
                                <input
                                    type="file"
                                    name="article_thumbnail_image"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.article_thumbnail_image)}
                            </div>
                            <div className="form-group">
                                <label>Article Image 1:</label>
                                <input
                                    type="file"
                                    name="article_image1"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.article_image1)}
                            </div>
                            <div className="form-group">
                                <label>Article Image 2:</label>
                                <input
                                    type="file"
                                    name="article_image2"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.article_image2)}
                            </div>
                            <div className="form-group">
                                <label>Article Image 3:</label>
                                <input
                                    type="file"
                                    name="article_image3"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.article_image3)}
                            </div>
                            <div className="form-group">
                                <label>Article Image 4:</label>
                                <input
                                    type="file"
                                    name="article_image4"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.article_image4)}
                            </div>
                            <div className="form-group">
                                <label>Articles Content:</label>
                                <textarea
                                    name="articles_content"
                                    value={formData.articles_content || ''}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>Sequence:</label>
                                <input
                                    type="number"
                                    name="sequence"
                                    value={formData.sequence || 0}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Status:</label>
                                <select
                                    name="status"
                                    value={formData.status === true ? 'active' : 'inactive'}
                                    onChange={handleChange}
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                            <button type="submit">Save</button>
                            <button type="button" onClick={closeModal}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UpdateArticleCRM;
