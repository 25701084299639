import React, { useState } from 'react';
import axiosInstance from '../../../axiosInstance';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './Career.css'; // updated CSS file name

const Career = () => {
    const today = new Date();
    const [applications, setApplications] = useState([]);
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [nameEmailMobile, setNameEmailMobile] = useState('');
    const [mailStatus, setMailStatus] = useState({ value: '', label: '--Select--' });

    const fetchApplications = async () => {
        const formattedFromDate = fromDate ? fromDate.toISOString().split('T')[0] : '';
        const formattedToDate = toDate ? toDate.toISOString().split('T')[0] : '';

        try {
            const response = await axiosInstance.get('/api/filtered-careers/', {
                params: {
                    from_date: formattedFromDate,
                    to_date: formattedToDate,
                    name_email_mobile: nameEmailMobile,
                    mail_status: mailStatus.value,
                },
            });
            setApplications(response.data);
        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    const handleDateChange = (dateSetter) => (date) => {
        const adjustedDate = new Date(date);
        adjustedDate.setHours(0, 0, 0, 0);
        dateSetter(adjustedDate);
    };

    const handleMailStatusChange = (selectedOption) => {
        setMailStatus(selectedOption);
    };

    const handleSearch = () => {
        fetchApplications();
    };

    const markAsAttended = async (id) => {
        try {
            await axiosInstance.patch(`/api/careers/${id}/attend/`);
            fetchApplications();
        } catch (error) {
            console.error('Error marking as attended:', error);
        }
    };

    return (
        <div className="career-unique__page-enquiry">
            <h2>Search Careers Request</h2>
            <div className="career-unique__search-filters">
                <div className="career-unique__filter-item">
                    <label>From:</label>
                    <DatePicker
                        selected={fromDate}
                        onChange={handleDateChange(setFromDate)}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                <div className="career-unique__filter-item">
                    <label>To:</label>
                    <DatePicker
                        selected={toDate}
                        onChange={handleDateChange(setToDate)}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                <div className="career-unique__filter-item">
                    <label>Applicant Name/Email/Mobile No.:</label>
                    <input
                        type="text"
                        value={nameEmailMobile}
                        onChange={(e) => setNameEmailMobile(e.target.value)}
                    />
                </div>
                <div className="career-unique__filter-item">
                    <label>Mail Status:</label>
                    <Select
                        options={[
                            { value: '', label: '--Select--' },
                            { value: 'sent_to_applicant', label: 'Mail Sent To Applicant' },
                            { value: 'sent_to_ddl', label: 'Mail Sent To DDL' },
                        ]}
                        value={mailStatus}
                        onChange={handleMailStatusChange}
                        classNamePrefix="career-unique"
                    />
                </div>
                <div className="career-unique__filter-buttons">
                    <button className="career-unique__button" onClick={handleSearch}>
                        Generate
                    </button>
                    <button className="career-unique__button">Export To Excel</button>
                </div>
            </div>
            <div className="career-unique__table-container">
                <table className="career-unique__table">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>ID</th>
                            <th>Applicant Details</th>
                            <th>Position Applied For</th>
                            <th>Total Work Experience</th>
                            <th>Expected CTC</th>
                            <th>Resume</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.length > 0 ? (
                            applications.map((application) => (
                                <tr
                                    key={application.id}
                                    className={application.is_attended ? 'career-unique__attended-row' : ''}
                                >
                                    <td>
                                        <div className="career-unique__action-buttons">
                                            <button className="career-unique__manage-button">Manage</button>
                                            <button className="career-unique__info-button">Send Info</button>
                                            {application.is_attended ? (
                                                <button className="career-unique__attended-button">Attended</button>
                                            ) : (
                                                <button
                                                    className="career-unique__attend-button"
                                                    onClick={() => markAsAttended(application.id)}
                                                >
                                                    Attend
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                    <td>{application.id}</td>
                                    <td>
                                        {application.name}
                                        <br />
                                        BD: {new Date(application.contact_date).toLocaleDateString('en-GB')}
                                        <br />
                                        BT: {new Date(application.contact_date).toLocaleTimeString('en-GB')}
                                        <br />
                                        <a href={`mailto:${application.email}`}>{application.email}</a>
                                        <br />
                                        <a href={`tel:${application.mobile_no}`}>{application.mobile_no}</a>
                                    </td>
                                    <td>{application.position}</td>
                                    <td>{application.experience}</td>
                                    <td>{application.ctc}</td>
                                    <td>
                                        <a href={application.resume} target="_blank" rel="noopener noreferrer">
                                            {application.resume.split('/').pop()}
                                        </a>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">No applications found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Career;
