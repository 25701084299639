import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; // Use axiosInstance instead of axios
import './UpdateBlogCRM.css';

function UpdateBlogCRM() {
    const [blogs, setBlogs] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [records, setRecords] = useState(10);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [formData, setFormData] = useState({
        blog_heading: '',
        thumbnail_alt_text: '',
        thumbnail_title_text: '',
        release_date: '',
        url_name: '',
        blog_thumbnail_image: null,
        blog_image1: null,
        blog_image2: null,
        blog_image3: null,
        blog_image4: null,
        blogs_content: '',
        sequence: 0,
        status: 'active',
    });

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axiosInstance.get('/api/get_blogs/');
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleSelect = (blog) => {
        setSelectedBlog(blog);
        setFormData({
            blog_heading: blog.blog_heading,
            thumbnail_alt_text: blog.thumbnail_alt_text,
            thumbnail_title_text: blog.thumbnail_title_text,
            release_date: blog.release_date,
            url_name: blog.url_name,
            blog_thumbnail_image: blog.blog_thumbnail_image,
            blog_image1: blog.blog_image1,
            blog_image2: blog.blog_image2,
            blog_image3: blog.blog_image3,
            blog_image4: blog.blog_image4,
            blogs_content: blog.blogs_content,
            sequence: blog.sequence,
            status: blog.status,
        });

        document.body.classList.add('modal-open');
    };

    const closeModal = () => {
        setSelectedBlog(null);
        document.body.classList.remove('modal-open');
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formDataToSend = new FormData();
        formDataToSend.append('blog_heading', formData.blog_heading);
        formDataToSend.append('thumbnail_alt_text', formData.thumbnail_alt_text);
        formDataToSend.append('thumbnail_title_text', formData.thumbnail_title_text);
        formDataToSend.append('release_date', formData.release_date);
        formDataToSend.append('url_name', formData.url_name);
        
        // Append files only if they are new uploads
        if (formData.blog_thumbnail_image && typeof formData.blog_thumbnail_image !== 'string') {
            formDataToSend.append('blog_thumbnail_image', formData.blog_thumbnail_image);
        }
        if (formData.blog_image1 && typeof formData.blog_image1 !== 'string') {
            formDataToSend.append('blog_image1', formData.blog_image1);
        }
        if (formData.blog_image2 && typeof formData.blog_image2 !== 'string') {
            formDataToSend.append('blog_image2', formData.blog_image2);
        }
        if (formData.blog_image3 && typeof formData.blog_image3 !== 'string') {
            formDataToSend.append('blog_image3', formData.blog_image3);
        }
        if (formData.blog_image4 && typeof formData.blog_image4 !== 'string') {
            formDataToSend.append('blog_image4', formData.blog_image4);
        }
        
        formDataToSend.append('blogs_content', formData.blogs_content);
        formDataToSend.append('sequence', formData.sequence);
        formDataToSend.append('status', formData.status === 'active'); // Convert to boolean
        
        try {
            const response = await axiosInstance.put(
                `/api/update_blog_sequence/${selectedBlog.id}/`,
                formDataToSend,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
    
            if (response.status === 200) {
                alert('Blog updated successfully!');
                closeModal();
            } else {
                alert('Failed to update blog');
            }
        } catch (error) {
            console.error('Error:', error);
            alert(`Failed to update blog: ${error.response?.data?.error || error.message}`);
        }
    };

    const handleSearch = () => {
        // Implement search functionality here
    };

    const handlePreview = (url) => {
        window.open(`https://api.dangsccg.co.in/blogs/${url}`, '_blank');
    };

    const handleDeactivate = async (id) => {
        try {
            const response = await axiosInstance.post(`/api/deactivate_blog/${id}/`);
            if (response.status === 200) {
                setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== id));
            } else {
                alert('Failed to deactivate blog');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to deactivate blog');
        }
    };

    const handleUpdateSequence = async (id, newSequence) => {
        try {
            const response = await axiosInstance.put(`/api/update_blog_sequence/${id}/`, {
                sequence: newSequence,
            });
    
            if (response.status === 200) {
                const updatedBlogs = await axiosInstance.get('/api/get_blogs/');
                setBlogs(updatedBlogs.data);
            } else {
                alert('Failed to update sequence');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to update sequence');
        }
    };

    const renderImagePreview = (image) => {
        if (!image) return null;
    
        if (typeof image === 'string') {
            return (
                <img
                    src={image.startsWith('/media') ? `https://api.dangsccg.co.in${image}` : image}
                    alt="Image Preview"
                    style={{ width: '100px', marginTop: '10px' }}
                />
            );
        } else if (image instanceof File) {
            return (
                <img
                    src={URL.createObjectURL(image)}
                    alt="Image Preview"
                    style={{ width: '100px', marginTop: '10px' }}
                />
            );
        }
    
        return null;
    };

    return (
        <div className="CRMBlog-container">
            <div className="CRMBlog-header">
                <h1>Blogs Master</h1>
                <Link to="/News/Blogs">
                    <button className="CRMBlog-back-button">Back To Menu</button>
                </Link>
            </div>

            <div className="CRMBlog-search">
                <div className="CRMBlog-search-row">
                    <input
                        type="text"
                        placeholder="Search Headlines/URL Name"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="">--Select--</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    <input
                        type="number"
                        value={records}
                        onChange={(e) => setRecords(e.target.value)}
                        placeholder="Records"
                    />
                    <button onClick={handleSearch}>Search</button>
                </div>
            </div>

            <div className="CRMBlog-table-container">
                <table className="CRMBlog-table">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Blog Detail</th>
                            <th>Sequence</th>
                            <th>Thumbnail Image</th>
                            <th>Blog Images</th>
                        </tr>
                    </thead>
                    <tbody>
                        {blogs.map((blog) => (
                            <tr key={blog.id}>
                                <td>
                                    <button onClick={() => handleSelect(blog)} className="CRMBlog-button select">
                                        Select
                                    </button>
                                    <button onClick={() => handlePreview(blog.url_name)} className="CRMBlog-button preview">
                                        Preview
                                    </button>
                                    <button onClick={() => handleDeactivate(blog.id)} className="CRMBlog-button deactivate">
                                        Deactivate
                                    </button>
                                </td>
                                <td>
                                    <div><strong>{blog.blog_heading}</strong></div>
                                    <div>📅 {blog.release_date}</div>
                                    <div>URL : {blog.url_name}</div>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={blog.sequence}
                                        onChange={(e) => handleUpdateSequence(blog.id, e.target.value)}
                                    />
                                    <button className="CRMBlog-button update-sequence">Update</button>
                                </td>
                                <td>
                                    <div>Image : {blog.blog_thumbnail_image.split('/').pop()}</div>
                                    <div>Alt Text : {blog.thumbnail_alt_text}</div>
                                    <div>Title Text : {blog.thumbnail_title_text}</div>
                                </td>
                                <td>
                                    <div>Image(1) : {blog.blog_image1 ? blog.blog_image1.split('/').pop() : ''}</div>
                                    <div>Image(2) : {blog.blog_image2 ? blog.blog_image2.split('/').pop() : ''}</div>
                                    <div>Image(3) : {blog.blog_image3 ? blog.blog_image3.split('/').pop() : ''}</div>
                                    <div>Image(4) : {blog.blog_image4 ? blog.blog_image4.split('/').pop() : ''}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedBlog && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Edit Blog</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Blog Heading:</label>
                                <input
                                    type="text"
                                    name="blog_heading"
                                    value={formData.blog_heading || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Thumbnail Alt Text:</label>
                                <input
                                    type="text"
                                    name="thumbnail_alt_text"
                                    value={formData.thumbnail_alt_text || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Thumbnail Title Text:</label>
                                <input
                                    type="text"
                                    name="thumbnail_title_text"
                                    value={formData.thumbnail_title_text || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Release Date:</label>
                                <input
                                    type="date"
                                    name="release_date"
                                    value={formData.release_date || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>URL Name:</label>
                                <input
                                    type="text"
                                    name="url_name"
                                    value={formData.url_name || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Blog Thumbnail Image:</label>
                                <input
                                    type="file"
                                    name="blog_thumbnail_image"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.blog_thumbnail_image)}
                            </div>
                            <div className="form-group">
                                <label>Blog Image 1:</label>
                                <input
                                    type="file"
                                    name="blog_image1"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.blog_image1)}
                            </div>
                            <div className="form-group">
                                <label>Blog Image 2:</label>
                                <input
                                    type="file"
                                    name="blog_image2"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.blog_image2)}
                            </div>
                            <div className="form-group">
                                <label>Blog Image 3:</label>
                                <input
                                    type="file"
                                    name="blog_image3"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.blog_image3)}
                            </div>
                            <div className="form-group">
                                <label>Blog Image 4:</label>
                                <input
                                    type="file"
                                    name="blog_image4"
                                    onChange={handleChange}
                                />
                                {renderImagePreview(formData.blog_image4)}
                            </div>
                            <div className="form-group">
                                <label>Blogs Content:</label>
                                <textarea
                                    name="blogs_content"
                                    value={formData.blogs_content || ''}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>Sequence:</label>
                                <input
                                    type="number"
                                    name="sequence"
                                    value={formData.sequence || 0}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Status:</label>
                                <select
                                    name="status"
                                    value={formData.status === true ? 'active' : 'inactive'}
                                    onChange={handleChange}
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                            <button type="submit">Save</button>
                            <button type="button" onClick={closeModal}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UpdateBlogCRM;
