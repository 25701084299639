import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axiosInstance from '../../../axiosInstance'; // Import your custom axiosInstance

function YourComponent() {
  const [selectedGender, setSelectedGender] = useState('Male'); // Default selected gender
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemsTable, setSelectedItemsTable] = useState([]); // Table data
  const [totalRate, setTotalRate] = useState(0); // Total rate

  const openPopupWindow = (itemcode, itemType) => {
    const popupUrl = `/Reports/EstimateParams?itemcode=${itemcode}&itemtype=${itemType}`;
    window.open(popupUrl, 'PopupWindow', 'width=800, height=600, scrollbars=yes');
  };

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get('/api/updated-estimaterate/');
        const data = response.data;

        const filteredOptions = data
          .filter((item) => {
            return selectedGender === 'Male'
              ? item.Gender === 'M' || item.Gender === 'B'
              : selectedGender === 'Female'
              ? item.Gender === 'F' || item.Gender === 'B'
              : true;
          })
          .map((item) => ({
            label: `${item.itemname}, #${item.itemcode}`,
            value: item.itemcode,
            rate: item.Rate,
            itemType: item.ItemType,
          }));
          
        setOptions(filteredOptions);
      } catch (error) {
        console.error('Error fetching data from the API:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [selectedGender]);

  useEffect(() => {
    const newTotalRate = calculateTotalRate(selectedItemsTable);
    setTotalRate(newTotalRate);
  }, [selectedItemsTable]);

  const handleChange = (selectedOption) => {
    const isOptionInTable = selectedItemsTable.some(
      (item) => item.value === selectedOption.value
    );

    if (!isOptionInTable) {
      setSelectedItemsTable([...selectedItemsTable, selectedOption]);
    }

    setSelectedOption(null);
  };

  const handleGenderChange = (selectedGender) => {
    setSelectedGender(selectedGender.value);
  };

  const handlePostData = async () => {
    if (selectedItemsTable.length > 0) {
      const testPackageNames = selectedItemsTable.map((selectedItem) => selectedItem.label).join(', ');

      const postData = {
        test_package_name: testPackageNames,
      };

      try {
        const response = await axiosInstance.post('/api/bookings/', postData);
        console.log('Test packages sent to the backend:', postData);
      } catch (error) {
        console.error('Error sending test packages to the backend:', error);
      }
    }
  };

  const handleDelete = (value) => {
    setSelectedItemsTable((prevSelectedItems) =>
      prevSelectedItems.filter((item) => item.value !== value)
    );
  };

  const calculateTotalRate = (items) => {
    return items.reduce((total, selectedItem) => {
      return total + selectedItem.rate;
    }, 0);
  };

  return (
    <div>
      <h1>Your Component</h1>
      <div>
        <label>Select Gender:</label>
        <Select
          value={{ label: selectedGender, value: selectedGender }}
          onChange={handleGenderChange}
          options={[
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' },
          ]}
        />
      </div>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isLoading={isLoading}
        isSearchable={true}
        placeholder="Select option..."
      />
      <table>
        <thead>
          <tr>
            <th></th>
            <th>ItemCode</th>
            <th>ItemName</th>
            <th>Rate</th>
            <th>Params</th>
          </tr>
        </thead>
        <tbody>
          {selectedItemsTable.map((selectedItem) => (
            <tr key={selectedItem.value}>
              <td>
                <button onClick={() => handleDelete(selectedItem.value)}>X</button>
              </td>
              <td>{selectedItem.value}</td>
              <td>{selectedItem.label.split(', ')[0]}</td>
              <td>{selectedItem.rate}</td>
              <td>
                <button onClick={() => openPopupWindow(selectedItem.value, selectedItem.itemType)}>Parameters</button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="EstimateTotal">
            <td colSpan="3">Total:</td>
            <td>{totalRate}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      <button onClick={handlePostData}>Submit</button>
    </div>
  );
}

export default YourComponent;
