import React from 'react';
import { Link } from 'react-router-dom';
import './Holidays.css'; // Include the updated CSS file

const Holidays = () => {
    return (
        <div className="holidays-container">
            <div className="holidays-header">
                <h1>Holidays Master</h1>
            </div>
            <div className="holidays-buttons">
                <span className="holidays-button">
                    <Link to="/Master/Add-Holidays">
                        <button>Add</button>
                    </Link>
                </span>
                <span className="holidays-button">
                    <Link to="/Master/Update-Holidays">
                        <button>Update</button>
                    </Link>
                </span>
                <span className="holidays-button">
                    <Link to="/Master/Deletes-Holidays">
                        <button>Delete</button>
                    </Link>
                </span>
            </div>
        </div>
    );
};

export default Holidays;
