import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is already logged in
        const token = localStorage.getItem('access_token');
        if (token) {
            navigate('/report/Bookings');
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post('https://api.dangsccg.co.in/api/token/', {
                username,
                password,
            });

            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);

            // Set the authorization header for subsequent requests
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

            // Fetch user details
            const userResponse = await axios.get('https://api.dangsccg.co.in/api/current-user/');
            
            const { name, username: fetchedUsername, user_type } = userResponse.data;

            localStorage.setItem('user_name', name);
            localStorage.setItem('user_username', fetchedUsername);
            localStorage.setItem('user_role', user_type);

            // Redirect to the Bookings page
            navigate('/report/Bookings');
        } catch (error) {
            console.error('Login failed:', error);
            setError('Invalid credentials or server issue');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2 className="login-title">Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="login-field">
                        <label className="login-label">Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="login-input"
                        />
                    </div>
                    <div className="login-field">
                        <label className="login-label">Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="login-input"
                        />
                    </div>
                    {error && <p className="login-error">{error}</p>}
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;