import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import './AddUser.css';
// import Spinner from '../../components/Spinner';
// TODO: Update the path to the Spinner component or create a new Spinner component if it doesn't exist.

function AddUser() {
  const [formData, setFormData] = useState({
    name: '',
    user_type: '',
    mobile_no: '',
    whatsapp_no: '',
    email: '',
    address: '',
    city: '',
    pincode: '',
    state: 'Delhi',
    country: 'India',
    username: '',
    password: '',
    confirm_password: '',
    profile_picture: null,
    vaccination_certificate: null,
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [profilePreview, setProfilePreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    const inputValue = type === 'file' ? files[0] : value;

    setFormData(prevData => ({ ...prevData, [name]: inputValue }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: null }));

    if (name === 'password') {
      evaluatePasswordStrength(value);
    }

    if (name === 'profile_picture' && files[0]) {
      setProfilePreview(URL.createObjectURL(files[0]));
    }
  };

  const evaluatePasswordStrength = (password) => {
    if (password.length < 6) {
      setPasswordStrength('Weak');
    } else if (password.length < 10) {
      setPasswordStrength('Moderate');
    } else {
      setPasswordStrength('Strong');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.user_type) newErrors.user_type = 'User type is required';
    if (!formData.mobile_no.trim()) newErrors.mobile_no = 'Mobile number is required';
    if (!formData.username.trim()) newErrors.username = 'Username is required';
    if (!formData.password.trim()) newErrors.password = 'Password is required';
    if (formData.password !== formData.confirm_password) newErrors.confirm_password = 'Passwords do not match';
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Invalid email format';

    if (formData.user_type !== 'phlebotomist') {
      if (formData.profile_picture) newErrors.profile_picture = 'Profile picture is only allowed for phlebotomists';
      if (formData.vaccination_certificate) newErrors.vaccination_certificate = 'Vaccination certificate is only allowed for phlebotomists';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);
    setErrors({});
    setSuccess(null);

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null && formData[key] !== '') {
          formDataToSend.append(key, formData[key]);
        }
      }

      await axiosInstance.post('/api/api/create-user/', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setSuccess('User created successfully');
      setFormData({
        name: '',
        user_type: '',
        mobile_no: '',
        whatsapp_no: '',
        email: '',
        address: '',
        city: '',
        pincode: '',
        state: 'Delhi',
        country: 'India',
        username: '',
        password: '',
        confirm_password: '',
        profile_picture: null,
        vaccination_certificate: null,
      });
      setProfilePreview(null);
    } catch (error) {
      console.error('Error creating user:', error);
      setErrors(error.response?.data || { general: 'An error occurred while processing your request. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderFormGroup = (name, label, type = 'text', options = null) => (
    <div className={`form-group ${errors[name] ? 'has-error' : ''}`}>
      <label htmlFor={name}>{label}:</label>
      {type === 'select' ? (
        <select
          name={name}
          id={name}
          value={formData[name]}
          onChange={handleInputChange}
          aria-label={label}
          required
        >
          <option value="">-- Select --</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          name={name}
          id={name}
          value={type === 'file' ? undefined : formData[name]}
          onChange={handleInputChange}
          aria-label={label}
          required={type !== 'file'}
        />
      )}
      {errors[name] && <div className="error-message" role="alert">{errors[name]}</div>}
    </div>
  );

  return (
    <div className="add-user-container">
      <div className="add-user-header">
        <h2>Add New User</h2>
        <Link to="/User">
          <button className="back-button">Back To Menu</button>
        </Link>
      </div>
      <div className="add-user-form">
        {success && <div className="success-message" role="status">{success}</div>}
        {loading && <div className='loading'>Loading...</div>}
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-row">
            {renderFormGroup('name', 'Name')}
            {renderFormGroup('user_type', 'User Type', 'select', [
              { value: 'admin', label: 'Admin' },
              { value: 'manager', label: 'Manager' },
              { value: 'employee', label: 'Employee' },
              { value: 'phlebotomist', label: 'Phlebotomist' },
              { value: 'qa', label: 'QA' },
              { value: 'hr', label: 'HR' },
            ])}
          </div>
          <div className="form-row">
            {renderFormGroup('mobile_no', 'Mobile No.', 'tel')}
            {renderFormGroup('whatsapp_no', 'WhatsApp No.', 'tel')}
          </div>
          {renderFormGroup('email', 'Email-ID', 'email')}
          {renderFormGroup('address', 'Address', 'textarea')}
          <div className="form-row">
            {renderFormGroup('city', 'City')}
            {renderFormGroup('pincode', 'Pincode')}
          </div>
          <div className="form-row">
            {renderFormGroup('state', 'State')}
            {renderFormGroup('country', 'Country')}
          </div>
          {renderFormGroup('username', 'Username')}
          <div className="form-group">
          <label htmlFor="password">Password:</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={formData.password}
              onChange={handleInputChange}
              required
              aria-label="Password"
            />
            <button 
              type="button" 
              onClick={togglePasswordVisibility} 
              className="toggle-password"
              tabIndex="-1"  // This prevents the button from being focused
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          </div>
          {passwordStrength && <div className={`password-strength ${passwordStrength.toLowerCase()}`}>{passwordStrength} Password</div>}
          {errors.password && <div className="error-message" role="alert">{errors.password}</div>}
        </div>
          {renderFormGroup('confirm_password', 'Confirm Password', 'password')}
          {formData.user_type === 'phlebotomist' && (
            <>
              {renderFormGroup('profile_picture', 'Profile Picture', 'file')}
              {profilePreview && <img src={profilePreview} alt="Profile Preview" className="profile-preview" />}
              {renderFormGroup('vaccination_certificate', 'Vaccination Certificate', 'file')}
            </>
          )}
          <div className="form-actions">
            <button type="submit" disabled={loading} className="submit-button">
              {loading ? 'Adding User...' : 'Add New User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddUser;