import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './RowNavbar.css';

const RowNavbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const username = localStorage.getItem('user_name') || 'User';

    const handleLogout = () => {
        // Clear tokens and user details from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_username');
        localStorage.removeItem('user_role');

        // Navigate to login page
        navigate('/login');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className="row-navbar">
           
            <div className="navbar-user">
                <div onClick={toggleDropdown} className="navbar-username">
                    {username}
                </div>
                {dropdownOpen && (
                    <div className="navbar-dropdown">
                        <button onClick={handleLogout} className="logout-button">Logout</button>
                    </div>
                )}
            </div>

           
        </div>
        
    );
};

export default RowNavbar;
