import React, { useState } from 'react';
import axiosInstance from '../../../axiosInstance'; // Import axiosInstance
import './AddTest.css';

const AddTest = () => {
    const [testName, setTestName] = useState('');
    const [testDetails, setTestDetails] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newTest = {
            name: testName,
            details: testDetails,
        };

        try {
            const response = await axiosInstance.post('/api/api/tests/', newTest); // Use axiosInstance
            console.log('Test created successfully:', response.data);
            // Clear the form fields
            setTestName('');
            setTestDetails('');
        } catch (error) {
            console.error('Error creating test:', error);
        }
    };

    return (
        <div className="add-test">
            <h2>Add New Test</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Test Name:</label>
                    <input
                        type="text"
                        value={testName}
                        onChange={(e) => setTestName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Test Details:</label>
                    <textarea
                        value={testDetails}
                        onChange={(e) => setTestDetails(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddTest;
